import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";

import { makeSliderValue, notAllowNegative } from "./utils";
import { MY_ORGANIZATION_OPTION } from "../utils";

import { useLocalization } from "../../localization/LocalizationProvider";

import { clientDownloadFile, downloadFile } from "../api";

import {
  Modal,
  ModalActions,
  ModalButton,
  ModalInput,
  ModalSelectAllowAdd,
  ModalSliderInput,
  SELECT_STYLES,
} from "../../components/modal/Modal";
import { UploadFileButton } from "../../components/UploadFileButton";

const actorTypeOptions = [
  "actorStartup",
  "actorSME",
  "actorCorporation",
  "actorUniversity",
  "actorNGO",
  "actorGovernmentalBody",
  "actorIndividualPerson",
].map((v) => ({ value: v, label: v }));

export const NodeModal = memo(
  ({
    isOpen,
    data,
    handleConfirmClick,
    handleDeleteClick,
    handleClose,
    providedByOptions,
  }) => {
    const { t } = useLocalization();
    const [reference, setReference] = useState("");
    const [description, setDescription] = useState("");
    const [providedBy, setProvidedBy] = useState(providedByOptions[0].value);
    const [actorType, setActorType] = useState("");
    const [contributionBasis, setcontributionBasis] = useState("");
    const [contributingAssumption, setContributingAssumption] = useState("");
    const [availableIn, setAvailableIn] = useState("");
    const [abilityToContribute, setAbilityToContribute] = useState(null);
    const [willingnessToContribute, setWillingnessToContribute] =
      useState(null);
    const [attachments, setAttachments] = useState([]);

    useEffect(() => {
      setReference(data?.reference);
      setDescription(data?.description);
      setProvidedBy(data?.providedBy || providedByOptions[0].value);
      setActorType(data?.actorType);
      setcontributionBasis(data?.contributionBasis);
      setContributingAssumption(data?.contributingAssumption);
      setAvailableIn(data?.availableIn);
      setAbilityToContribute(data?.abilityToContribute);
      setWillingnessToContribute(data?.willingnessToContribute);
      setAttachments(data?.attachments || []);
    }, [
      data?.abilityToContribute,
      data?.actorType,
      data?.availableIn,
      data?.contributingAssumption,
      data?.contributionBasis,
      data?.description,
      data?.attachments,
      data?.providedBy,
      data?.reference,
      data?.willingnessToContribute,
      providedByOptions,
    ]);

    const handleSubmitClick = useCallback(() => {
      handleConfirmClick({
        reference,
        description,
        providedBy,
        actorType,
        contributionBasis,
        contributingAssumption,
        availableIn,
        abilityToContribute,
        willingnessToContribute,
        attachments,
      });
    }, [
      handleConfirmClick,
      reference,
      description,
      providedBy,
      actorType,
      contributionBasis,
      contributingAssumption,
      availableIn,
      abilityToContribute,
      willingnessToContribute,
      attachments,
    ]);

    const handleFileUpload = useCallback((e) => {
      const file = e.target.files[0];
      setAttachments((v) => [
        {
          id: new Date().getTime().toString(),
          isNew: true,
          name: file.name,
          key: null,
          data: file,
        },
        ...v,
      ]);
    }, []);

    const handleRemoveFile = useCallback((file) => {
      setAttachments((v) =>
        file.isNew
          ? v.filter((vv) => vv.id !== file.id)
          : v.map((vv) => {
              return { ...vv, remove: vv.remove || vv.id === file.id };
            })
      );
    }, []);

    const handleDownloadFile = useCallback((file) => {
      if (file.isNew) {
        clientDownloadFile(file.data, file.name);
      } else {
        downloadFile(file.key, file.name).catch((e) => console.log(e));
      }
    }, []);

    const actorTypeOptionsLocalized = useMemo(
      () => actorTypeOptions.map((v) => ({ ...v, label: t(v.label) })),
      [t]
    );

    return (
      <Modal open={isOpen} closeOnDimmerClick={true} handleClose={handleClose}>
        <div className="grid gap--small">
          <ModalInput
            label={t("descriptionLabel")}
            value={description || ""}
            onChange={setDescription}
            placeholder={t("descriptionHint")}
          />
          <ModalSelectAllowAdd
            label={t("providedByLabel")}
            value={
              providedBy === MY_ORGANIZATION_OPTION.value
                ? t(MY_ORGANIZATION_OPTION.key)
                : providedBy
            }
            onChange={setProvidedBy}
            options={providedByOptions}
          />
          <div className="sc-modal__field">
            <div className="sc-modal__label">{t("actorTypeLabel")}</div>
            <Select
              value={
                actorType
                  ? { value: actorType, label: t(actorType) }
                  : undefined
              }
              onChange={(v) => setActorType(v.value)}
              options={actorTypeOptionsLocalized}
              placeholder={""}
              styles={SELECT_STYLES}
            />
          </div>
          <ModalInput
            label={t("contributionBasisLabel")}
            value={contributionBasis || ""}
            onChange={setcontributionBasis}
            placeholder={t("shortDescriptionHint")}
          />
          <ModalInput
            label={t("contributingAssumptionLabel")}
            value={contributingAssumption || ""}
            onChange={setContributingAssumption}
            placeholder={t("shortDescriptionHint")}
          />
          <ModalInput
            label={t("availableInLabel")}
            type="number"
            digits={3}
            value={availableIn || ""}
            min="0"
            onChange={(value) => notAllowNegative(value, setAvailableIn)}
            hint={t("months")}
          />
          <ModalSliderInput
            label={t("abilityToContributeLabel")}
            value={abilityToContribute}
            onChange={setAbilityToContribute}
          />
          <ModalSliderInput
            label={t("willingnessToContributeLabel")}
            value={willingnessToContribute}
            displayValue={makeSliderValue(willingnessToContribute)}
            onChange={setWillingnessToContribute}
          />
          <UploadFileButton
            text={t("uploadAttachement")}
            handleFileUpload={handleFileUpload}
          />
          <ModalFiles
            files={attachments.filter((f) => !f.remove)}
            handleDownloadFile={handleDownloadFile}
            handleRemoveFile={handleRemoveFile}
          />
        </div>
        <ModalActions>
          <ModalButton
            style={{ marginRight: "auto" }}
            danger
            onClick={handleDeleteClick}
            text={t("delete")}
          />
          <ModalButton onClick={handleClose} text={t("cancel")} />
          <ModalButton
            primary
            onClick={handleSubmitClick}
            text={t("confirm")}
          />
        </ModalActions>
      </Modal>
    );
  }
);

const ModalFiles = memo(({ files, handleDownloadFile, handleRemoveFile }) => {
  return (
    <div className="flex gap--normal ai--center mt--small">
      {files.map((file) => (
        <ModalFile
          file={file}
          handleRemoveFile={handleRemoveFile}
          handleDownloadFile={handleDownloadFile}
        />
      ))}
    </div>
  );
});

const ModalFile = memo(({ file, handleRemoveFile, handleDownloadFile }) => {
  return (
    <div
      className="pointer grid ji--center"
      onClick={() => handleDownloadFile(file)}
    >
      <div className="sc-modal__file-img">
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveFile(file);
          }}
          className="absolute bold pointer"
          style={{ top: "-10px", right: "-5px" }}
        >
          X
        </div>
      </div>
      <div title={file.name} className="ellipsis fs--tiny">
        {file.name}
      </div>
    </div>
  );
});
