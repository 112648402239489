import React, { memo } from 'react';

import { useTheme } from '../../themes/ThemeManager.js';

import { mapInRange } from '../utils';

export const RiskIcon = memo(({
  id = new Date().getTime(),

  ability,
  willingness,
  mode,
}) => {
  const { riskIcon } = useTheme();

  const abilityIndex = mapInRange(ability);

  const willingnessIndex = mapInRange(willingness);

  const iconDisplay = riskIcon.build(
    { id: id, value: ability, valueIndex: abilityIndex },
    { id: id, value: willingness, valueIndex: willingnessIndex },
    mode
  );

  return (
    <>
      {iconDisplay}
    </>
  );
});
