

export function findIntersect(origin, radius, otherLineEndPoint) {
  var v = otherLineEndPoint.subtract(origin);
  var lineLength = v.length();
  if (lineLength === 0) throw new Error("Length has to be positive");
  v = v.normalize();
  return origin.add(v.multiplyScalar(radius));
}

export class Vector {
  constructor(x, y) {
    this.x = x || 0;
    this.y = y || 0;
  }
}

Vector.prototype.add = function (vector) {
  return new Vector(this.x + vector.x, this.y + vector.y);
};

Vector.prototype.subtract = function (vector) {
  return new Vector(this.x - vector.x, this.y - vector.y);
};

Vector.prototype.multiply = function (vector) {
  return new Vector(this.x * vector.x, this.y * vector.y);
};

Vector.prototype.multiplyScalar = function (scalar) {
  return new Vector(this.x * scalar, this.y * scalar);
};

Vector.prototype.divide = function (vector) {
  return new Vector(this.x / vector.x, this.y / vector.y);
};

Vector.prototype.divideScalar = function (scalar) {
  return new Vector(this.x / scalar, this.y / scalar);
};

Vector.prototype.length = function () {
  return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
};

Vector.prototype.normalize = function () {
  return this.divideScalar(this.length());
};

export const majorComplementPositionMapper = (count, index) => {
  return calculateMajorComplementsPosition(count, index);
};

export function calculateMajorComplementsPosition(size, i) {
  const map = {
    0: { // 
      0: { x: 520, y: 267 },
    },
    1: {
      1: { x: 750, y: 400 },
    },
    2: {
      1: { x: 750, y: 400 },
      2: { x: 670, y: 710 },
    },
    3: {
      1: { x: 750, y: 400 },
      2: { x: 670, y: 710 },
      3: { x: 380, y: 710 },
    },
    4: {
      1: { x: 750, y: 400 },
      2: { x: 670, y: 710 },
      3: { x: 380, y: 710 },
      4: { x: 315, y: 400 },
    },
    5: {
      1: { x: 750, y: 400 },
      2: { x: 744, y: 642 },
      3: { x: 524, y: 772 },
      4: { x: 326, y: 642 },
      5: { x: 315, y: 400 },
    },
    6: {
      1: { x: 701, y: 330 },
      2: { x: 776, y: 560 },
      3: { x: 660, y: 730 },
      4: { x: 400, y: 730 },
      5: { x: 290, y: 560 },
      6: { x: 353, y: 330 },
    },
    7: {
      1: { x: 685, y: 320 },
      2: { x: 776, y: 510 },
      3: { x: 720, y: 670 },
      4: { x: 525, y: 770 },
      5: { x: 354, y: 670 },
      6: { x: 286, y: 510 },
      7: { x: 377, y: 320 },
    },
    8: {
      1: { x: 677, y: 320 },
      2: { x: 768, y: 450 },
      3: { x: 760, y: 600 },
      4: { x: 637, y: 740 },
      5: { x: 430, y: 740 },
      6: { x: 308, y: 600 },
      7: { x: 300, y: 450 },
      8: { x: 385, y: 320 },
    }
  };
  const position = { x: map[size][i].x, y: map[size][i].y };
  return position;
}

export function makeSliderValue(v) {
  if (!v && v !== 0) return null;
  return Number((1 - v).toFixed(1));
}

export function notAllowNegative(value, func) {
  if (value >= 0) {
    return func(value);
  }
}
