import { MAJOR_COMPLEMENT } from "../../components/major-complements/MajorElementNode";
import { VALUE_ELEMENT } from "../../components/value-complement/ValueComplementNode";

import { getChildElements, HIGHT_RISK, MY_ORGANIZATION_OPTION, valueComplementRisk } from "../../utils";

export const INTEGRATING_ID = 'INTEGRATING';

export function suggestIntegrating(elements) {
  return elements
    .filter(el => el.type === VALUE_ELEMENT && el.data.providedBy === MY_ORGANIZATION_OPTION.value)
    .some(el => {
      const child = elements.find(ee => ee.parentId === el.id) || null;
      const parent = el.parentId ? elements.find(ee => ee.id === el.parentId) : null;

      const riskyChild = child ? valueComplementRisk(child.data) <= HIGHT_RISK : false;
      const riskyParent = parent ? valueComplementRisk(parent.data) <= HIGHT_RISK : false;
      return riskyChild || riskyParent;
    })
    ||
    elements
      .filter(el => el.type === MAJOR_COMPLEMENT)
      .some(el => {
        const childs = getChildElements(elements.filter(el => el.type === VALUE_ELEMENT), el.id).filter(cEl => cEl.data.providedBy === MY_ORGANIZATION_OPTION.value);
        return childs.length === 0 && valueComplementRisk(el.data) <= 0.6;
      });
}
