import React from 'react';
import ReactDOM from 'react-dom';
import firebase from "firebase/app";

import './index.css';
import './css/colors.css';
import './css/spacing.css';
import './css/layout.css';
import './css/text.css';
import './css/utils.css';
import './css/elements.css';
import './css/shared.css';

import './auth/auth.css';

import './utils/axios-utils';

import Root from './Root';

import { createBrowserHistory } from 'history';
export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

var firebaseConfig = {
  apiKey: "AIzaSyC3x3RVePoq03yPMZ7YuIYsw43vEFXRaQs",
  authDomain: "mind-ecosystem-ci.firebaseapp.com",
  projectId: "mind-ecosystem-ci",
  storageBucket: "mind-ecosystem-ci.appspot.com",
  messagingSenderId: "79666137143",
  appId: "1:79666137143:web:89caf10f91666d5fc057b2",
  measurementId: "G-54R725TD78"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);

