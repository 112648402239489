import React, { memo, useCallback, useContext } from 'react';

import { useTheme, useThemeManager } from '../../../themes/ThemeManager.js';
import { ScenarioContext } from '../../ScenarioPage';
import { Node } from '../Node';

import { mapInRange } from '../../utils.js';

export const MAJOR_COMPLEMENT = 'MAJOR_COMPLEMENT';

const MajorElementNode = memo(({ id, data }) => {
  const ctx = useContext(ScenarioContext);

  const theme = useThemeManager();
  const { riskIcon } = useTheme();

  const abilityIndex = mapInRange(data.abilityToContribute);
  const abilityIcons = riskIcon.icons ? riskIcon.icons[abilityIndex] : {};

  const willingnessIndex = mapInRange(data.willingnessToContribute);
  const willingnessIcons = riskIcon.icons ? riskIcon.icons[willingnessIndex] : {};

  const handleOpen = useCallback((id) => {
    ctx.onMajorElementOpen(id);
  }, [ctx]);

  return (
    <Node
      id={id}
      description={data.description}
      canMove={false}
      handleAddClick={ctx.onValueElementAddClick}
      riskIconAbility={abilityIcons?.ability}
      riskIconAbilityStyle={riskIcon.iconsStyle?.ability}
      riskIconWillingness={willingnessIcons?.willingness}
      riskIconWillingnessStyle={riskIcon.iconsStyle?.willingness}
      plusIcon={theme.addImg?.src}
      plusIconStyle={theme.addImg?.style}
      handleOpen={handleOpen}

      willingness={data.willingnessToContribute}
      ability={data.abilityToContribute}
    />
  );
});

export default MajorElementNode;
