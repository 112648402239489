import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import googleIcon from "../assets/google.png";

import { useLocalization } from "../localization/LocalizationProvider";
import { safeFunction } from "../utils/safeFunction";

import { firebaseRegisterUser, googleLogin, registerUser } from "./api";
import { AuthButton } from "./components/AuthButton";

import { Loader } from "./components/Loader/Loader";
import { AUTH_STATUS, useAuthStatus } from "./PrivateRoute";
import { isPasswordValid } from "./utils";
import { openAlertModal } from "../components/alert-modal/AlertModal";
import {
  AuthFooter,
  AuthFormInput,
  AuthFormSubmit,
  AuthHeader,
  AuthLanguage,
  Hero,
  HeroPage,
} from "./components/AuthPages";

const initialRegisterState = {
  isLoading: false,

  email: "",
  password: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_EMAIL":
      return { ...state, email: action.payload };
    case "SET_PASSWORD":
      return { ...state, password: action.payload };
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_VALUES":
      return { ...state, ...action.payload };
    default:
      throw new Error("Invalid Type");
  }
}

const googleLSKey = "thrive_google_auth_signup";

function RegisterPage() {
  const [state, dispatch] = useReducer(reducer, initialRegisterState);
  const { isLoading, email, password } = state;

  const { t } = useLocalization();

  const { authStatus, user } = useAuthStatus();

  const navigate = useNavigate();

  const isValidPassword = useMemo(() => {
    return isPasswordValid(password);
  }, [password]);

  const handleSubmitRegister = useCallback(() => {
    localStorage.removeItem(googleLSKey);

    if (!email || !password || !isValidPassword) return null;
    dispatch({ type: "SET_LOADING", payload: true });

    safeFunction(
      async () => {
        const firebaseUser = await firebaseRegisterUser(email, password);

        await registerUser({
          email: firebaseUser.email,
          id: firebaseUser.uid,
        }).then(() => {
          openAlertModal({ title: t("createduserAlert") });
        });

        navigate("/login");
      },
      () => dispatch({ type: "SET_LOADING", payload: false })
    )();
  }, [email, navigate, password, isValidPassword]);

  const handleGoogleRegister = useCallback(() => {
    localStorage.setItem(googleLSKey, "true");
    googleLogin();
  }, []);

  useEffect(() => {
    if (authStatus === AUTH_STATUS.LOGGED && user) {
      navigate("/login");
    }
  }, [authStatus, navigate, user]);

  return (
    <HeroPage>
      <Loader active={isLoading} />
      <Hero />
      <div className="auth__form">
        <AuthLanguage />
        <AuthHeader />
        <div className="auth__form-wrapper" id="login-to">
          <div className="title">{t("registerUserTitle")}</div>
          <div className="auth__form-content">
            <AuthFormInput
              value={email}
              label="E-mail"
              onChange={(e) =>
                dispatch({ type: "SET_EMAIL", payload: e.target.value })
              }
              placeholder={t("emailPlaceholder")}
            />
            <AuthFormInput
              value={password}
              label={t("passwordPlaceholder")}
              type="password"
              onChange={(e) =>
                dispatch({ type: "SET_PASSWORD", payload: e.target.value })
              }
              placeholder={t("passwordPlaceholder")}
            />
          </div>

          <AuthFormSubmit
            text={t("registerButton")}
            onClick={handleSubmitRegister}
            disabled={!email || !password || !isValidPassword}
          />
          <AuthButton
            onClick={handleGoogleRegister}
            label={t("googleRegister")}
            iconSrc={googleIcon}
            color={{ "--color": "#4285F4" }}
          />

          <div className="auth__form-next-action">
            <span className="click" onClick={() => navigate("/login")}>
              {t("registerLoginLabel")}
            </span>
          </div>
          <AuthFooter />
        </div>
      </div>
    </HeroPage>
  );
}

export default RegisterPage;
export const PATH = "/register";
