import React, { memo, useCallback, useState } from "react";
import { Handle } from "react-flow-renderer";

import { TooltipWrapper } from "../../components/TooltipWrapper";
import { RiskIcon } from "./RiskIcon";

import { useLocalization } from "../../localization/LocalizationProvider";
import { useThemeManager } from "../../themes/ThemeManager";

import "./Node.css";

export const Node = memo(
  ({
    id,
    description,
    handleAddClick,
    canMove = true,

    handleOpen = () => {},
    handleMove = () => {},

    ability,
    willingness,
  }) => {
    const theme = useThemeManager();
    const plusIconStyle = theme.addImg?.style;

    const { handleDown, handleUp } = useClickHandler(({ type }) =>
      type === "CLICK" ? handleOpen(id) : handleMove(id)
    );

    const { t } = useLocalization();
    return (
      <div className={`node ${canMove ? "" : "nodrag"}`}>
        <div
          className="node__content"
          onTouchStart={handleDown}
          onTouchEnd={handleUp}
          onMouseDown={handleDown}
          onMouseUp={handleUp}
        >
          <div
            className="node__label"
            data-tip="editComplementHint"
            data-for={`node${id}`}
          >
            {description}
          </div>
          <div className="node__risk-icon">
            <RiskIcon id={id} ability={ability} willingness={willingness} />
          </div>
          <AddNodeIcon
            id={id}
            onClick={() => handleAddClick({ id })}
            style={plusIconStyle}
          />
        </div>
        <Handle
          type="source"
          id={id}
          style={{ top: "50%", left: "50%", opacity: 0, pointerEvents: "none" }}
        />
        <TooltipWrapper id={`node${id}`} label={t} effect="float" />
      </div>
    );
  }
);

function AddNodeIcon({ id, onClick, style }) {
  return (
    <div
      onTouchEnd={() => onClick({ id })}
      onClick={() => onClick({ id })}
      className="node__plus"
      style={style}
    >
      <svg
        style={{ width: "100%", height: "100%" }}
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        viewBox="0 0 200 200"
      >
        <g
          id="Grupo_268"
          data-name="Grupo 268"
          transform="translate(-1207.653 -729.341)"
        >
          <g id="Grupo_264" data-name="Grupo 264">
            <g id="Grupo_263" data-name="Grupo 263" opacity="0.9">
              <path
                className="theme-primary__stroke"
                id="Caminho_456"
                data-name="Caminho 456"
                d="M1405.754,829.836a97.607,97.607,0,1,1-97.607-97.607A97.606,97.606,0,0,1,1405.754,829.836Z"
                fill="#fff"
                stroke="#003a60"
                stroke-miterlimit="10"
                stroke-width="10"
              />
            </g>
          </g>
          <g id="Grupo_267" data-name="Grupo 267">
            <g id="Grupo_266" data-name="Grupo 266">
              <g id="Grupo_265" data-name="Grupo 265">
                <path
                  className="theme-primary__fill"
                  id="Caminho_457"
                  data-name="Caminho 457"
                  d="M1347.209,839.572H1319.23v29.422h-19.483V839.572H1271.9V820.088h27.85V790.676h19.483v29.412h27.979Z"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

const CLICK_TIME = 100;
function useClickHandler(cb) {
  const [timer, setTimer] = useState(null);

  const handleDown = useCallback(() => {
    setTimer(new Date().getTime());
  }, []);

  const handleUp = useCallback(() => {
    const finalTime = new Date().getTime() - timer;
    cb({ type: finalTime > CLICK_TIME ? "MOVE" : "CLICK" });
  }, [cb, timer]);

  return { handleDown, handleUp };
}
