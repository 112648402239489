import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_WEB_API_URL || 'http://35.177.241.80:3000',
});

// api.defaults.withCredentials = true;

function getLanguage() {
  const lang = navigator.language?.split('-') || ['en'];
  return lang[0];
}

api.interceptors.request.use(request => {
  request.headers = { 'accept-Language': getLanguage() };
  request.headers.sid = localStorage.getItem('sid');
  return request;
});

api.interceptors.response.use(response => response, error => Promise.reject(error));

api.interceptors.response.use(response => {
  if (response?.headers?.sid) {
    localStorage.setItem('sid', response?.headers?.sid);
  }
  return response;
});
