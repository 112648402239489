import React, { useState } from "react";

import logoImg from "../../assets/logo.png";
import sponsorsImg from "../../assets/sponsors.svg";
import advantageImg from "../../assets/advantage_horizontal.svg";
import cotecImg from "../../assets/cotec.jpg";
import enFlag from "../../assets/flag_EN.png";
import ptFlag from "../../assets/flag_PT.png";

import { useLocalization } from "../../localization/LocalizationProvider";

export const AuthFormInput = ({
  type = "text",
  value,
  label,
  placeholder,
  onChange,
}) => {
  return (
    <div className="auth__form-input">
      <label className="label">
        {label}
        <span className="required">*</span>
      </label>
      <input
        className="box"
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export const HeroPage = ({ children }) => (
  <div className="auth__page">{children}</div>
);

export const Hero = () => {
  const handleActionClick = () => {
    document
      .querySelector(".auth__form")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="auth__hero">
      <div className="auth__hero-content">
        <img src={logoImg} alt="Thrive" />
        <div className="action" onClick={handleActionClick}>
          Entrar
        </div>
      </div>
    </div>
  );
};

export const AuthFooter = () => {
  const { t } = useLocalization();

  return (
    <div className="auth__footer">
      <label className="label">{t("authFooterLabel")}</label>
      <img
        src={sponsorsImg}
        alt="Compete 2020 / Portugal 2020 / Fundo Europeu do Desenvolvimento Regional"
      />
    </div>
  );
};

export const VersionFooter = () => {
  const { t } = useLocalization();
  //{{version}} {{buildTimestamp | date:"YYYY-MM-dd HH:mm"}}
  return (
    <div className="version">
      {/* <span>Versão: {process.env.VERSION}</span> */}
      <span>{t("Version")}: 1.0.2 2024-02-39 14:59</span>
    </div>
  );
};

export const AuthHeader = () => {
  return (
    <div className="auth__header">
      <img className="advantage" src={advantageImg} alt="Advantage" />
      <img className="cotec" src={cotecImg} alt="Cotec" />
    </div>
  );
};

const langOptions = [
  { value: "pt", label: "Português", flag: ptFlag },
  { value: "en", label: "English", flag: enFlag },
];
export const AuthLanguage = () => {
  const { lang, setLang } = useLocalization();
  const [isOpen, setIsOpen] = useState(false);

  const currentLang = langOptions.find((l) => l.value === lang);
  return (
    <div className="auth__form-language" onClick={() => setIsOpen((v) => !v)}>
      <div className="auth__form-language-selected">
        <img src={currentLang.flag} alt="" />
        <span>{currentLang.label}</span>
      </div>
      {isOpen ? (
        <div className="auth__form-language-popup">
          {langOptions.map((l) => (
            <div
              key={l.value}
              className="auth__form-language-popup-option"
              onClick={() => setLang(l.value)}
            >
              <img src={l.flag} alt="" />
              <span>{l.label}</span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export const AuthFormSubmit = ({ text, onClick, disabled }) => {
  return (
    <div
      disabled={disabled}
      className="auth__form-btn"
      onClick={() => (disabled ? null : onClick())}
    >
      {text}
    </div>
  );
};
