import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

import { useTheme } from "../../themes/ThemeManager";

import { getRiskLabel, valueComplementRisk, valuesReducer } from "../utils";
import { sortArray } from "../../utils/utils";

import {
  Modal,
  ModalActions,
  ModalButton,
  ModalTitle,
} from "../../components/modal/Modal";
import { ScenarioContext } from "../ScenarioPage";
import { VALUE_ELEMENT } from "../components/value-complement/ValueComplementNode";
import { ScenarioTroubleshootingModal } from "./ScenarioTroubleshooting";
import { MAJOR_COMPLEMENT } from "../components/major-complements/MajorElementNode";
import { RiskIcon } from "../components/RiskIcon";

import { ScenarioDelayingModal } from "./delaying/ScenarioDelaying";
import { useLocalization } from "../../localization/LocalizationProvider";
import { safeFunction } from "../../utils/safeFunction";
import { createChildScenario } from "../api";
import { openAlertModal } from "../../components/alert-modal/AlertModal";
import { useNavigate } from "react-router-dom";

export const ANALYZER_STEP = {
  ANALYZER: "ANALYZER",
  TROUBLESHOOTING: "TROUBLESHOOTING",
  GENERICIZATION: "GENERICIZATION",
  DUPLICATING: "DUPLICATING",
  ELIMINATING: "ELIMINATING",
  ADDING: "ADDING",
  INCENTIVIZING: "INCENTIVIZING",
  SPECIALIZING: "SPECIALIZING",
  INTEGRATING: "INTEGRATING",
  DELAYING: "DELAYING",
};

export const getNegativeComplements = (elements) => {
  return sortArray(
    elements
      .filter((e) => e.type === VALUE_ELEMENT || e.type === MAJOR_COMPLEMENT)
      .map((e) => ({ ...e, risk: valueComplementRisk(e.data) }))
      .filter((e) => e.risk <= 0.6),
    (v1, v2) => v1.risk - v2.risk
  ).slice(0, 3);
};

export function ScenarioAnalyzerFlowModal({ isOpen, handleCancel, elements }) {
  const [step, setStep] = useState(ANALYZER_STEP.ANALYZER);

  useEffect(() => {
    if (isOpen) {
      setStep(ANALYZER_STEP.ANALYZER);
    }
  }, [isOpen]);

  const buildContent = useCallback(() => {
    if (step === ANALYZER_STEP.ANALYZER)
      return (
        <ScenarioAnalyzerModal
          handleCancel={handleCancel}
          handleNextStep={setStep}
          elements={elements}
        />
      );

    if (step === ANALYZER_STEP.TROUBLESHOOTING)
      return (
        <ScenarioTroubleshootingModal
          handleCancel={handleCancel}
          handleStrategyClick={setStep}
          elements={elements}
        />
      );

    if (step === ANALYZER_STEP.DELAYING)
      return (
        <ScenarioDelayingModal
          handleCancel={handleCancel}
          elements={elements}
        />
      );

    return <ScenarioImplementStrategy strategy={step} />;
  }, [elements, handleCancel, step]);

  return isOpen ? <>{buildContent()}</> : null;
}

const analyzerValues = {
  abilityIcon: null,
  willingnessIcon: null,

  riskLabel: null,
  ecosystemSuccess: null,
  negativeElements: [],
};

function ScenarioAnalyzerModal({ handleCancel, handleNextStep }) {
  const ctx = useContext(ScenarioContext);
  const { t, lang } = useLocalization();

  const { riskIcon } = useTheme();

  const [state, dispatch] = useReducer(valuesReducer, analyzerValues);

  useEffect(() => {
    const riskLabel = getRiskLabel(ctx.scenarioRisk, lang);

    const ecosystemSuccess = Math.round(
      (1 -
        (1 -
          (ctx.scenarioRisk.ability < ctx.scenarioRisk.willingness
            ? ctx.scenarioRisk.ability
            : ctx.scenarioRisk.willingness))) *
        100
    );

    const negativeElements = getNegativeComplements(ctx?.elements);

    dispatch({
      type: "SET_VALUES",
      payload: { riskLabel, ecosystemSuccess, negativeElements },
    });
  }, [ctx?.elements, ctx.scenarioRisk, riskIcon.icons, lang]);

  const { riskLabel, ecosystemSuccess, negativeElements } = state;

  return (
    <Modal
      size="big"
      open={true}
      closeOnDimmerClick={true}
      handleClose={handleCancel}
    >
      <div>
        <ModalTitle>{t("analyserModalTitle")}</ModalTitle>
        <div className="flex gap--normal ai--center">
          <div className="bold color--gray">{t("scenarioRiskRating")}:</div>
          <RiskIcon
            ability={ctx.scenarioRisk.ability}
            willingness={ctx.scenarioRisk.willingness}
            mode="analyze"
          />
          <div>
            {t("analyserRiskLabel")}{" "}
            <span className="bold theme-color--primay">{riskLabel}</span>
          </div>
        </div>
        <div className="mt--normal">
          <div className="bold color--gray">{t("whatIsMeaning")}:</div>
          <div>{t("analyserMessage1")}</div>
          <div className="mt--small">
            {t("analyserMessage2.1")}{" "}
            <span className="bold theme-color--primay">[{riskLabel}]</span>,{" "}
            {t("analyserMessage2.1")}{" "}
            <span className="bold theme-color--primay">
              [{t("lowerThan")} {ecosystemSuccess}%]
            </span>
            {t("analyserMessage2.3")}
          </div>
        </div>
        <div className="mt--normal">
          <div className="bold color--gray">{t("whatNeggativeElements")}:</div>
          <div className="grid gap--big mt--small pl--big">
            {negativeElements.map((el) => (
              <AnalyzerItem
                key={el.id}
                element={el}
                handleReviewComplementClick={
                  ctx.handleAnalyzerReviewComplementClick
                }
              />
            ))}
          </div>
        </div>
        <div className="mt--small">
          <div className="bold color--gray">{t("analyserImprove")}</div>
          <div>{t("analyserMessage3")}</div>
        </div>
      </div>
      <ModalActions>
        <ModalButton onClick={handleCancel} text={t("cancel")} />
        <ModalButton
          primary
          onClick={() => handleNextStep(ANALYZER_STEP.TROUBLESHOOTING)}
          text={t("troubleshootButton")}
        />
      </ModalActions>
    </Modal>
  );
}

const AnalyzerItem = memo(({ element, handleReviewComplementClick }) => {
  const { t } = useLocalization();

  return (
    <div
      className="grid ai--center gap--big"
      style={{ gridTemplateColumns: "100px max-content 1fr" }}
    >
      <div>{element?.data?.description}</div>
      <RiskIcon
        ability={element.data.abilityToContribute}
        willingness={element.data.willingnessToContribute}
        mode="row"
      />
      <div
        className="underline pointer"
        onClick={() => handleReviewComplementClick(element)}
      >
        {t("reviewItem")}
      </div>
    </div>
  );
});

const ScenarioImplementStrategy = memo(({ strategy }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const ctx = useContext(ScenarioContext);
  const { t } = useLocalization();

  useEffect(() => {
    if (loading) return;
    safeFunction(async () => {
      setLoading(true);
      const values = {
        strategy: strategy,
      };
      const createdId = await createChildScenario(ctx.scenarioId, values);
      openAlertModal({
        title: t("scenarioCreated"),
        closeHandler: () => {
          navigate(`/scenarios/${createdId}`, {
            state: { strategyDocs: strategy },
          });
        },
      });
    })();
  }, [loading, ctx.scenarioId, navigate, strategy, t]);

  return <></>;
});
