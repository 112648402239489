import React, { memo, useCallback, useContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import arrowSrc from '../../../assets/arrow.svg';

import { createChildScenario } from '../../api';

import { scenarioRiskCalculation, valuesReducer } from '../../utils';
import { safeFunction } from '../../../utils/safeFunction';

import { Modal, ModalActions, ModalButton, ModalTitle, Slider } from '../../../components/modal/Modal';
import { ScenarioContext } from '../../ScenarioPage';
import { ANALYZER_STEP, getNegativeComplements } from '../ScenarioAnalyzer';
import { MAJOR_COMPLEMENT } from '../../components/major-complements/MajorElementNode';
import { makeSliderValue } from '../../components/utils';
import { RiskIcon } from '../../components/RiskIcon';
import { openAlertModal } from '../../../components/alert-modal/AlertModal';
import { useLocalization } from '../../../localization/LocalizationProvider';

const troubleShootingValues = {
  loading: false,

  negativeElements: [],

  newTimeline: '',
};

export function ScenarioDelayingModal({ handleCancel }) {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const ctx = useContext(ScenarioContext);

  const [state, dispatch] = useReducer(valuesReducer, troubleShootingValues);

  const { negativeElements, newTimeline, loading } = state;

  useEffect(() => {
    const negativeElements =
      getNegativeComplements(ctx?.elements)
        .map(el => ({
          ...el,
          data: { ...el.data, extraTime: '', newAbilityToContribute: makeSliderValue(el.data.abilityToContribute) }
        }));

    dispatch({ type: 'SET_VALUES', payload: { negativeElements } });
  }, [ctx?.elements]);

  const handleNewTimelineChange = useCallback((e) => {
    dispatch({ type: 'SET_VALUES', payload: { newTimeline: e.target.value } });
  }, []);

  const handleExtraTimeChange = useCallback((el, value) => {
    const newElements = negativeElements.map(e => {
      if (e.id === el.id) {
        return { ...e, data: { ...el.data, extraTime: value } };
      }
      return e;
    });
    dispatch({ type: 'SET_VALUES', payload: { negativeElements: newElements } });
  }, [negativeElements]);

  const handleTargetLevelChange = useCallback((el, value) => {
    const newElements = negativeElements.map(e => {
      if (e.id === el.id) {
        return { ...e, data: { ...el.data, newAbilityToContribute: value } };
      }
      return e;
    });
    dispatch({ type: 'SET_VALUES', payload: { negativeElements: newElements } });
  }, [negativeElements]);

  const handleSubmitClick = useCallback(() => {
    if (!newTimeline) return null;
    if (loading) return null;
    safeFunction(async () => {
      dispatch({ type: 'SET_VALUES', payload: { loading: true } });
      const values = {
        strategy: 'Delaying',
        timelineEVP: Number(newTimeline),
        changingElements: negativeElements.map(el => ({
          id: el.id,
          abilityToContribute: makeSliderValue(el.data.newAbilityToContribute),
          availableIn: Number(el.data.extraTime) + (Number(el.data.availableIn) || 0)
        }))
      };
      const createdId = await createChildScenario(ctx.scenarioId, values);
      openAlertModal({
        title: t('scenarioCreated'), closeHandler: () => {
          navigate(`/scenarios/${createdId}`, { state: { strategyDocs: ANALYZER_STEP.DELAYING } });
        }
      });
    })();
  }, [loading, ctx.scenarioId, navigate, negativeElements, newTimeline, t]);

  const majorComplements = ctx.elements
    .filter(el => el.type === MAJOR_COMPLEMENT)
    .map(mEl => {
      const negativeFound = negativeElements.find(el => el.id === mEl.id);
      return {
        ...mEl,
        data: {
          ...mEl.data,
          abilityToContribute: negativeFound ? negativeFound.data.newAbilityToContribute : mEl.data.abilityToContribute,
        }
      };
    });
  const scenarioRisk = scenarioRiskCalculation(majorComplements);

  return (
    <Modal size='big' open={true}>
      <div className='mb--big'>
        <ModalTitle>{t('delayingModalTitle')}</ModalTitle>
        <div className='color--gray'>
          <div className='bold'>{t('basicIdea')}:</div>
          <div>
            {t('delayingModalMessage')}
          </div>
        </div>
        <div className='mt--normal mb--normal flex ai--center gap--large'>
          <div className='bold color--gray'>{t('currentTimeline')}:</div>
          <MonthsInput
            value={ctx.timelineEVP}
            readOnly
          />
          <HorizontalArrow />
          <div className='bold color--gray'>{t('newTimeline')}:</div>
          <MonthsInput
            value={newTimeline}
            onChange={handleNewTimelineChange}
          />
        </div>
        <div className='bold color--gray'>{t('delayingModalElementsTitle')}:</div>
        <div className='flex jc--space-between ai--center'>
          <div>
            <div className='bold hidden'>spacing</div>
            <div className='grid gap--normal mt--normal pl--big'>
              {negativeElements.map(el =>
                <DelayingItem
                  key={el.id}
                  element={el}
                />
              )}
            </div>
          </div>
          <div>
            <div className='bold hidden'>spacing</div>
            <HorizontalArrow />
          </div>
          <div>
            <div className='bold color--gray'>{t('delayingModalTarget')}</div>
            <div className='grid gap--normal mt--normal pl--big'>
              {negativeElements.map(el =>
                <Slider key={el.id} displayValue={makeSliderValue(el.data.newAbilityToContribute)} value={el.data.newAbilityToContribute} onChange={(v) => handleTargetLevelChange(el, v)} />
              )}
            </div>
          </div>
          <div>
            <div className='bold color--gray'>{t('estimatedExtraTime')}</div>
            <div className='grid gap--normal mt--normal pl--big'>
              {negativeElements.map(el =>
                <MonthsInput
                  value={el.data.extraTime || ''}
                  key={el.id}
                  onChange={(e) => handleExtraTimeChange(el, e.target.value)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalActions style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <div className='flex ai--center'>
          <div style={{ width: '150px' }} className='bold fs--big color--gray-600'>{t('newOverallRiskLevel')}:</div>
          <RiskIcon
            ability={scenarioRisk.ability}
            willingness={scenarioRisk.willingness}
            mode='analyze'
          />
        </div>
        <div className='flex gap--normal'>
          <ModalButton onClick={handleCancel} text={t('cancel')} />
          <ModalButton primary onClick={handleSubmitClick} text={t('implementChanges')} />
        </div>
      </ModalActions>
    </Modal>
  );
}

const HorizontalArrow = memo(() => (
  <img src={arrowSrc} alt="" style={{ transform: 'rotate(45deg)', height: '45px' }} />
));

const MonthsInput = memo(({ value, readOnly, onChange }) => {
  const { t } = useLocalization();

  const maxDigits = 2;
  return (
    <div className='flex ai--center gap--small'>
      <input type='number' readOnly={readOnly} value={value} onChange={onChange} className="sc-modal__input" style={maxDigits ? { width: `${(maxDigits + 2) * 10}px` } : {}} />
      <div className='italic'>{t('months')}</div>
    </div>
  );
});

const DelayingItem = memo(({ element }) => {
  return (
    <div className='flex ai--center gap--small'>
      <div>{element?.data?.reference}</div>
      <RiskIcon
        ability={element.data.abilityToContribute}
        willingness={element.data.willingnessToContribute}
        mode='row'
      />
    </div>
  );
});
