import water_Status0 from "../assets/airtank-0.svg";
import water_Status1 from "../assets/airtank-1.svg";
import water_Status2 from "../assets/airtank-2.svg";
import water_Status3 from "../assets/airtank-3.svg";
import water_Status4 from "../assets/airtank-4.svg";
import water_Status5 from "../assets/airtank-5.svg";

import water_Face0 from "../assets/face-0.svg";
import water_Face1 from "../assets/face-1.svg";
import water_Face2 from "../assets/face-2.svg";
import water_Face3 from "../assets/face-3.svg";
import water_Face4 from "../assets/face-4.svg";
import water_Face5 from "../assets/face-5.svg";

import { makeSliderValueDisplay } from "../components/modal/Modal";

export const SCUBA_DIVING_RISK_ICONS = {
  build: (ability, willingness, mode = "node") => {
    const abilityStyle = { left: "0", height: "100%" };
    const abilityIndexMap = [
      water_Status0,
      water_Status1,
      water_Status2,
      water_Status3,
      water_Status4,
      water_Status5,
    ];
    const abilitySrc = abilityIndexMap[ability.valueIndex];

    const willingnessStyle = {
      left: "25%",
      bottom: "-20%",
      width: "100%",
      height: "auto",
      zIndex: "1",
    };
    const willingnessIndexMap = [
      water_Face0,
      water_Face1,
      water_Face2,
      water_Face3,
      water_Face4,
      water_Face5,
    ];
    const willingnessSrc = willingnessIndexMap[willingness.valueIndex];

    const sizeStyle = () => {
      if (mode === "node") {
        return { height: "90px", width: "45px" };
      }
      if (mode === "graph") {
        return { height: "60px", width: "30px" };
      }
      if (mode === "hud") {
        return { height: "110%", width: "30px" };
      }
      if (mode === "analyze") {
        return { height: "60px", width: "30px" };
      }
      if (mode === "row") {
        return { height: "40px", width: "25px" };
      }
    };

    return (
      <div style={sizeStyle()}>
        <div className="relative" style={{ height: "100%", width: "100%" }}>
          <img
            data-tip="abilityHint"
            data-for={`node${ability.id}`}
            style={abilityStyle}
            className="absolute"
            src={abilitySrc}
            alt=""
          />
          <img
            data-tip="willingnessHint"
            data-for={`node${willingness.id}`}
            style={willingnessStyle}
            className="absolute"
            src={willingnessSrc}
            alt=""
          />
        </div>
      </div>
    );
  },
};

export const FORMAL_RISK_ICONS = {
  build: (ability, willingness, mode = "node") => {
    const defaultStyle = {
      position: "relative",
      fontWeight: "bold",
    };

    const abilityStyle = { left: "0", top: "20px" };

    const willingnessStyle = { right: "0", bottom: "20px" };

    const relative = { position: "relative" };

    const rowText = {
      display: "flex",
      flexWrap: "wrap",
      gap: "5px",
      alignItems: "center",
    };

    const style = (() => {
      if (mode === "node") {
        return {
          wrapper: { fontSize: "22px", height: "90px", width: "45px" },
          ability: { top: "-10px", left: "-30px" },
          willingness: { bottom: "-30px", right: "-30px" },
        };
      }
      if (mode === "graph") {
        return {
          wrapper: { fontSize: "22px", height: "90px", width: "65px" },
          ability: abilityStyle,
          willingness: willingnessStyle,
        };
      }
      if (mode === "hud") {
        return {
          wrapper: { fontSize: "18px", ...rowText },
          ability: relative,
          willingness: relative,
        };
      }
      if (mode === "analyze") {
        return {
          wrapper: { fontSize: "18px", ...rowText },
          ability: relative,
          willingness: relative,
        };
      }
      if (mode === "row") {
        return {
          wrapper: { fontSize: "14px", ...rowText },
          ability: relative,
          willingness: relative,
        };
      }
    })();

    return (
      <div style={{ ...style.wrapper, ...defaultStyle }}>
        <div
          data-tip="abilityHint"
          data-for={`node${ability.id}`}
          style={style.ability}
          className="absolute"
        >
          <span>A</span>
          <span>{makeSliderValueDisplay(ability.value)}%</span>
        </div>
        <div
          data-tip="willingnessHint"
          data-for={`node${willingness.id}`}
          style={style.willingness}
          className="absolute"
        >
          <span>W</span>
          <span>{makeSliderValueDisplay(willingness.value)}%</span>
        </div>
      </div>
    );
  },
};
