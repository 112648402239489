import React, { memo } from 'react';

export const UploadFileButton = memo(({ text, handleFileUpload, id = 'modal-upload' }) => {
  return (
    <div className="relative button primary max-content" style={{ fontSize: '14px' }}>
      <label className="pointer" htmlFor={id}>{text}</label>
      <input
        id={id}
        className="absolute"
        style={{ left: '0', opacity: '0', width: '0.1px', height: '0.1px' }}
        type='file'
        onChange={handleFileUpload}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
    </div>
  );
});
