import { VALUE_ELEMENT } from "../../components/value-complement/ValueComplementNode";

export const INCENTIVIZING_ID = 'INCENTIVIZING';

export function suggestIncentivizing(complements) {
  let counter = 0;
  for (const el of complements.filter(el => el.type === VALUE_ELEMENT)) {
    if (Number(el.data?.abilityToContribute) >= 0.8 && Number(el.data?.willingnessToContribute) <= 0.6) {
      counter += 1;
    }
    if (counter === 2) {
      return INCENTIVIZING_ID;
    }
  }
  return null;
}
