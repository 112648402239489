import React, { memo, useContext } from "react";

import { TooltipWrapper } from "../../../components/TooltipWrapper";
import { ScenarioContext } from "../../ScenarioPage";

import { useLocalization } from "../../../localization/LocalizationProvider";

export const ADD_MAJOR_ELEMENT = 'ADD_MAJOR_ELEMENT';

const style = {
  height: '130px',
  width: '130px',
  cursor: 'pointer',
};
const AddMajorElementNode = memo(() => {
  const ctx = useContext(ScenarioContext);

  const { t } = useLocalization();

  return (
    <>
      <div className="nodrag" data-tip='addMajorComplementHint' data-for='addMajorElement'>
        <AddMajorIcon onClick={() => ctx.handleAddMajorComplementClick()} />
      </div>
      <TooltipWrapper
        id={'addMajorElement'}
        label={t}
        effect="solid"
      />
    </>
  );
});

export default AddMajorElementNode;

function AddMajorIcon({ onClick }) {
  return (
    <svg onClick={onClick} style={style} xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 200.989 200.988">
      <g id="Grupo_268" data-name="Grupo 268" transform="translate(-1207.653 -729.341)">
        <g id="Grupo_264" data-name="Grupo 264">
          <g id="Grupo_263" data-name="Grupo 263" opacity="0.5">
            <path id="Caminho_456" data-name="Caminho 456" d="M1405.754,829.836a97.607,97.607,0,1,1-97.607-97.607A97.606,97.606,0,0,1,1405.754,829.836Z" fill="#fff" className="theme-primary__stroke" stroke="" stroke-miterlimit="10" stroke-width="5.776" />
          </g>
        </g>
        <g id="Grupo_267" data-name="Grupo 267">
          <g id="Grupo_266" data-name="Grupo 266">
            <g id="Grupo_265" data-name="Grupo 265">
              <path id="Caminho_457" data-name="Caminho 457" d="M1347.209,839.572H1319.23v29.422h-19.483V839.572H1271.9V820.088h27.85V790.676h19.483v29.412h27.979Z" fill="#fff" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}