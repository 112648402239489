import React, { useContext } from 'react';
import {
  getEdgeCenter,
} from 'react-flow-renderer';

import './EdgeAddButton.css';

import { ScenarioContext } from '../../../scenario/ScenarioPage';

import { findIntersect, Vector } from '../utils';

export const EDGE_ADD_BUTTON = 'EDGE_ADD_BUTTON';

const foreignObjectSize = 40;

export default function EdgeAddBtn({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  arrowHeadType,
  markerEndId,
  data,
}) {
  const ctx = useContext(ScenarioContext);

  const radius = 85; // TODO CHANGE, from props?
  const offset = 20;
  const pathStart = findIntersect(new Vector(sourceX, sourceY), radius + offset, new Vector(targetX, targetY));
  const pathEnd = findIntersect(new Vector(targetX, targetY), radius + offset, new Vector(sourceX, sourceY));

  const triangleWidth = 14;
  const triangleMidWidth = triangleWidth / 2;
  const triangleHeight = 15;
  const triangle = `${pathEnd.x} ${pathEnd.y}, ${pathEnd.x + triangleHeight} ${pathEnd.y - triangleMidWidth}, ${pathEnd.x + triangleHeight} ${pathEnd.y + triangleMidWidth}`;

  const angle = Math.atan2((pathEnd.y - targetY), pathEnd.x - targetX) * (180 / Math.PI);
  const rotateMarker = { transform: `rotate(${angle}deg)`, transformOrigin: `left center`, transformBox: 'fill-box' };

  const straightPath = `M ${pathStart.x} ${pathStart.y} L ${pathEnd.x} ${pathEnd.y}`;

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX: pathStart.x,
    sourceY: pathStart.y,
    targetX: pathEnd.x,
    targetY: pathEnd.y,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="edge"
        d={straightPath}
      />
      <polygon className='edge-arrow' points={triangle} style={rotateMarker} />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
      >
        <div className='edge-add'>
          <EdgeAddButtonSvg onClick={() => ctx.onEdgeAddClick({ id, position: { x: edgeCenterX, y: edgeCenterY }, data })} />
        </div>
      </foreignObject>
    </>
  );
}

// use this everywhere it works!;
function EdgeAddButtonSvg({ onClick }) {
  return (
    <svg onClick={onClick} className='edge-add__plus' xmlns="http://www.w3.org/2000/svg" width="32.281" height="32.28" viewBox="0 0 32.281 32.28">
      <g id="Grupo_342" data-name="Grupo 342" transform="translate(-887.554 -1625.412)">
        <path id="Caminho_509" data-name="Caminho 509" d="M915.974,1641.555a12.283,12.283,0,1,1-12.28-12.281A12.278,12.278,0,0,1,915.974,1641.555Z" fill="#fff" />
        <g id="Grupo_341" data-name="Grupo 341">
          <path id="Caminho_510" data-name="Caminho 510" d="M910.69,1643.364h-5.2v5.464h-3.626v-5.464H896.7v-3.621h5.17v-5.471h3.626v5.471h5.2Z" fill="var(--theme__color-primary)" />
        </g>
        <path id="Caminho_511" data-name="Caminho 511" d="M919.208,1641.555a15.513,15.513,0,1,1-15.514-15.516A15.509,15.509,0,0,1,919.208,1641.555Z" fill="none" stroke="var(--theme__color-primary)" strokeMiterlimit="10" strokeWidth="1.254" />
      </g>
    </svg>
  );
}