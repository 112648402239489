import React, { memo, useCallback, useEffect, useState } from 'react';

import { useLocalization } from '../localization/LocalizationProvider';

import { useTheme } from './ThemeManager';
import { Modal, ModalActions, ModalButton, ModalTitle } from '../components/modal/Modal';

export function useThemeModalManager() {
  const [isOpen, setIsOpen] = useState(false);

  const { backgrounds, background, handleThemeOptionsChange, color, colors, riskIcon, riskIcons } = useTheme();

  const [backgroundIndex, setBackgroundIndex] = useState({});
  const [currentBackground, setCurrentBackground] = useState({});

  const [currentColor, setCurrentColor] = useState({});
  const [colorIndex, setColorIndex] = useState(0);

  const [currentRiskIcon, setCurrentRiskIcon] = useState({});
  const [riskIconIndex, setRiskIconIndex] = useState(0);

  useEffect(() => {
    setCurrentBackground(background);
    setBackgroundIndex(backgrounds.findIndex(b => b.id === background.id) || 0);
  }, [background, backgrounds]);

  useEffect(() => {
    setCurrentColor(color);
    setColorIndex(colors.findIndex(b => b.id === color.id) || 0);
  }, [color, colors]);

  useEffect(() => {
    setCurrentRiskIcon(riskIcon);
    setRiskIconIndex(riskIcons.findIndex(b => b.id === riskIcon.id) || 0);
  }, [riskIcon, riskIcons]);

  const handleBackgroundChange = useCallback((flag) => {
    let nextIndex = backgroundIndex + flag;
    if (nextIndex < 0) {
      nextIndex = backgrounds.length - 1;
    } else if (nextIndex >= backgrounds.length) {
      nextIndex = 0;
    }
    const b = backgrounds.find((_, i) => i === nextIndex);
    setCurrentBackground(b);
    setBackgroundIndex(nextIndex);
  }, [backgroundIndex, backgrounds]);

  const handleColorChange = useCallback((flag) => {
    let nextIndex = colorIndex + flag;
    if (nextIndex < 0) {
      nextIndex = colors.length - 1;
    } else if (nextIndex >= colors.length) {
      nextIndex = 0;
    }
    const b = colors.find((_, i) => i === nextIndex);
    setCurrentColor(b);
    setColorIndex(nextIndex);
  }, [colorIndex, colors]);

  const handleRiskIconChange = useCallback((flag) => {
    let nextIndex = riskIconIndex + flag;
    if (nextIndex < 0) {
      nextIndex = riskIcons.length - 1;
    } else if (nextIndex >= riskIcons.length) {
      nextIndex = 0;
    }
    const b = riskIcons.find((_, i) => i === nextIndex);
    setCurrentRiskIcon(b);
    setRiskIconIndex(nextIndex);
  }, [riskIconIndex, riskIcons]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleSubmit = useCallback(() => {
    handleThemeOptionsChange({ background: currentBackground, color: currentColor, riskIcon: currentRiskIcon });
    handleClose();
  }, [currentBackground, currentColor, currentRiskIcon, handleClose, handleThemeOptionsChange]);

  return {
    isOpen,
    handleOpen,
    handleClose,

    currentBackground,
    handleBackgroundChange,

    currentColor,
    handleColorChange,

    currentRiskIcon,
    handleRiskIconChange,

    handleSubmit
  };
}

export function ThemeModal({ themeModalManager }) {
  const { t } = useLocalization();
  return (
    <Modal size='big' open={themeModalManager.isOpen} closeOnDimmerClick={true} handleClose={themeModalManager.handleClose}>
      <div>
        <ModalTitle>{t('themesTitle')}</ModalTitle>
        <div className='grid gap--big mb--big'>
          <NextPrevSlider label={t('themesBackground')} currentItemLabel={t(themeModalManager.currentBackground.label)} handleOptionChange={themeModalManager.handleBackgroundChange} />
          <NextPrevSlider label={t('themesRiskIcon')} currentItemLabel={t(themeModalManager.currentRiskIcon.label)} handleOptionChange={themeModalManager.handleRiskIconChange} />
          <NextPrevSlider label={t('themesColor')} currentItemLabel={t(themeModalManager.currentColor.label)} handleOptionChange={themeModalManager.handleColorChange} />
        </div>
        <ModalActions>
          <ModalButton onClick={themeModalManager.handleClose} text={t('cancel')} />
          <ModalButton primary onClick={themeModalManager.handleSubmit} text={t('confirm')} />
        </ModalActions>
      </div>
    </Modal>
  );
}

const NextPrevSlider = memo(({ label, handleOptionChange, currentItemLabel }) => {
  return (
    <div className='flex jc--space-between ai--center fs-600 color--gray-600'>
      <div>{label}</div>
      <div className='flex ai--center gap--normal'>
        <div className='arrow arrow--left pointer' onClick={() => handleOptionChange(-1)}></div>
        <div className='ta--center' style={{ width: '150px' }}>{currentItemLabel}</div>
        <div className='arrow arrow--right pointer' onClick={() => handleOptionChange(1)}></div>
      </div>
    </div>
  );
});
