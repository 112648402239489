import React, { useCallback, useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import "./LoginPage.css";

import googleIcon from "../assets/google.png";

import { PATH as REGISTER_PAGE_PATH } from "./RegisterPage";
import { PATH as PASSWORD_RECOVER_PATH } from "./ForgotPasswordPage";

import { firebaseLoginUser, googleLogin, login } from "./api";
import { Loader } from "./components/Loader/Loader";
import { AUTH_STATUS, useAuthStatus } from "./PrivateRoute";
import { useLocalization } from "../localization/LocalizationProvider";
import { AuthButton } from "./components/AuthButton";
import {
  AuthFooter,
  AuthFormInput,
  AuthFormSubmit,
  AuthHeader,
  AuthLanguage,
  Hero,
  HeroPage,
  VersionFooter,
} from "./components/AuthPages";
import { openAlertModal } from "../components/alert-modal/AlertModal";

const initialLoginState = {
  isLoading: false,
  email: "",
  password: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_EMAIL":
      return { ...state, email: action.payload };
    case "SET_PASSWORD":
      return { ...state, password: action.payload };
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    default:
      throw new Error("Invalid Type");
  }
}

function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useLocalization();

  const { authStatus, user } = useAuthStatus();

  const [state, dispatch] = useReducer(reducer, initialLoginState);
  const { isLoading, email, password } = state;

  const handleGoogleLogin = useCallback(() => {
    googleLogin();
  }, []);

  const handleRegisterClick = useCallback(() => {
    navigate(REGISTER_PAGE_PATH);
  }, [navigate]);

  const handleForgotPasswordClick = useCallback(() => {
    navigate(PASSWORD_RECOVER_PATH);
  }, [navigate]);

  const loginHandler = useCallback(
    (user) => {
      login({ email: user.email, id: user.uid, provider: user.provider })
        .then(() => {
          navigate(
            location?.state?.referrer ? location.state.referrer : "/home"
          );
        })
        .catch((err) => {
          dispatch({ type: "SET_LOADING", payload: false });
          // alert(JSON.stringify(err));
        });
    },
    [navigate, location]
  );

  const handleLoginClick = useCallback(() => {
    if (!email || !password) return null;
    dispatch({ type: "SET_LOADING", payload: true });
    firebaseLoginUser(email, password)
      .then((user) => {
        loginHandler(user);
      })
      .catch((error) => {
        // alert(JSON.stringify(error));
        openAlertModal({
          title: t("error"),
          message: t("invalidEmailOrPassword"),
        });
        dispatch({ type: "SET_LOADING", payload: false });
      });
  }, [email, loginHandler, password]);

  useEffect(() => {
    if (authStatus === AUTH_STATUS.LOGGED && user) {
      loginHandler(user);
    }
  }, [authStatus, user, loginHandler]);

  return (
    <>
      <LoginPageView
        email={email}
        password={password}
        isLoading={isLoading || authStatus === AUTH_STATUS.LOADING}
        dispatch={dispatch}
        handleGoogleLogin={handleGoogleLogin}
        handleRegisterClick={handleRegisterClick}
        handleForgotPasswordClick={handleForgotPasswordClick}
        handleLoginClick={handleLoginClick}
        t={t}
      />
      <ReactTooltip
        id="loginPage"
        getContent={(dataTip) => <div>{t(dataTip)}</div>}
        delayShow={500}
        place="top"
        effect="solid"
        type="light"
        border={true}
        borderColor={"var(--color-dark)"}
        textColor={"var(--color-dark)"}
      />
    </>
  );
}

export default LoginPage;
export const PATH = "/login";

const LoginPageView = ({
  email,
  password,
  isLoading,
  dispatch,
  handleGoogleLogin,
  handleLoginClick,
  handleRegisterClick,
  handleForgotPasswordClick,
  t,
}) => {
  return (
    <HeroPage>
      <Loader active={isLoading} />
      <Hero />
      <div className="auth__form">
        <AuthLanguage />
        <AuthHeader />
        <div className="auth__form-wrapper" id="login-to">
          <div className="title">{t("loginPageTitle")}</div>
          <div className="auth__form-content">
            <AuthFormInput
              value={email}
              label="E-mail"
              onChange={(e) =>
                dispatch({ type: "SET_EMAIL", payload: e.target.value })
              }
              placeholder={t("emailPlaceholder")}
            />
            <AuthFormInput
              value={password}
              label={t("passwordPlaceholder")}
              type="password"
              onChange={(e) =>
                dispatch({ type: "SET_PASSWORD", payload: e.target.value })
              }
              placeholder={t("passwordPlaceholder")}
            />
            <div
              className="auth__form-secondary"
              onClick={handleForgotPasswordClick}
            >
              {t("forgotPassword")}
            </div>
          </div>

          <AuthFormSubmit
            text={t("loginButton")}
            onClick={handleLoginClick}
            disabled={!email || !password}
          />
          <AuthButton
            onClick={handleGoogleLogin}
            label={t("googleButton")}
            iconSrc={googleIcon}
            color={{ "--color": "#4285F4" }}
          />

          <div className="auth__form-next-action">
            {t("loginRegisterLabel")}{" "}
            <span className="click" onClick={handleRegisterClick}>
              {t("loginRegisterButton")}
            </span>
          </div>
          <AuthFooter />
          <VersionFooter />
        </div>
      </div>
    </HeroPage>
  );
};
