import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import LocalizationProvider from './localization/LocalizationProvider';

import PrivateRoute from './auth/PrivateRoute';
import RedirectLogged from './auth/RedirectLogged';

import LoginPage, { PATH as LOGIN_PATH } from './auth/LoginPage';
import RegisterPage, { PATH as REGISTER_PATH } from './auth/RegisterPage';
import HomePage, { HOME_PATH } from './home/HomePage';
import ScenarioPage, { SCENARIO_PATH } from './scenario/ScenarioPage';
import ForgotPasswordPage, { PATH as PASSWORD_RECOVER_PATH } from './auth/ForgotPasswordPage';
import ConfirmPasswordRecoverPage, { PATH as CONFIRM_PASSWORD_RECOVER_PATH } from './auth/ConfirmPasswordRecoverPage';
import { ThemeProvider } from './themes/ThemeManager';
import { AlertModal } from './components/alert-modal/AlertModal';
import { ErrorBoundary } from './components/ErrorBoundary';

function Root() {

  return (
    <LocalizationProvider>
      <ErrorBoundary>
        <ThemeProvider>
          <BrowserRouter>
            <Routes>
              <Route exact path={REGISTER_PATH} element={<RegisterPage />} />
              <Route exact path={LOGIN_PATH} element={<LoginPage />} />
              <Route exact path={PASSWORD_RECOVER_PATH} element={<RedirectLogged><ForgotPasswordPage /></RedirectLogged>} />
              <Route exact path={CONFIRM_PASSWORD_RECOVER_PATH} element={<RedirectLogged><ConfirmPasswordRecoverPage /></RedirectLogged>} />

              <Route path={HOME_PATH} element={<PrivateRoute><HomePage /></PrivateRoute>} />
              <Route exact path={SCENARIO_PATH} element={<PrivateRoute><ScenarioPage /></PrivateRoute>} />

              <Route path='*' element={<Navigate to={'/login'} replace />} />
            </Routes>
          </BrowserRouter>
          <AlertModal />
        </ThemeProvider>
      </ErrorBoundary>
    </LocalizationProvider>
  );
}

export default Root;
