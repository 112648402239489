import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import enFlag from '../assets/flag_EN.png';
import ptFlag from '../assets/flag_PT.png';

import { logout } from '../auth/api';

import { useLocalization } from '../localization/LocalizationProvider';

const CONTENT_STYLE = {
  top: '4.5em',
  right: '2em',
};

export const Menu = memo(({ isOpen, handleClose, options }) => {
  const { t, setLang } = useLocalization();

  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    logout().then(() => {
      navigate('/login');
    });
  }, [navigate]);

  return isOpen ? (
    <div className='sc_modal-dimmer' onClick={handleClose}>
      <div style={CONTENT_STYLE} className='absolute sc_modal-content background border-radius'>
        <div>
          <div className='grid gap--big'>
            <div className='grid gap--normal'>
              {options.map(option =>
                <div className='pointer' onClick={option.handleClick}>{t(option.text)}</div>
              )}
            </div>
          </div>
          <div className='flex ai--center jc--space-between mt--normal'>
            <div className='flex ai--center gap--small'>
              <img className='pointer menu__flag' onClick={() => setLang('en')} src={enFlag} alt='EN' />
              <img className='pointer menu__flag' onClick={() => setLang('pt')} src={ptFlag} alt='PT' />
            </div>
            <div className='pointer button' onClick={handleLogout}>{t('logout')}</div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
});

export const LocalizedMenu = memo(({ isOpen, handleClose, children }) => {

  return isOpen ? (
    <>
      <div className='sc_modal-dimmer' onClick={handleClose} />
      {children}
    </>
  ) : null;
});
