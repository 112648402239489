import React, { useCallback, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLocalization } from '../localization/LocalizationProvider';
import { safeFunction } from '../utils/safeFunction';

import { firebaseCanEmailRecoverPassord, firebaseSendPasswordRecoverEmail } from './api';

import { Loader } from './components/Loader/Loader';
import { openAlertModal } from '../components/alert-modal/AlertModal';
import { AuthFooter, AuthFormInput, AuthFormSubmit, AuthHeader, AuthLanguage, Hero, HeroPage } from './components/AuthPages';

const initialRegisterState = {
  isLoading: false,

  email: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    default:
      throw new Error('Invalid Type');
  }
}

function ForgotPasswordPage() {
  const [state, dispatch] = useReducer(reducer, initialRegisterState);
  const { isLoading, email } = state;

  const navigate = useNavigate();
  const { t } = useLocalization();

  const handleSubmitClick = useCallback(() => {
    if (!email || isLoading) return null;
    dispatch({ type: 'SET_LOADING', payload: true });

    safeFunction(async () => {
      await firebaseCanEmailRecoverPassord(email);
      await firebaseSendPasswordRecoverEmail(email);
      openAlertModal({ title: t('checkInboxMessage') });
    }, () => dispatch({ type: 'SET_LOADING', payload: false }))();
  }, [email, isLoading, t]);

  return (
    <HeroPage>
      <Loader active={isLoading} />
      <Hero />
      <div className='auth__form'>
        <AuthLanguage />
        <AuthHeader />
        <div className='auth__form-wrapper' id='login-to'>
          <div className='title'>{t('passwordResetTitle')}</div>
          <div className='auth__form-content'>
            <AuthFormInput value={email} label='E-mail' onChange={e => dispatch({ type: 'SET_EMAIL', payload: e.target.value })} placeholder={t('emailPlaceholder')} />
          </div>

          <AuthFormSubmit
            text={t('passwordResetConfirmButton')}
            onClick={handleSubmitClick}
            disabled={!email || isLoading}
          />

          <div className='auth__form-next-action'><span className='click' onClick={() => navigate('/login')}>{t('passwordResetLoginLabel')}</span></div>
          <AuthFooter />
        </div>
      </div>
    </HeroPage>
  );
}


export default ForgotPasswordPage;
export const PATH = '/password-forgot';
