import water_backgroundImageSrc from '../assets/bg-water.png';
import water_add from '../assets/plus-2.svg';
import water_add__major from '../assets/plus-3.svg';
import water_centerbackgroundImageSrc from '../assets/bg-center.svg';

import water_Status0 from '../assets/airtank-0.svg';
import water_Status1 from '../assets/airtank-1.svg';
import water_Status2 from '../assets/airtank-2.svg';
import water_Status3 from '../assets/airtank-3.svg';
import water_Status4 from '../assets/airtank-4.svg';
import water_Status5 from '../assets/airtank-5.svg';
import water_Face0 from '../assets/face-0.svg';
import water_Face1 from '../assets/face-1.svg';
import water_Face2 from '../assets/face-2.svg';
import water_Face3 from '../assets/face-3.svg';
import water_Face4 from '../assets/face-4.svg';
import water_Face5 from '../assets/face-5.svg';

export const OCEAN_THEME = {
  id: 'ocean',
  bgImg: water_backgroundImageSrc,
  bgColor: '#40bac5',
  color: '#0f4f73',
  colorLight: 'rgb(10, 156, 220)',
  centerBgImg: water_centerbackgroundImageSrc,

  riskIconsNodeStyle: {
    ability: {
      left: '-30px',
      bottom: '15px',
      width: 'auto',
      height: '90px',
    },
    willingness: {
      left: '-17px',
      bottom: '0px',
      width: '41px',
      zIndex: '1',
    },
  },
  riskIconsHudStyle: {
    ability: {
      height: '120%',
      marginTop: '-10px'
    },
    willingness: {
      height: '60%',
      marginLeft: '-7px'
    },
  },
  riskIconsAnalyzerStyle: {
    ability: {
      height: '120%',
      marginTop: '-10px'
    },
    willingness: {
      height: '60%',
      marginLeft: '-7px'
    },
  },
  riskIconsSrc: {
    0: {
      ability: water_Status0,
      willingness: water_Face0,
    },
    1: {
      ability: water_Status1,
      willingness: water_Face1,
    },
    2: {
      ability: water_Status2,
      willingness: water_Face2,
    },
    3: {
      ability: water_Status3,
      willingness: water_Face3,
    },
    4: {
      ability: water_Status4,
      willingness: water_Face4,
    },
    5: {
      ability: water_Status5,
      willingness: water_Face5,
    },
  },
  addImg: {
    src: water_add,
    style: {
      right: '6px',
      top: '6px',
      width: '25px',
    }
  },
  addMajorComplamentImg: {
    src: water_add__major,
  }
};
