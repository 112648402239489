import React, { useContext, useState } from "react";

import actionsIcon from "../assets/to-do.svg";

import { LocalizedMenu } from "../components/Menu";
import { ScenarioContext } from "./ScenarioPage";
import { VALUE_ELEMENT } from "./components/value-complement/ValueComplementNode";
import { calculateComplementsRisk, valueComplementRisk } from "./utils";
import { useLocalization } from "../localization/LocalizationProvider";

export function ScenarioToDo({ toDoListItems }) {
  const ctx = useContext(ScenarioContext);
  const { t } = useLocalization();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <div
        className="hud__actions--todo pointer"
        onClick={() => setIsOpen((v) => !v)}
      >
        <img src={actionsIcon} alt="" />
      </div>
      <LocalizedMenu isOpen={isOpen} handleClose={() => setIsOpen(false)}>
        <div className="background scenario-to-do__menu border-radius padding">
          <div className="scenario-to-do__menu-title uppercase fs--big ta--center mb--small">
            {t("toDolist")}
          </div>
          <div className="scenario-to-do__menu-items-wrapper">
            <div className="scenario-to-do__menu-items grid gap--small">
              {toDoListItems.map((item, i) => (
                <div
                  title={item.text}
                  key={i}
                  className="pointer scenario-to-do__menu-item"
                  onClick={() =>
                    ctx.handleToDoReviewComplementClick(item.complement)
                  }
                >
                  {item.text}
                </div>
              ))}
            </div>
          </div>
        </div>
      </LocalizedMenu>
    </div>
  );
}

export function buildToDoListItems(t, complements) {
  const ecosystemValidations = [];

  const complementsList = buildComplementsToDoItems(t, complements);

  return [...ecosystemValidations, ...complementsList];
}

function buildComplementsToDoItems(t, complements) {
  const activeItemsTypesList = [FieldsType(t), RiskType(t), TimelineType(t)];
  return complements
    .flatMap((complement) => {
      return activeItemsTypesList.map((type) =>
        type.execute({ complement, complements })
      );
    })
    .filter((executeResult) => executeResult.actionable);
}

function buildComplementText(complement) {
  return ` ${complement.data.description || "N/D"} [${
    complement.type === VALUE_ELEMENT ? "VC" : "MC"
  }]`;
}

function TimelineType(t) {
  const runActionable = (complement, complements) => {
    const childs = complements
      .filter((c) => c.parentId === complement.id)
      .map((c) => c.data);
    if (childs.length === 0) return false;
    const maxChildsTime = childs.reduce(
      (prev, curr) =>
        prev > Number(curr.availableIn) ? prev : Number(curr.availableIn),
      0
    );
    return complement.data.availableIn < maxChildsTime;
  };

  const buildText = (complement) => {
    return t("toDoComplementTimeline") + buildComplementText(complement);
  };

  return {
    execute: (values) => {
      const actionable = runActionable(values.complement, values.complements);
      return {
        type: "TIMELINE",
        actionable,
        text: buildText(values.complement),
        complement: values.complement,
      };
    },
  };
}

function RiskType(t) {
  const RISK_WARNING_THRESHHOLD = 0.1;

  const runActionable = (complement, complements) => {
    const childs = complements
      .filter((c) => c.parentId === complement.id)
      .map((c) => c.data);
    if (childs.length === 0) return false;
    const childsRisk = calculateComplementsRisk(childs);
    const complementRisk = valueComplementRisk(complement.data);
    return complementRisk - childsRisk > RISK_WARNING_THRESHHOLD;
  };

  const buildText = (complement) => {
    return t("toDoComplementRisk") + buildComplementText(complement);
  };

  return {
    execute: (values) => {
      const actionable = runActionable(values.complement, values.complements);
      return {
        type: "RISK",
        actionable,
        text: buildText(values.complement),
        complement: values.complement,
      };
    },
  };
}

function FieldsType(t) {
  function valuePresent(v) {
    return !!v;
  }

  const complementFields = [
    { key: "description", validator: valuePresent, canBlock: true },
    { key: "providedBy", validator: valuePresent },
    { key: "availableIn", validator: valuePresent },
    { key: "abilityToContribute", validator: valuePresent },
    { key: "willingnessToContribute", validator: valuePresent },
  ];

  const runActionable = (complement) => {
    return complementFields.some(
      (field) => field.validator(complement.data[field.key]) === false
    );
  };

  const checkBlocksAnalyze = (complement) => {
    return complementFields
      .filter((f) => f.canBlock)
      .some((field) => field.validator(complement.data[field.key]) === false);
  };

  const buildText = (complement) => {
    return t("toDoComplementFields") + buildComplementText(complement);
  };

  return {
    execute: (values) => {
      const actionable = runActionable(values.complement);
      return {
        type: "FIELDS",
        actionable,
        text: buildText(values.complement),
        complement: values.complement,
        blocksAnalyze: checkBlocksAnalyze(values.complement),
      };
    },
  };
}
