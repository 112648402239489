
export function generateUUID() { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;//random number between 0 and 16
    if (d > 0) {//Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {//Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 || 0x8)).toString(16);
  });
}

export function sortArray(array = [], sortFunc) {
  const a = [...array];
  a.sort(sortFunc);
  return a;
}

export function isNotNullUndefined(value) {
  return value === 0 || (value !== null && value !== undefined);
}

export function uniqueValues(values) {
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  return values.filter(onlyUnique);
}

export function uniqueValuesBy(values, uniqBy) {
  const obj = {};
  return values.reduce((prev, curr) => {
    if (obj[curr[uniqBy]]) return prev;
    obj[curr[uniqBy]] = true;
    return [...prev, curr];
  }, []);
}

export function downloadLocalFile(file, fileName) {
  var element = document.createElement('a');
  element.setAttribute('href', file);
  element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();
}
