import html2canvas from 'html2canvas';
import React, { memo, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import ReactFlow, { ReactFlowProvider, useStore, useStoreState, useZoomPanHelper } from 'react-flow-renderer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import './Scenario.css';

import {
  deleteScenario,
  downloadScenario, getScenario,
  shareScenario
} from './api';

import { generateUUID, uniqueValuesBy } from '../utils/utils';
import { complementsFilter, DEFAULT_ZOOM, guidedModeQueryString, MAX_ZOOM, MIN_ZOOM, MY_ORGANIZATION_OPTION, scenarioRiskCalculation } from './utils';

import { ThemeHOC } from '../themes/ThemeManager';

import ScenarioHud from './ScenarioHud';

import { openAlertModal } from '../components/alert-modal/AlertModal';
import { Modal, ModalActions, ModalButton, ModalTitle } from '../components/modal/Modal';
import { TooltipWrapper } from '../components/TooltipWrapper';
import { HOME_PATH } from '../home/HomePage';
import { useLocalization } from '../localization/LocalizationProvider';
import { ThemeModal, useThemeModalManager } from '../themes/ThemeModal';
import { safeFunction } from '../utils/safeFunction';
import { ScenarioAnalyzerFlowModal } from './analyzer/ScenarioAnalyzer';
import { ScenarioStrategyDocsModal } from './analyzer/ScenarioStrategyDocsModal';
import CenterElementNode, { CENTER_ELEMENT } from './components/center/CenterElement';
import { CenterElementModal } from './components/center/CenterElementModal';
import EdgeAddBtn, { EDGE_ADD_BUTTON } from './components/edge/EdgeAddButton';
import AddMajorElementNode, { ADD_MAJOR_ELEMENT } from './components/major-complements/AddMajorElementNode';
import { MajorElementModal } from './components/major-complements/MajorElementModal';
import MajorElementNode, { MAJOR_COMPLEMENT } from './components/major-complements/MajorElementNode';
import { calculateMajorComplementsPosition } from './components/utils';
import { ValueComplementModal } from './components/value-complement/ValueComplementModal';
import ValueComplementNode, { VALUE_ELEMENT as VALUE_COMPLEMENT } from './components/value-complement/ValueComplementNode';
import { ScenarioTimeline } from './ScenarioTimeline';
import { buildToDoListItems } from './ScenarioToDo';
import { ACTIONS, useScenarioUndoRedoActionsManager } from './scenarioUndoRedoManager';
import ShareScenarioModal from './ShareScenarioModal';

export const ScenarioContext = React.createContext({});

const initialState = {
  scenario: null,
  elements: [],
  rfInstance: null,
  firstLoad: true,

  valueElementOpen: null,
  centerElementOpen: null,
  majorElementOpen: null,

  guidedModeQuery: false,
  strategyDocs: null,

  shareModal: false,

  scenarioAnalyzerOpen: false,
  reviewComplementOnAnalyse: false,

  timelineOpen: false,
  reviewComplementOnTimeline: false,

  actorsOpen: false,
  reviewComplementOnActors: false,

  menuOpen: false,

  deleteScenarioOpen: false,

  toDoListItems: [],
};

function scenarioReducer(state, action) {

  switch (action.type) {

    case 'SET_VALUES': return { ...state, ...action.payload };

    case 'SET_FIRST_LOAD': return { ...state, firstLoad: action.payload };

    case 'SET_SCENARIO': return { ...initialState, rfInstance: state.rfInstance, strategyDocs: state.strategyDocs, scenario: action.payload };

    case 'SET_SCENARIO_DETAILS': return { ...state, ...action.payload };

    case 'SET_ELEMENTS': return { ...state, elements: action.payload };

    case 'ADD_ELEMENTS': return { ...state, elements: state.elements.concat(action.payload) };

    case 'UPDATE_ELEMENT': {
      const _elems = state.elements.map(e => {
        if (e.id === action.payload.id) {
          return { ...e, ...action.payload };
        }
        return e;
      });
      return { ...state, elements: _elems };
    }

    case 'MOVE_VALUE_COMPLEMENT': {
      const _elems = state.elements.map(e => {
        if (e.id === action.payload.id) {
          return { ...e, ...action.payload };
        }
        return e;
      });
      return { ...state, elements: _elems };
    }

    case 'UPDATE_ELEMENT_DATA': {
      const _elems = state.elements.map(e => {
        if (e.id === action.payload.id) {
          return { ...e, data: { ...e.data, ...action.payload.data } };
        }
        return e;
      });
      return { ...state, elements: _elems };
    }

    case 'REMOVE_VALUE_COMPLEMENT': {
      const currentElement = state.elements.find(el => el.id === action.payload);
      const _elems = state.elements
        .filter(el => el.id !== action.payload) // remove element
        .filter(el => el.type === EDGE_ADD_BUTTON ? el.source !== action.payload : true) // remove edge from element
        .map(e => {
          if (e.parentId === action.payload) { // update child elements
            return { ...e, parentId: currentElement.parentId };
          }
          if (e.target === action.payload) { // update child edges
            return { ...e, target: currentElement.parentId };
          }
          return e;
        });
      return { ...state, elements: _elems };
    }

    case 'REMOVE_COMPLEMENTS': {
      const _elems = state.elements
        .filter(el => !action.payload.includes(el.id)) // remove elements
        .filter(el => el.type === EDGE_ADD_BUTTON ? !action.payload.includes(el.source) : true); // remove edge from element
      return { ...state, elements: _elems };
    }

    case 'ADD_MAJOR_COMPLEMENT': {
      const _elems = state.elements.map(e => { // Update position other major complements
        const found = action.payload.complementsToUpdate.find(el => el.id === e.id);
        if (found) {
          return { ...e, position: found.position, index: found.index };
        }
        return e;
      });
      _elems.push(...action.payload.newComplements); // add new major complement and childs
      return { ...state, elements: _elems };
    }

    case 'UPDATE_MAJOR_COMPLEMENTS_POSITION': {
      const _elems = state.elements.map(e => { // Update position other major complements
        const found = action.payload.find(el => el.id === e.id);
        if (found) {
          return { ...e, position: found.position, index: found.index };
        }
        return e;
      });
      return { ...state, elements: _elems };
    }

    case 'ADD_ELEMENT': {
      const _elems = [
        ...state.elements,
        {
          ...action.payload
        }];
      return { ...state, elements: _elems };
    }

    case 'SET_VALUE_ELEMENT_OPEN': {
      return {
        ...state,
        valueElementOpen: action.payload,
        scenarioAnalyzerOpen: !action.payload && state.reviewComplementOnAnalyse ? true : false,
        reviewComplementOnAnalyse: !action.payload ? false : state.reviewComplementOnAnalyse,
        timelineOpen: !action.payload && state.reviewComplementOnTimeline ? true : false,
        reviewComplementOnTimeline: !action.payload ? false : state.reviewComplementOnTimeline,
        actorsOpen: !action.payload && state.reviewComplementOnActors ? true : false,
        reviewComplementOnActors: !action.payload ? false : state.reviewComplementOnActors,
      };
    }

    case 'SET_MAJOR_ELEMENT_OPEN': {
      return {
        ...state,
        majorElementOpen: action.payload,
        scenarioAnalyzerOpen: !action.payload && state.reviewComplementOnAnalyse ? true : false,
        reviewComplementOnAnalyse: !action.payload ? false : state.reviewComplementOnAnalyse,
        timelineOpen: !action.payload && state.reviewComplementOnTimeline ? true : false,
        reviewComplementOnTimeline: !action.payload ? false : state.reviewComplementOnTimeline,
        actorsOpen: !action.payload && state.reviewComplementOnActors ? true : false,
        reviewComplementOnActors: !action.payload ? false : state.reviewComplementOnActors,
      };
    }

    case 'SET_CENTER_ELEMENT_OPEN': return { ...state, centerElementOpen: action.payload };

    case 'SET_RF_INSTANCE': {
      return { ...state, rfInstance: action.payload };
    }

    case 'TOGGLE_MENU': {
      return { ...state, menuOpen: !state.menuOpen };
    }

    default:
      throw Error('Invalid type');
  }
}

const CENTER_POSITION = 250;

function ScenarioPage() {
  const { t } = useLocalization();

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const NODE_TYPES = {
    [VALUE_COMPLEMENT]: ValueComplementNode,
    [MAJOR_COMPLEMENT]: MajorElementNode,
    [CENTER_ELEMENT]: CenterElementNode,
    [ADD_MAJOR_ELEMENT]: AddMajorElementNode,
  };
  const EDGE_TYPES = {
    [EDGE_ADD_BUTTON]: EdgeAddBtn,
  };

  const ref = useRef(null);
  const store = useStore();
  const { setCenter, zoomTo } = useZoomPanHelper();

  const [state, dispatch] = useReducer(scenarioReducer, initialState);
  const {
    scenario, elements, rfInstance, firstLoad, valueElementOpen, centerElementOpen, majorElementOpen,
    guidedModeQuery, shareModal, scenarioAnalyzerOpen, strategyDocs, timelineOpen, actorsOpen,
    deleteScenarioOpen, toDoListItems,
  } = state;

  const actionsManager = useScenarioUndoRedoActionsManager(params.id, dispatch);

  // Undo-Redo
  const undoAction = useCallback(() => {
    actionsManager.undo();
  }, [actionsManager]);

  const redoAction = useCallback(() => {
    actionsManager.redo();
  }, [actionsManager]);

  // INITIAL GET

  // Get scenario
  useEffect(() => {
    function fun() {
      getScenario(params.id)
        .then(scenario => {
          dispatch({ type: 'SET_SCENARIO', payload: scenario });
        })
        .catch(err => {
          alert(err);
          navigate(HOME_PATH);
        });
    }
    fun();
  }, [params, navigate]);

  // Guided mode / strategy check
  useEffect(() => {
    dispatch({
      type: 'SET_VALUES', payload: {
        guidedModeQuery: location?.search?.includes(guidedModeQueryString),
        strategyDocs: location.state?.strategyDocs || null
      }
    });
    window.history.replaceState({}, document.title);
  }, [location]);

  // Map scenario elements
  useEffect(() => {
    if (scenario !== null) {
      const _elements = scenario.elements.flatMap(el => {
        const node = {
          id: el.id,
          type: el.type,
          parentId: el.parentId,
          position: el.position,
          index: el.index,
          data: {
            description: el.description,
            reference: el.reference,
            providedBy: el.providedBy,
            actorType: el.actorType,
            contributionBasis: el.contributionBasis,
            contributingAssumption: el.contributingAssumption,
            availableIn: el.availableIn,
            abilityToContribute: el.abilityToContribute,
            willingnessToContribute: el.willingnessToContribute,
            attachments: el.attachments,
          }
        };
        if (el.parentId && el.type === VALUE_COMPLEMENT) { // build edge
          const edge = {
            id: generateUUID(),
            source: node.id,
            target: node.parentId,
            type: EDGE_ADD_BUTTON,
            arrowHeadType: 'arrowclosed',
            data: {
              source: node.id,
              target: node.parentId
            }
          };
          return [node, edge];
        }
        return node;
      });

      _elements.push({
        id: generateUUID(),
        type: CENTER_ELEMENT,
        position: { x: CENTER_POSITION, y: CENTER_POSITION },
        data: {
          title: scenario.title,
          overarchingVP: scenario.overarchingVP,
          customerSegmentForVP: scenario.customerSegmentForVP,
          timelineEVP: scenario.timelineEVP,
        }
      });

      _elements.push({ // Add Major Complement Add Button
        id: generateUUID(),
        type: ADD_MAJOR_ELEMENT,
        position: calculateMajorComplementsPosition(0, 0)
      });

      dispatch({
        type: 'SET_SCENARIO_DETAILS',
        payload: { depth: scenario.depth, parentId: scenario.parentId, rootId: scenario.rootId }
      });

      dispatch({ type: 'SET_ELEMENTS', payload: _elements });
    }
  }, [scenario]);


  // Focus center element (Not working)
  useEffect(() => {
    function focusCenterNode() {
      const { nodes } = store.getState();
      if (nodes.length) {
        const node = nodes[0];
        const x = node.__rf.position.x + node.__rf.width / 2;
        const y = node.__rf.position.y + node.__rf.height / 2;
        const zoom = DEFAULT_ZOOM;
        setCenter(x, y, zoom);
      }
    }
    if (elements.length > 0 && firstLoad) {
      focusCenterNode();
      dispatch({ type: 'SET_FIRST_LOAD', payload: false });
    }
  }, [elements, setCenter, firstLoad, store]);

  const [, , zoom] = useStoreState((state) => state.transform);

  const handleZoomClick = useCallback((values) => {
    const zoomAmount = (values.value || 0.1) * (values.type === 'plus' ? 1 : -1);
    zoomTo(zoom + zoomAmount);
  }, [zoomTo, zoom]);


  const handleDeleteScenarioClick = useCallback(() => {
    dispatch({ type: 'SET_VALUES', payload: { deleteScenarioOpen: true } });
  }, []);

  const handleDeleteScenario = useCallback(() => {
    safeFunction(async () => {
      await deleteScenario(params.id);
      openAlertModal({ title: t('deleteScenarioSuccess') });
      navigate('/home');
    })();
  }, [navigate, params.id, t]);

  // VALUE COMPLEMENT
  const onValueComplementAddClick = useCallback((parentNode) => {
    const flow = rfInstance.toObject();
    const parentElement = flow.elements.find(el => el.id === parentNode.id);
    const parentParentElement = parentElement.type === MAJOR_COMPLEMENT ? null : flow.elements.find(el => el.id === parentElement.parentId);
    const valueElementsInFlow = flow.elements.filter(el => el.type === VALUE_COMPLEMENT);
    const majorComplementIndex = parentElement.type === MAJOR_COMPLEMENT ? parentElement.index : null;

    actionsManager.execute(ACTIONS.addValueComplement, elements,
      {
        valueElementsInFlow,
        parentId: parentNode.id,
        parentPosition: parentElement?.position,
        parentParentPosition: parentParentElement?.position,
        majorComplementIndex,
      });

  }, [actionsManager, elements, rfInstance]);

  const handleComplementDataChange = useCallback((id, data) => {
    actionsManager.execute(ACTIONS.updateComplement, elements, { id, data });

  }, [actionsManager, elements]);

  const handleValueComplementRemoveClick = useCallback((id) => {
    actionsManager.execute(ACTIONS.removeValueComplement, elements, { id });
  }, [actionsManager, elements]);

  const onValueElementOpen = useCallback((id) => {
    const element = elements.find(el => el.id === id);
    dispatch({
      type: 'SET_VALUE_ELEMENT_OPEN', payload: {
        id,
        ...element.data
      }
    });
  }, [elements]);

  const handleMoveComplement = useCallback((id) => {
    const flow = rfInstance.toObject();
    const elementToSave = flow.elements.find(el => el.id === id);

    actionsManager.execute(ACTIONS.moveValueComplement, elements, { id, position: elementToSave.position });
  }, [rfInstance, elements, actionsManager]);


  // EDGE
  const onEdgeAddClick = useCallback((edge) => {
    actionsManager.execute(ACTIONS.addValueComplementFromEdge, elements, { edge });
  }, [actionsManager, elements]);


  // MAJOR COMPLEMENT
  const handleAddMajorComplementClick = useCallback(() => {
    const flow = rfInstance.toObject();
    const majorComplements = flow.elements.filter(el => el.type === MAJOR_COMPLEMENT);
    if (majorComplements.length === 8) {
      return openAlertModal({ title: t('contactSupportTitle') });
    }

    actionsManager.execute(ACTIONS.addMajorComplement, elements, { majorComplements });
  }, [actionsManager, elements, rfInstance, t]);

  const onMajorElementOpen = useCallback((id) => {
    const element = elements.find(el => el.id === id);
    dispatch({
      type: 'SET_MAJOR_ELEMENT_OPEN', payload: {
        id,
        ...element.data
      }
    });
  }, [elements]);

  const removeMajorComplement = useCallback((id) => {
    actionsManager.execute(ACTIONS.removeMajorComplement, elements, { id });
  }, [actionsManager, elements]);


  // CENTER Element 
  const onCenterElementOpen = useCallback(() => {
    const element = elements.find(el => el.type === CENTER_ELEMENT);
    dispatch({
      type: 'SET_CENTER_ELEMENT_OPEN', payload: {
        ...element.data,
        majorComplements: elements.filter(el => el.type === MAJOR_COMPLEMENT).map(el => el.data)
      }
    });
  }, [elements]);

  const handleUpdateCenterElement = useCallback((values) => {
    actionsManager.execute(ACTIONS.updateVP, elements, values);
  }, [actionsManager, elements]);

  // LOG
  const logElements = useCallback(() => {
    const flow = rfInstance.toObject();
    console.log('Flow Elements', flow.elements);
    console.log('Elements', elements);
  }, [rfInstance, elements]);


  // New Scenario Version
  const handleDuplicateScenario = useCallback(() => {
    /*
     const flow = rfInstance.toObject();
 
     const mappedElements = flow.elements
       .filter(el => el.type !== "EDGE_ADD_BUTTON")
       .map(el => ({ // mapear, alterar, passar a ter as cenas do center fora!
         id: el.id,
         type: el.type,
         position: el.position,
         parentId: el.parentId,
         reference: el.reference,
         description: el.description,
         providedBy: el.providedBy,
         actorType: el.actorType,
         contributionBasis: el.contributionBasis,
         contributingAssumption: el.contributingAssumption,
         availableIn: el.availableIn,
         abilityToContribute: el.abilityToContribute,
         willingnessToContribute: el.willingnessToContribute,
       }));
     
     createChildScenario({
       parentId: params.id,
       values: mappedElements,
     });
     */
  }, []);


  // SHARE
  const handleShareScenarioClick = useCallback(() => {
    dispatch({ type: 'SET_VALUES', payload: { shareModal: true } });
  }, []);

  const handleSubmitShareScenario = useCallback((values) => {
    const flow = rfInstance.toObject();

    const mappedElements = flow.elements
      .filter(el => el.type !== "EDGE_ADD_BUTTON" && el.type !== 'ADD_MAJOR_ELEMENT' && el.type !== CENTER_ELEMENT)
      .map(el => ({
        id: el.id,
        type: el.type,
        parentId: el.parentId,
        position: el.position,
        ...el.data,
      }));

    const centerElement = flow.elements.find(el => el.type === CENTER_ELEMENT);
    const scenarioVP = {
      title: centerElement.data.title,
      overarchingVP: centerElement.data.overarchingVP,
      customerSegmentForVP: centerElement.data.customerSegmentForVP,
      timelineEVP: centerElement.data.timelineEVP,
    };

    shareScenario(params.id, { ...values, ...scenarioVP, elements: mappedElements })
      .then(newScenarioId => {
        navigate(`/scenarios/${newScenarioId}`);
      })
      .catch(e => {
        alert(e.response?.data || e.message);
      });

  }, [params.id, rfInstance, navigate]);


  // ANALYZE
  const handleAnalyzeClick = useCallback((value = false) => {
    if (!value) {
      dispatch({ type: 'SET_VALUES', payload: { scenarioAnalyzerOpen: false } });
    }
    else {
      if (toDoListItems.filter(item => item.blocksAnalyze).length > 0) {
        return openAlertModal({ title: t('verifyTodoList') });
      }
      if (toDoListItems.length > 0) {
        return openAlertModal({
          type: 'DIALOG',
          title: t('verifyTodoList'),
          positiveLabel: t('yes'),
          positiveHandler: () => { },
          negativeLabel: t('no'),
          negativeHandler: () => {
            dispatch({ type: 'SET_VALUES', payload: { scenarioAnalyzerOpen: true } });
          },
        });
      }
      dispatch({ type: 'SET_VALUES', payload: { scenarioAnalyzerOpen: value } });
    }
  }, [t, toDoListItems]);

  const handleAnalyzerReviewComplementClick = useCallback((complement) => {
    dispatch({ type: 'SET_VALUES', payload: { scenarioAnalyzerOpen: false, reviewComplementOnAnalyse: true } });
    if (complement.type === MAJOR_COMPLEMENT) {
      onMajorElementOpen(complement.id);
    } else if (complement.type === VALUE_COMPLEMENT) {
      onValueElementOpen(complement.id);
    }
  }, [onValueElementOpen, onMajorElementOpen]);

  // PRINT
  const handlePrintClick = useCallback(() => {
    html2canvas(document.querySelector("#root")) //meh because everything else needs to be responsive, { windowWidth: 2480, windowHeight: 3508 })
      .then(canvas => {
        const url = canvas.toDataURL();
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = 'scenario_print';
        downloadLink.click();
      })
      .catch(e => {
        openAlertModal({ title: t('error'), message: t('errorMessage') });
      });
  }, [t]);

  // GUIDED MODE
  const handleSkipGuidedModeClick = useCallback(() => {
    navigate(location.pathname, { replace: true });
    dispatch({ type: 'SET_VALUES', payload: { guidedModeQuery: false } });
  }, [navigate, location]);

  const guidedModeEnabled = guidedModeQuery;

  // Timeline
  const handleOpenTimeline = useCallback(() => {
    dispatch({ type: 'SET_VALUES', payload: { timelineOpen: true } });
  }, []);

  const handleTimelineReviewComplementClick = useCallback((complement) => {
    dispatch({ type: 'SET_VALUES', payload: { timelineOpen: false, reviewComplementOnTimeline: true } });
    if (complement.type === MAJOR_COMPLEMENT) {
      onMajorElementOpen(complement.id);
    } else if (complement.type === VALUE_COMPLEMENT) {
      onValueElementOpen(complement.id);
    }
  }, [onValueElementOpen, onMajorElementOpen]);

  // Actors
  const handleSetActorsOpen = useCallback((flag) => {
    dispatch({ type: 'SET_VALUES', payload: { actorsOpen: flag } });
  }, []);

  const handleActorsReviewComplementClick = useCallback((complement) => {
    dispatch({ type: 'SET_VALUES', payload: { actorsOpen: false, reviewComplementOnActors: true } });
    if (complement.type === MAJOR_COMPLEMENT) {
      onMajorElementOpen(complement.id);
    } else if (complement.type === VALUE_COMPLEMENT) {
      onValueElementOpen(complement.id);
    }
  }, [onValueElementOpen, onMajorElementOpen]);


  // ToDo List
  const handleToDoReviewComplementClick = useCallback((complement) => {
    if (complement.type === MAJOR_COMPLEMENT) {
      onMajorElementOpen(complement.id);
    } else if (complement.type === VALUE_COMPLEMENT) {
      onValueElementOpen(complement.id);
    }
  }, [onValueElementOpen, onMajorElementOpen]);


  // Download
  const handleDownloadScenario = useCallback(() => {
    downloadScenario(params.id);
  }, [params.id]);

  // BabySitting
  useEffect(() => {
    if (elements) {
      const toDoListItems = buildToDoListItems(t, elements.filter(complementsFilter));
      dispatch({ type: 'SET_VALUES', payload: { toDoListItems } });
    }
  }, [elements, t]);


  const themeModalManager = useThemeModalManager();

  const handleOpenThemes = useCallback(() => {
    themeModalManager.handleOpen();
  }, [themeModalManager]);

  // GLOBAL STATE
  const providerState = useMemo(() => {
    const centerElement = elements.find(el => el.type === CENTER_ELEMENT);
    const majorComplements = elements.filter(el => el.type === MAJOR_COMPLEMENT);
    const scenarioRisk = scenarioRiskCalculation(majorComplements);
    const scenarioId = params.id;
    const scenarioRootId = scenario?.rootId;

    const elementsProvidedBy = [
      { value: MY_ORGANIZATION_OPTION.value, label: t(MY_ORGANIZATION_OPTION.key) },
      ...elements.filter(el => el.data?.providedBy && el.data?.providedBy !== 'null').map(el => el.data.providedBy)
        .map(v => ({ value: v, label: v }))
    ];
    const providedByOptions = uniqueValuesBy(elementsProvidedBy, 'value');

    return {
      onValueElementAddClick: onValueComplementAddClick,
      onEdgeAddClick,
      onValueElementOpen,
      handleValueComplementRemoveClick,
      handleComplementDataChange,
      onCenterElementOpen,
      logElements,
      handleAddMajorComplementClick,
      onMajorElementOpen,
      removeMajorComplement,
      handleDuplicateScenario,
      handleShareScenarioClick,
      timelineEVP: centerElement?.data?.timelineEVP,
      scenarioRisk,
      handleAnalyzeClick,
      elements,
      handleAnalyzerReviewComplementClick,
      scenarioId,
      scenarioRootId,
      zoom,
      handleZoomClick,
      handlePrintClick,
      handleMoveComplement,
      handleOpenTimeline,
      handleTimelineReviewComplementClick,
      handleActorsReviewComplementClick,
      handleSetActorsOpen,
      actorsOpen,
      providedByOptions,
      handleDeleteScenarioClick,
      toDoListItems,
      handleToDoReviewComplementClick,
      handleDownloadScenario,
      undoAction,
      redoAction,
      actionsManager,
      handleOpenThemes,
    };
  }, [elements, params.id, scenario?.rootId, t, onValueComplementAddClick, onEdgeAddClick, onValueElementOpen, handleValueComplementRemoveClick, handleComplementDataChange, onCenterElementOpen, logElements, handleAddMajorComplementClick, onMajorElementOpen, removeMajorComplement, handleDuplicateScenario, handleShareScenarioClick, handleAnalyzeClick, handleAnalyzerReviewComplementClick, zoom, handleZoomClick, handlePrintClick, handleMoveComplement, handleOpenTimeline, handleTimelineReviewComplementClick, handleActorsReviewComplementClick, handleSetActorsOpen, actorsOpen, handleDeleteScenarioClick, toDoListItems, handleToDoReviewComplementClick, handleDownloadScenario, undoAction, redoAction, actionsManager, handleOpenThemes]);

  return (
    <ScenarioContext.Provider value={providerState}>
      <ThemeHOC>
        <ScenarioHud />
        <div className='scenario'>
          <ReactFlow
            ref={ref}
            nodeTypes={NODE_TYPES}
            edgeTypes={EDGE_TYPES}
            elements={elements}
            nodesConnectable={false}
            minZoom={MIN_ZOOM}
            maxZoom={MAX_ZOOM}
            onLoad={(e) => dispatch({ type: 'SET_RF_INSTANCE', payload: e })}
          />
        </div>
        {valueElementOpen &&
          <ValueComplementModal
            isOpen={valueElementOpen !== null}
            data={valueElementOpen}
            handleClose={() => dispatch({ type: 'SET_VALUE_ELEMENT_OPEN', payload: null })}
          />
        }
        {majorElementOpen &&
          <MajorElementModal
            isOpen={majorElementOpen !== null}
            data={majorElementOpen}
            handleClose={() => dispatch({ type: 'SET_MAJOR_ELEMENT_OPEN', payload: null })}
          />
        }
        <CenterElementModal
          isOpen={centerElementOpen !== null}
          data={centerElementOpen}
          disableEditMajorComplements={true}
          handleConfirmClick={handleUpdateCenterElement}
          handleClose={() => dispatch({ type: 'SET_CENTER_ELEMENT_OPEN', payload: null })}
        />
        <GuidedModeScenario open={guidedModeEnabled} onSkip={handleSkipGuidedModeClick} />
        <ShareScenarioModal
          isOpen={shareModal}
          handleClose={() => dispatch({ type: 'SET_VALUES', payload: { shareModal: false } })}
          handleSubmitClick={handleSubmitShareScenario}
        />
        <ScenarioAnalyzerFlowModal
          isOpen={scenarioAnalyzerOpen}
          handleCancel={() => handleAnalyzeClick(false)}
        />
        <ScenarioStrategyDocsModal
          isOpen={strategyDocs !== null}
          type={strategyDocs}
          handleClose={() => dispatch({ type: 'SET_VALUES', payload: { strategyDocs: null } })}
        />
        <ScenarioTimeline
          isOpen={timelineOpen}
          handleClose={() => dispatch({ type: 'SET_VALUES', payload: { timelineOpen: false } })}
        />
        <DeleteScenarioModal
          isOpen={deleteScenarioOpen}
          handleClose={() => dispatch({ type: 'SET_VALUES', payload: { deleteScenarioOpen: false } })}
          handleSubmit={handleDeleteScenario}
        />
        <TooltipWrapper
          label={t}
          effect="float"
        />
        <ThemeModal
          themeModalManager={themeModalManager}
        />
      </ThemeHOC>
    </ScenarioContext.Provider>
  );
}

function ScenarioPageWrapper({ match }) {

  return (
    <ReactFlowProvider>
      <ScenarioPage match={match} />
    </ReactFlowProvider>
  );
}

export default ScenarioPageWrapper;
export const SCENARIO_PATH = "/scenarios/:id";

const DeleteScenarioModal = memo(({ isOpen, handleClose, handleSubmit }) => {
  const { t } = useLocalization();

  return isOpen ? (
    <Modal open={isOpen}>
      <div>
        <ModalTitle>{t('deleteScenarioTitle')}</ModalTitle>
        <div className='ta--center'>{t('deleteScenarioText')}</div>
      </div>
      <ModalActions>
        <ModalButton onClick={handleClose} text={t('cancel')} />
        <ModalButton danger onClick={handleSubmit} text={t('confirm')} />
      </ModalActions>
    </Modal>
  ) : null;
});

const GuidedModeScenario = memo(({ open, onSkip }) => {

  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (open) {
      setIndex(0);
    }
  }, [open]);

  const buildContent = useCallback(() => { // ADD More popups
    switch (index) {
      case 0:
        return (
          <GuidedModePopup
            title='Welcome'
            messages={[
              'This is the scenario screen which is where most of the ecosystem modeling takes place. The goal here is to try to build a realistic representation of the envisioned ecosystem so the system can guide you in the next steps about developing your ecosystem strategy.',
              'I will now walk you through through the modeling process',
            ]}
          />
        );
      default: return null;
    }
  }, [index]);

  const handleNext = useCallback(() => {
    onSkip();
  }, [onSkip]);

  return (
    <Modal open={open}>
      {buildContent()}
      <div className="flex gap--normal mt--normal jc--end">
        <ModalButton onClick={onSkip} text='Skip' />
        <ModalButton onClick={handleNext} text='Continue' />
      </div>
    </Modal>
  );
});

const GuidedModePopup = memo(({ title, messages }) => {
  return (
    <div className='grid gap--normal'>
      {title ? <div>{title}</div> : null}
      {messages.map(m => <div>{m}</div>)}
    </div>
  );
});
