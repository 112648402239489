import React from "react";
import { Modal, ModalActions, ModalButton } from "../../components/modal/Modal";
import { useLocalization } from "../../localization/LocalizationProvider";

import { ADDING_ID } from "./adding/adding";
import { DELAYING_ID } from "./delaying/delaying";
import { DUPLICATING_ID } from "./duplicating/duplicating";
import { ELIMINATING_ID } from "./eliminating/eliminating";
import { GENERICIZATION_ID } from "./generecization/generecization";
import { INCENTIVIZING_ID } from "./incentivizing/incentivizing";
import { INTEGRATING_ID } from "./integrating/integrating";
import { SPECIALIZING_ID } from "./specializing/specializing";

import strategyManual from '../manuals/strategy_manual.docx';

import { downloadLocalFile } from "../../utils/utils";

const MODAL_STRATEGY = {
  [GENERICIZATION_ID]: { title: 'genericizationHelpModalTitle', items: ['genericizationHelpModalMessage1', 'genericizationHelpModalMessage2', 'genericizationHelpModalMessage3', 'genericizationHelpModalMessage4', 'genericizationHelpModalMessage5'] },
  [DUPLICATING_ID]: { title: 'duplicatingHelpModalTitle', items: ['duplicatingHelpModalMessage1', 'duplicatingHelpModalMessage2', 'duplicatingHelpModalMessage3'] },
  [ELIMINATING_ID]: { title: 'eliminatingHelpModalTitle', file: { data: strategyManual, name: 'strategy.docx' }, items: ['eliminatingHelpModalMessage1', 'eliminatingHelpModalMessage2', 'eliminatingHelpModalMessage3'] },
  [ADDING_ID]: { title: 'addingHelpModalTitle', file: { data: strategyManual, name: 'strategy.docx' }, items: ['addingHelpModalMessage1', 'addingHelpModalMessage2', 'addingHelpModalMessage3'] },
  [INCENTIVIZING_ID]: { title: 'incentivizingHelpModalTitle', file: { data: strategyManual, name: 'strategy.docx' }, items: ['incentivizingHelpModalMessage1', 'incentivizingHelpModalMessage2', 'incentivizingHelpModalMessage3'] },
  [SPECIALIZING_ID]: { title: 'specializingHelpModalTitle', file: { data: strategyManual, name: 'strategy.docx' }, items: ['specializingHelpModalMessage1', 'specializingHelpModalMessage2'] },
  [INTEGRATING_ID]: { title: 'integratingHelpModalTitle', items: ['integratingHelpModalMessage1', 'integratingHelpModalMessage2', 'integratingHelpModalMessage3'] },
  [DELAYING_ID]: { title: 'delayingHelpModalTitle', file: { data: strategyManual, name: 'strategy.docx' }, items: ['delayingHelpModalMessage1', 'delayingHelpModalMessage2', 'delayingHelpModalMessage3'] },
};

export function ScenarioStrategyDocsModal({ handleClose, isOpen, type }) {
  const { t } = useLocalization();

  const strategyValues = MODAL_STRATEGY[type] || {};

  return (
    <Modal open={isOpen}>
      <div>
        <div className='bold fs--normal ta--center mb--big'>{t(strategyValues.title)}</div>
        <ScenarioStrategyDocs strategyValues={strategyValues} />
      </div>
      <ModalActions style={{ alignItems: 'flex-end' }}>
        <div className='flex gap--normal'>
          {strategyValues.file ?
            <ModalButton onClick={() => downloadLocalFile(strategyValues.file.data, strategyValues.file.name)} text={t('downloadMaterial')} />
            : null}
          <ModalButton onClick={handleClose} text={t('close')} />
        </div>
      </ModalActions>
    </Modal>
  );
}

function ScenarioStrategyDocs({ strategyValues }) {
  const { t } = useLocalization();
  return (
    <div className="grid gap--normal">
      {strategyValues.items.map(item => <div key={item}>{t(item)}</div>)}
    </div>
  );
}
