
export const isPasswordValid = (password) => {
  return password && password.length >= 6;
};

export function mapGoogleAuthErrorMessages(code) {
  const map = new Map();
  map.set("auth/invalid-email", "invalidEmail");
  map.set("auth/email-already-in-use", "emailAlreadyInUse");

  return map.get(code) || 'errorMessage';
}