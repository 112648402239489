import firebase from "firebase/app";
import "firebase/auth";

import { api } from "../utils/axios-utils";
import { mapGoogleAuthErrorMessages } from "./utils";

export const LOGIN_PROVIDER = {
  PASSWORD: 'password',
  GOOGLE: 'google',
};

export function googleLogin() {
  firebase.auth().useDeviceLanguage();
  firebase.auth().setPersistence('local').then(() => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  });
}

export function getFireBaseCurrentUser() {
  return firebase.auth().currentUser;
}

export function isFirebaseLoggedIn() {
  return new Promise((res, rej) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const provider = getProviderFromFirebaseUser(user);
        res({ ...user, provider });
      } else {
        res(null);
      }
    });
  });
}

export function login(values) {
  return api.post("/login", values);
}

export async function logout() {
  await firebase.auth().signOut();
  localStorage.removeItem('sid');
  return api.post("/logout");
}

export function validateSession() {
  return api.get("/session");
}

export function registerUser(user) {
  return api.post("/user", user);
}

export function recoverUserPassword(email) {
  return api.post("/password-recovery", { email });
}

export function firebaseRegisterUser(email, password) {
  return new Promise((res, rej) => {
    firebase.auth().createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const provider = getProviderFromFirebaseUser(user);
        res({ ...user, provider });
      })
      .catch((error) => {
        const mappedMessage = mapGoogleAuthErrorMessages(error.code);
        rej({ response: { data: mappedMessage } });
      });
  });
}

export function getProviderFromFirebaseUser(user) {
  return user.providerData?.length > 0 && user.providerData[0]?.providerId;
}

export function firebaseLoginUser(email, password) {
  return new Promise((res, rej) => {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const provider = getProviderFromFirebaseUser(user);
        res({ ...user, provider });
      })
      .catch((error) => {
        const mappedMessage = mapGoogleAuthErrorMessages(error.code);
        rej({ response: { data: mappedMessage } });
      });
  });
}

export function firebaseSendPasswordRecoverEmail(email) {
  firebase.auth().useDeviceLanguage();
  return firebase.auth().sendPasswordResetEmail(email);
}

export function firebaseVerifyPasswordResetCode(actionCode) {
  const auth = firebase.auth();
  return new Promise((res, rej) => {
    auth.verifyPasswordResetCode(actionCode)
      .then((email) => { res(email); })
      .catch(error => {
        const mappedMessage = mapGoogleAuthErrorMessages(error.code);
        rej({ response: { data: mappedMessage } });
      });
  });
}

export function firebaseConfirmPasswordReset(actionCode, newPassword) {
  const auth = firebase.auth();
  return new Promise((res, rej) => {
    auth.confirmPasswordReset(actionCode, newPassword)
      .then((email) => { res(email); })
      .catch(error => {
        const mappedMessage = mapGoogleAuthErrorMessages(error.code);
        rej({ response: { data: mappedMessage } });
      });
  });
}

export function firebaseCanEmailRecoverPassord(email) {
  const auth = firebase.auth();
  return new Promise((res, rej) => {
    auth.fetchSignInMethodsForEmail(email)
      .then(providers => {
        if (!providers || providers.length === 0) {
          rej({ response: { data: 'emailNotRegisted' } });
        }
        if (providers.includes('google.com')) {
          rej({ response: { data: 'invalidGoogleRecoverPassword' } });
        }
        res(true);
      })
      .catch(error => {
        const mappedMessage = mapGoogleAuthErrorMessages(error.code);
        rej({ response: { data: mappedMessage } });
      });
  });
}
