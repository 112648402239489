import React, { useCallback, useContext, useMemo, useState } from 'react';

import EN from './en.json';
import PT from './pt.json';

const DEFAULT_STATE = {
  lang: '',
  t: () => { },
  setLang: () => { },
};

const LocalizationContext = React.createContext(DEFAULT_STATE);

const TRANSLATIONS = {
  en: EN,
  pt: PT,
};

export const LANGUAGES = ['pt', 'en'];

const LANG_KEY = 'pb2b_lang';

function replaceKeys(string = '', obj) {
  if (!obj) return string;
  let newString = string.toString();
  Object.keys(obj).forEach(key => newString = newString.replace(`{${key}}`, obj[key]));
  return newString;
}

const getShortLang = (lang) => {
  const parts = lang.split('-');
  return parts && parts.length > 0 ? parts[0] : lang;
};

const saveLangLocalStorage = lang => {
  localStorage.setItem(LANG_KEY, lang);
};

const getLangLocalStorage = () => {
  return localStorage.getItem(LANG_KEY);
};

export const getLanguage = () => {
  const lang = getLangLocalStorage() || getShortLang(navigator.language || navigator.userLanguage) || 'en';
  return TRANSLATIONS[lang] !== undefined ? lang : 'en';
};

export const translate = (lang, key, values) => {
  const text = TRANSLATIONS[lang][key] || key;
  return replaceKeys(text, values);
};

function LocalizationProvider({ children }) {
  const [lang, innerSetLang] = useState(getLanguage());

  const t = useCallback((key, values) => {
    return translate(lang, key, values);
  }, [lang]);

  const setLang = useCallback((key) => {
    saveLangLocalStorage(key);
    innerSetLang(key);
  }, []);

  const state = useMemo(() => {
    return { setLang, lang, t };
  }, [lang, t, setLang]);

  return <LocalizationContext.Provider value={state}>{children}</LocalizationContext.Provider>;
}

export function useLocalization() {
  const localization = useContext(LocalizationContext);
  return { setLang: localization.setLang, lang: localization.lang, t: localization.t };
}

export default LocalizationProvider;
