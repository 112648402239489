import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { isFirebaseLoggedIn, validateSession } from './api';

import { safeFunction } from '../utils/safeFunction';

import LoadingPage from '../components/LoadingPage';

export const AUTH_STATUS = {
  LOADING: 0,
  LOGGED: 1,
  NOT_LOGGED: -1,
};

function PrivateRoute({ disabled, children }) {
  const { authStatus } = useAuthStatus(disabled);
  const location = useLocation();

  if (disabled) {
    return children;
  }

  return authStatus === AUTH_STATUS.LOADING ? <LoadingPage />
    : authStatus === AUTH_STATUS.LOGGED ? children : <Navigate to="/login" state={{ referrer: location.pathname }} replace />;
}

export default PrivateRoute;

export function useAuthStatus(disabled = false) {
  const [state, setState] = useState({ authStatus: AUTH_STATUS.LOADING, user: null });

  useEffect(() => {
    if (disabled) return null;
    safeFunction(async () => {
      const firebaseLogged = await isFirebaseLoggedIn();
      if (firebaseLogged) {
        await validateSession();
        setState({ authStatus: AUTH_STATUS.LOGGED, user: firebaseLogged });
      } else {
        setState({ authStatus: AUTH_STATUS.NOT_LOGGED, user: null });
      }
    }, null, () => setState({ authStatus: AUTH_STATUS.NOT_LOGGED, user: null }))();

  }, [disabled]);

  if (disabled) return null;

  return state;
}
