import React, { memo } from "react";
import Creatable from 'react-select/creatable';

import './Modal.css';

export const Modal = memo(({ open, children, size = '', style, closeOnDimmerClick = false, handleClose = () => { } }) => {
  return open ? (
    <div className="modal__wrapper" onClick={closeOnDimmerClick ? handleClose : null}>
      <div style={style} className={`modal ${size} background padding border-radius`} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  ) : null;
});

export const ModalDimmer = memo(({ open, children }) => {
  return open ? (
    <div className="sc-modal">
      {children}
    </div>
  ) : null;
});

export const ModalActions = memo(({ children, style }) => {

  return (
    <div style={style} className="flex ai--center jc--center gap--big margin-top">
      {children}
    </div>
  );
});

export const ModalButton = memo(({ text, style, onClick, danger, disabled, primary }) => {
  return (
    <button disabled={disabled} style={style} className={`button ${danger ? 'button--danger' : ''} ${primary ? 'primary' : ''}`} onClick={onClick}>{text}</button>
  );
});

export const ModalInput = memo(({ label, placeholder, value, hint, type = 'text', digits, onChange, error, min }) => {
  const errorStyle = error ? { borderBottom: '1px solid red' } : {};
  return (
    <div className="sc-modal__field">
      <div className="sc-modal__label">{label}</div>
      <span>
        <input placeholder={placeholder} type={type} value={value} onChange={e => onChange(e.target.value)} min={min} className="sc-modal__input" style={digits ? { ...errorStyle, width: `${(digits + 2) * 10}px` } : errorStyle} />
        {hint && <span className="sc-modal__input-hint">{hint}</span>}
      </span>
    </div>
  );
});

export const ModalMultipleInputs = memo(({ label, labelStyle, values, disabled, type = 'text', onChange, placeholders }) => {
  const style = { pointerEvents: disabled ? 'none' : 'all' };
  return (
    <div className="sc-modal__field">
      <div style={labelStyle} className="sc-modal__label">{label}</div>
      <div className="grid gap--small" style={{ width: '100%' }}>
        {values.map((v, i) => (
          <input key={i} placeholder={placeholders[i]} type={type} value={v || ''} onChange={e => !disabled ? onChange(i, e.target.value) : null} disabled={disabled} style={style} className="sc-modal__input" />
        ))}
      </div>
    </div>
  );
});

export const ModalSliderInput = memo(({ label, value, displayValue, onChange }) => {
  return (
    <div className="sc-modal__field">
      <div className="sc-modal__label">{label}</div>
      <Slider
        value={value}
        onChange={onChange}
        displayValue={displayValue}
      />
    </div>
  );
});

export const ModalTitle = memo(({ children }) => {
  return <div className='bold fs--big ta--center color--gray-600 mb--big'>{children}</div>;
});

export const makeSliderValueDisplay = (v) => {
  return Math.round(v * 100) || '';
};

const makeSliderDisplayToValue = (v) => {
  return v / 100;
};

export const Slider = memo(({ value, onChange }) => {
  const sliderValue = makeSliderValueDisplay(value);
  const valuePresent = sliderValue || sliderValue === 0 ? true : false;
  return (
    <div className="flex ai--center gap--small">
      <input style={{ '--thumb-display': valuePresent ? 1 : 0 }} type="range" min={0} max={100} step={10} value={isNaN(sliderValue) ? '' : sliderValue
      } onChange={e => onChange(makeSliderDisplayToValue(e.target.value))} className="sc-modal__sliderV2" />
      <span className={valuePresent ? '' : 'italic'}>{valuePresent ? sliderValue : 'N/D'}</span>
    </div>
  );
});

export const SELECT_STYLES = {
  container: (provided) => ({ ...provided, height: '20px', fontSize: '13px' }),
  control: (provided) => ({
    ...provided,
    border: '1px solid var(--gray)',
    boxShadow: '2px 2px 3px 0px rgb(0 0 0 / 46%)',
    borderRadius: '0',
    minHeight: 'unset',
    width: '155px',
    height: '100%',
    color: 'var(--theme__color-primary)'
  }),
  singleValue: (provided) => {
    return { ...provided, color: 'var(--theme__color-primary)' };
  },
  valueContainer: (provided) => {
    return { ...provided, color: 'var(--theme__color-primary)', height: '100%', padding: '0 8px' };
  },
  indicatorsContainer: (provided) => {
    return { ...provided, color: 'var(--theme__color-primary)', height: '100%' };
  },
  indicatorSeparator: (provided) => {
    return { ...provided, color: 'var(--theme__color-primary)', margin: '0' };
  },
  input: (provided) => {
    return { ...provided, color: 'var(--theme__color-primary)', margin: '0', padding: '0' };
  },
  option: (provided) => ({
    ...provided,
    color: 'var(--theme__color-primary)'
  }),
  placeholder: (provided) => ({
    ...provided,
    fontStyle: 'italic',
    // color: 'var(--sc__color)',
  })
};

export const ModalSelectAllowAdd = memo(({ label, value, onChange, options, placeholder }) => {
  return (
    <div className="sc-modal__field">
      <div className="sc-modal__label">{label}</div>
      <div>
        <Creatable
          options={options}
          onChange={(v) => onChange(v.value)}
          value={value ? { value, label: value } : undefined}
          placeholder={placeholder}
          createOptionPosition='first'
          styles={SELECT_STYLES}
        />
      </div>
    </div>
  );
});
