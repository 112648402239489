import React from 'react';

import ReactTooltip from 'react-tooltip';

export const TooltipWrapper = ({ id, label = () => { }, effect = 'solid' }) => {
  return (
    <ReactTooltip
      id={id}
      getContent={dataTip => (<div>{label(dataTip)}</div>)}
      delayShow={400}
      place="top"
      effect={effect}
      type="light"
      border={true}
      borderColor={'var(--color-dark)'}
      textColor={'var(--color-dark)'}
    />
  );
};
