import { Component } from 'react';

import { getLanguage, translate } from '../localization/LocalizationProvider';

import { openAlertModal } from './alert-modal/AlertModal';

export class ErrorBoundary extends Component {

  componentDidCatch(error, errorInfo) {
    const lang = getLanguage();
    openAlertModal({ title: translate(lang, 'error'), message: translate(lang, 'errorMessage') });
  }

  render() {
    return this.props.children;
  }
}
