import React from 'react';

export function AuthButton({ label, iconSrc, iconSize, color, onClick, disabled }) {
  return (
    <button onClick={onClick} disabled={disabled} style={color} className='auth-button ripple'>
      <img className={`auth-button__icon ${iconSize}`} src={iconSrc} alt='' />
      <div className='auth-button__text'>{label}</div>
    </button>
  );
}
