import usersIcons from '../assets/users.svg';
import hourglassIcon from '../assets/hourglass.svg';

export const SCUBA_HUD_ICONS = {
  type: 'IMG',
  icons: {
    timeline: hourglassIcon,
    actors: usersIcons,
  },
  style: {
    timeline: {},
    actors: {},
  }
};

export const FORMAL_HUD_ICONS = {
  type: 'TEXT',
  icons: {
    timeline: 'timeline',
    actors: 'actors',
  },
  style: {
    timeline: {},
    actors: {},
  }
};
