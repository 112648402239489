import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";

import arrowSrc from "../../assets/arrow.svg";

import { useTheme } from "../../themes/ThemeManager";

import { getRiskLabel, valuesReducer } from "../utils";

import {
  Modal,
  ModalActions,
  ModalButton,
  ModalTitle,
} from "../../components/modal/Modal";
import { Tooltip } from "../../components/tooltip/Tooltip";
import { ScenarioContext } from "../ScenarioPage";
import { useLocalization } from "../../localization/LocalizationProvider";

import {
  GENERICIZATION_ID,
  suggestGenerecization,
} from "./generecization/generecization";
import {
  INCENTIVIZING_ID,
  suggestIncentivizing,
} from "./incentivizing/incentivizing";
import { ELIMINATING_ID, suggestEliminating } from "./eliminating/eliminating";
import { ADDING_ID, suggestAdding } from "./adding/adding";
import { DUPLICATING_ID, suggestDuplicating } from "./duplicating/duplicating";
import {
  SPECIALIZING_ID,
  suggestSpecializing,
} from "./specializing/specializing";
import { DELAYING_ID, suggestDelaying } from "./delaying/delaying";
import { INTEGRATING_ID, suggestIntegrating } from "./integrating/integrating";
import { RiskIcon } from "../components/RiskIcon";

const troubleShootingValues = {
  abilityIcon: null,
  willingnessIcon: null,

  riskLabel: null,
  suggested: [],
};

export function ScenarioTroubleshootingModal({
  handleCancel,
  handleStrategyClick,
}) {
  const ctx = useContext(ScenarioContext);
  const { t, lang } = useLocalization();

  const { riskIcon } = useTheme();

  const [state, dispatch] = useReducer(valuesReducer, troubleShootingValues);

  useEffect(() => {
    const _riskLabel = getRiskLabel(ctx.scenarioRisk, lang);

    const _suggested = [
      suggestDelaying(ctx.elements),
      suggestIntegrating(ctx.elements),
      suggestSpecializing(ctx.elements),
      suggestDuplicating(ctx.elements),
      suggestEliminating(ctx.scenarioRisk?.risk),
      suggestAdding(ctx.scenarioRisk?.risk),
      suggestIncentivizing(ctx.elements),
      suggestGenerecization(ctx.elements),
    ];

    dispatch({
      type: "SET_VALUES",
      payload: { riskLabel: _riskLabel, suggested: _suggested },
    });
  }, [ctx.elements, ctx.scenarioRisk, riskIcon, lang]);

  const { riskLabel, suggested } = state;

  return (
    <Modal size="big" open={true}>
      <div className="mb--big">
        <ModalTitle>{t("troubleshootingModalTitle")}</ModalTitle>
        <div className="flex gap--normal ai--center">
          <div className="bold color--gray">{t("scenarioRiskRating")}: </div>
          <RiskIcon
            ability={ctx.scenarioRisk.ability}
            willingness={ctx.scenarioRisk.willingness}
            mode="analyze"
          />
          <div>
            {t("analyserRiskLabel")}{" "}
            <span className="bold theme-color--primay">{riskLabel}</span>
          </div>
        </div>
        <div className="mt--normal">
          <div className="bold color--gray">{t("whatCanIDo")}:</div>
          <div className="mb--small">{t("troubleshootingModalMessage1")}</div>
          <div className="mb--normal">{t("troubleshootingModalMessage2")}</div>
        </div>
        <RiskStrategyGraph
          handleStrategyClick={handleStrategyClick}
          suggested={suggested}
          ability={ctx.scenarioRisk.ability}
          willingness={ctx.scenarioRisk.willingness}
        />
      </div>
      <ModalActions>
        <ModalButton onClick={handleCancel} text={t("backToScenario")} />
      </ModalActions>
    </Modal>
  );
}

const RiskStrategyGraph = memo(
  ({ willingness, ability, handleStrategyClick, suggested }) => {
    const { t } = useLocalization();

    const options = [
      {
        id: GENERICIZATION_ID,
        pos: 0,
        text: t("troubleshootingGenericization"),
        description: t("troubleshootingGenericizationDescription"),
      },
      {
        id: DUPLICATING_ID,
        pos: 1,
        text: t("troubleshootingDuplicating"),
        description: t("troubleshootingDuplicatingDescription"),
      },
      {
        id: ELIMINATING_ID,
        pos: 2,
        text: t("troubleshootingEliminating"),
        description: t("troubleshootingEliminatingDescription"),
      },
      {
        id: ADDING_ID,
        pos: 3,
        text: t("troubleshootingAdding"),
        description: t("troubleshootingAddingDescription"),
      },
      {
        id: INCENTIVIZING_ID,
        pos: 4,
        text: t("troubleshootingIncentivizing"),
        description: t("troubleshootingIncentivizingDescription"),
      },
      {
        id: SPECIALIZING_ID,
        pos: 5,
        text: t("troubleshootingSpecializing"),
        description: t("troubleshootingSpecializingDescription"),
      },
      {
        id: INTEGRATING_ID,
        pos: 6,
        text: t("troubleshootingIntegrating"),
        description: t("troubleshootingIntegratingDescription"),
      },
      {
        id: DELAYING_ID,
        pos: 7,
        text: t("troubleshootingDelaying"),
        description: t("troubleshootingDelayingDescription"),
      },
    ];

    return (
      <div
        id="graph"
        style={{ height: "200px" }}
        className="relative w100 mt--big center"
      >
        {options.map((op) => (
          <RiskStrategyGraphItem
            key={op.id}
            handleClick={handleStrategyClick}
            option={op}
            suggested={suggested}
          />
        ))}
        <RiskIcon ability={ability} willingness={willingness} mode="graph" />
      </div>
    );
  }
);

const centerRadius = 70;
const arrowSize = 25;
const textOffset = 10;
const textAngledOffset = 5;
const horizontalTextOffset = 40;
const arrowAngledOffset = 50;
const suggestedOffset = 20;

// use the center and then translate from that
const positionTextMap = {
  0: {
    transform: `translate(-50%, -50%) translateY(-${centerRadius + arrowSize + textOffset
      }px)`,
  },
  1: {
    transform: `translate(-50%, -50%) translate(${centerRadius + arrowSize + textAngledOffset
      }px, -${centerRadius + textAngledOffset}px)`,
  },
  2: {
    transform: `translate(-50%, -50%) translateX(${centerRadius + arrowSize + horizontalTextOffset
      }px)`,
  },
  3: {
    transform: `translate(-50%, -50%) translate(${centerRadius + arrowSize + textAngledOffset
      }px, ${centerRadius + textAngledOffset}px)`,
  },
  4: {
    transform: `translate(-50%, -50%) translateY(${centerRadius + arrowSize + textOffset
      }px)`,
  },
  5: {
    transform: `translate(-50%, -50%) translate(-${centerRadius + arrowSize + textAngledOffset
      }px, ${centerRadius + textAngledOffset}px)`,
  },
  6: {
    transform: `translate(-50%, -50%) translateX(-${centerRadius + arrowSize + horizontalTextOffset
      }px)`,
  },
  7: {
    transform: `translate(-50%, -50%) translate(-${centerRadius + arrowSize + textAngledOffset
      }px, -${centerRadius + textAngledOffset}px)`,
  },
};

const positionArrowMap = {
  0: {
    transform: `translate(-50%, -50%) translateY(-${centerRadius}px) rotate(-45deg)`,
  },
  1: {
    transform: `translate(-50%, -50%) translate(${arrowAngledOffset}px, -${arrowAngledOffset}px)`,
  },
  2: {
    transform: `translate(-50%, -50%) translateX(${centerRadius}px) rotate(45deg)`,
  },
  3: {
    transform: `translate(-50%, -50%) translate(${arrowAngledOffset}px, ${arrowAngledOffset}px) rotate(90deg)`,
  },
  4: {
    transform: `translate(-50%, -50%) translateY(${centerRadius}px) rotate(135deg)`,
  },
  5: {
    transform: `translate(-50%, -50%) translate(-${arrowAngledOffset}px, ${arrowAngledOffset}px) rotate(180deg)`,
  },
  6: {
    transform: `translate(-50%, -50%) translateX(-${centerRadius}px) rotate(-135deg)`,
  },
  7: {
    transform: `translate(-50%, -50%) translate(-${arrowAngledOffset}px, -${arrowAngledOffset}px) rotate(-90deg)`,
  },
};

const positionSuggestedMap = {
  0: {
    transform: `translate(-50%, -50%) translateY(-${centerRadius + arrowSize + textOffset + suggestedOffset
      }px)`,
  },
  1: {
    transform: `translate(-50%, -50%) translate(${centerRadius + arrowSize + textAngledOffset + suggestedOffset
      }px, -${centerRadius + textAngledOffset + suggestedOffset}px)`,
  },
  2: {
    transform: `translate(-50%, -50%) translateX(${centerRadius + arrowSize + horizontalTextOffset + suggestedOffset * 3.5
      }px)`,
  },
  3: {
    transform: `translate(-50%, -50%) translate(${centerRadius + arrowSize + textAngledOffset + suggestedOffset
      }px, ${centerRadius + textAngledOffset + suggestedOffset}px)`,
  },
  4: {
    transform: `translate(-50%, -50%) translateY(${centerRadius + arrowSize + textOffset + suggestedOffset
      }px)`,
  },
  5: {
    transform: `translate(-50%, -50%) translate(-${centerRadius + arrowSize + textAngledOffset + suggestedOffset
      }px, ${centerRadius + textAngledOffset + suggestedOffset}px)`,
  },
  6: {
    transform: `translate(-50%, -50%) translateX(-${centerRadius + arrowSize + horizontalTextOffset + suggestedOffset * 3.5
      }px)`,
  },
  7: {
    transform: `translate(-50%, -50%) translate(-${centerRadius + arrowSize + textAngledOffset + suggestedOffset
      }px, -${centerRadius + textAngledOffset + suggestedOffset}px)`,
  },
};

const posMap = {
  0: "top",
  1: "left",
  2: "left",
  3: "top",
  4: "top",
  5: "top",
  6: "right",
  7: "right",
};

const RiskStrategyGraphItem = memo(({ option, handleClick, suggested }) => {
  const { t } = useLocalization();

  const onClick = useCallback(() => {
    handleClick(option.id);
  }, [handleClick, option.id]);

  const textStyle = {
    top: "50%",
    left: "50%",
    width: "max-content",
    ...positionTextMap[option.pos],
  };
  const arrowStyle = {
    top: "50%",
    left: "50%",
    height: `${arrowSize}px`,
    ...positionArrowMap[option.pos],
  };
  const suggestedStyle = {
    top: "50%",
    left: "50%",
    color: "red",
    ...positionSuggestedMap[option.pos],
  };

  const isSuggested = suggested.includes(option.id);
  return (
    <>
      <div
        style={textStyle}
        id={option.id}
        className={`bold color--gray absolute ${isSuggested ? "underline" : ""
          }`}
      >
        {option.text}
      </div>
      {isSuggested ? (
        <div style={suggestedStyle} className="absolute bold fs--tiny">
          {t("suggested")}
        </div>
      ) : null}
      <Tooltip idFor={option.id} pos={posMap[option.pos]} z="9">
        <div>
          <div>{option.description}</div>
          <ModalButton
            onClick={onClick}
            style={{ marginLeft: "auto", display: "block" }}
            text={t("strategize")}
          />
        </div>
      </Tooltip>
      <img style={arrowStyle} className="absolute" src={arrowSrc} alt="" />
    </>
  );
});
