import React, { useCallback, useEffect, useState } from 'react';
import { useLocalization } from '../../localization/LocalizationProvider';

import { Modal, ModalActions, ModalButton } from '../modal/Modal';

export function openAlertModal(data) {
  const event = new CustomEvent('OpenAlertModal');
  event.myData = data;
  window.dispatchEvent(event);
}

export function AlertModal() {
  const { t } = useLocalization();

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const cb = (evt) => {
      setData(evt.myData);
      setIsOpen(true);
    };

    window.addEventListener('OpenAlertModal', cb, false);
    return () => {
      window.removeEventListener('OpenAlertModal', cb);
    };
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    if (data.closeHandler) { data.closeHandler(); }
    setData(null);
  }, [data]);

  return (
    <Modal open={isOpen} size='tiny'>
      <div className='ta--center'>
        <div className='bold fs-600 mb--normal color--gray-600'>{data?.title}</div>
        <div className='fs'>{data?.message}</div>
      </div>
      <ModalActions>
        {data?.type === 'DIALOG' ?
          <>
            <ModalButton text={data.negativeLabel} onClick={() => { handleClose(); data.negativeHandler(); }} />
            <ModalButton primary text={data.positiveLabel} onClick={() => { handleClose(); data.positiveHandler(); }} />
          </>
          :
          <ModalButton text={t('ok')} onClick={handleClose} />
        }
      </ModalActions>
    </Modal>
  );
}
