import { VALUE_ELEMENT } from "../../components/value-complement/ValueComplementNode";
import { HIGHT_RISK, valueComplementRisk } from "../../utils";

export const DUPLICATING_ID = 'DUPLICATING';

export function suggestDuplicating(complements) {
  return complements
    .filter(el => el.type === VALUE_ELEMENT)
    .filter(el => valueComplementRisk(el.data) <= HIGHT_RISK).length <= 3 ?
    DUPLICATING_ID : null;
}
