import React, { createRef, memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import "./Tooltip.css";

const DEFAULT_STYLE = {
  display: "block",
  position: "absolute",
  backgroundColor: "white",
  border: "1px solid var(--theme__color-primary)",
  padding: "8px 12px",
  width: "40%",
  maxWidth: "240px",
};

export const Tooltip = memo(({ idFor, z, pos, children, delay = 300 }) => {
  const lockRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [elementDimensions, setElementDimensions] = useState({});
  const [popupStyle, setPopupStyle] = useState({ ...DEFAULT_STYLE, zIndex: z });

  useEffect(() => {
    function funcIn() {
      clearTimeout(lockRef.current);
      lockRef.current = setTimeout(() => {
        setShowModal(true);
        setPopupStyle((v) => ({
          ...v,
          display: "block",
          top: elementDimensions.y + "px",
          left: elementDimensions.x + "px",
        }));
      }, delay);
    }
    function funcOut() {
      clearTimeout(lockRef.current);
      lockRef.current = setTimeout(() => {
        setShowModal(false);
        setPopupStyle((v) => ({ ...v, display: "block" }));
      }, delay);
    }

    const el = document.querySelector(`#${idFor}`);
    el.addEventListener("mouseover", funcIn, false);
    el.addEventListener("mouseout", funcOut, false);

    const rect = el.getBoundingClientRect();
    setElementDimensions({
      x: rect.left, // - parentRect.left,
      y: rect.top, //  - parentRect.top,
      width: rect.width,
      height: rect.height,
    });

    return () => {
      el.removeEventListener("mouseover", funcIn);
      el.removeEventListener("mouseout", funcOut);
    };
  }, [idFor, delay, elementDimensions.y, elementDimensions.x]);

  const popupRef = createRef(null);

  useEffect(() => {
    function funcIn() {
      clearTimeout(lockRef.current);
      lockRef.current = setTimeout(() => {
        setShowModal(true);
        setPopupStyle((v) => ({ ...v, display: "block" }));
      }, delay);
    }
    function funcOut() {
      clearTimeout(lockRef.current);
      lockRef.current = setTimeout(() => {
        setShowModal(false);
        setPopupStyle((v) => ({ ...v, display: "block" }));
      }, delay);
    }
    const el = popupRef?.current;
    if (el) {
      el.addEventListener("mouseover", funcIn, false);
      el.addEventListener("mouseout", funcOut, false);
      return () => {
        el.removeEventListener("mouseover", funcIn);
        el.removeEventListener("mouseout", funcOut);
      };
    }
  }, [popupRef, delay]);

  return (
    <>
      {showModal &&
        createPortal(
          <div
            className={`tooltip ${pos} tooltip-customize`}
            style={popupStyle}
            ref={popupRef}
          >
            <div className="tooltip__content">{children}</div>
          </div>,
          document.querySelector(".scenario")
        )}
    </>
  );
});
