import React, { memo, useCallback, useEffect, useReducer, useState } from "react";

import { Modal, ModalActions, ModalButton, ModalInput, ModalMultipleInputs } from "../../../components/modal/Modal";
import { useLocalization } from "../../../localization/LocalizationProvider";

const initialState = {
  isLoading: false,

  title: '',
  overarchingVP: '',
  customerSegmentForVP: '',
  majorComplements: [{ description: '' }, { description: '' }, { description: '' }, { description: '' }],
  timelineEVP: '',

  guidedModeKey: null,

  titleWarning: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_VALUES':
      return { ...state, ...action.payload };

    default:
      throw new Error('Invalid type');
  }
}

export function CenterElementModal({ isOpen, isLoading, disableEditMajorComplements, data, handleConfirmClick, handleClose, guideMode, guideModeClick }) {
  const { t } = useLocalization();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { title, overarchingVP, customerSegmentForVP, majorComplements, timelineEVP, guidedModeKey, titleWarning } = state;

  useEffect(() => {
    if (isOpen) {
      dispatch({
        type: 'SET_VALUES',
        payload: {
          title: data?.title || '',
          overarchingVP: data?.overarchingVP || '',
          customerSegmentForVP: data?.customerSegmentForVP || '',
          majorComplements: data?.majorComplements || initialState.majorComplements,
          timelineEVP: data?.timelineEVP || '',
          guidedModeKey: null,
        }
      });
    }
  }, [isOpen, data]);

  const innerHandleClose = useCallback(() => {
    dispatch({ type: 'SET_VALUES', payload: { guidedModeKey: null } });
    handleClose();
  }, [handleClose]);

  const handleTitleChange = useCallback((value) => {
    dispatch({ type: 'SET_VALUES', payload: { title: value } });
  }, []);

  const handleOverarchingVPChange = useCallback((value) => {
    dispatch({ type: 'SET_VALUES', payload: { overarchingVP: value } });
  }, []);

  const handleCustomerSegmentForVPChange = useCallback((value) => {
    dispatch({ type: 'SET_VALUES', payload: { customerSegmentForVP: value } });
  }, []);

  const handleTimelineEVPChange = useCallback((value) => {
    dispatch({ type: 'SET_VALUES', payload: { timelineEVP: value } });
  }, []);

  const handleMajorComplementChange = useCallback((index, value) => {
    dispatch({
      type: 'SET_VALUES', payload: {
        majorComplements: majorComplements.map((comp, i) => {
          if (i === index) { return { ...comp, description: value }; }
          return comp;
        })
      }
    });
  }, [majorComplements]);

  const onConfirmClick = useCallback(() => {
    if (!title || isLoading) { return null; }
    handleConfirmClick({
      title,
      overarchingVP,
      customerSegmentForVP,
      majorComplements,
      timelineEVP,
    }, guidedModeKey !== null);
  }, [title, isLoading, handleConfirmClick, overarchingVP, customerSegmentForVP, majorComplements, timelineEVP, guidedModeKey]);

  const handleGuideModeClick = useCallback((key) => {
    if (!title) {
      return dispatch({ type: 'SET_VALUES', payload: { titleWarning: true } });
    }
    dispatch({ type: 'SET_VALUES', payload: { guidedModeKey: key, titleWarning: false } });
  }, [title]);

  const isSubmitDisabled = !title || isLoading;

  const titleRequiredFlag = !title && titleWarning;

  return isOpen ? (
    <>
      <Modal open={isOpen} closeOnDimmerClick={true} handleClose={innerHandleClose}>
        <div className="grid gap--normal">
          <ModalInput
            label={t('title')}
            value={title || ''}
            onChange={handleTitleChange}
            placeholder={t('titleHint')}
            error={titleRequiredFlag}
          />
          <div className="flex align--center jc--space-between gap--normal">
            <ModalInput
              label={t('overarchingVP')}
              value={overarchingVP || ''}
              onChange={handleOverarchingVPChange}
              placeholder={t('shortDescriptionHint')}
            />
            {guideMode ? <div onClick={() => handleGuideModeClick('overarchingVP')} className="underline fs--small scenario__color pointer">{t('guideMe')}</div> : null}
          </div>
          <div className="flex align--center jc--space-between gap--normal">
            <ModalInput
              label={t('vpCustomerSegments')}
              value={customerSegmentForVP || ''}
              onChange={handleCustomerSegmentForVPChange}
              placeholder={t('shortDescriptionHint')}
            />
            {guideMode ? <div onClick={() => handleGuideModeClick('customerSegmentForVP')} className="underline fs--small scenario__color pointer">{t('guideMe')}</div> : null}
          </div>
          <div className="flex jc--space-between gap--normal">
            <ModalMultipleInputs
              label={t('majorComplements')}
              values={majorComplements.map(v => v.description) || []}
              placeholders={[
                t('insertFirst'),
                t('insertSecond'),
                t('insertThird'),
                t('insertFourth'),
              ]}
              disabled={disableEditMajorComplements}
              onChange={handleMajorComplementChange}
            />
            {guideMode ? <div onClick={() => handleGuideModeClick('majorComplements')} className="underline fs--small scenario__color pointer">{t('guideMe')}</div> : null}
          </div>
          <div className="flex jc--space-between gap--normal">
            <ModalInput
              label={t('timelineLabel')}
              value={timelineEVP || ''}
              onChange={handleTimelineEVPChange}
              hint={t('months')}
              type='number'
              digits={3}
            />
            {guideMode ? <div onClick={() => handleGuideModeClick('timelineEVP')} className="underline fs--small scenario__color pointer">{t('guideMe')}</div> : null}
          </div>
        </div>
        <ModalActions>
          <ModalButton onClick={innerHandleClose} text={t('cancel')} />
          <ModalButton primary disabled={isSubmitDisabled} onClick={onConfirmClick} text={t('confirm')} />
        </ModalActions>
      </Modal>
      {guidedModeKey ?
        <GuidedModeModal
          guidedModeKey={guidedModeKey}
          onClose={innerHandleClose}
          onSwitch={handleGuideModeClick}
          onNextClick={handleGuideModeClick}
          onFinish={onConfirmClick}

          onOverarchingVPChange={handleOverarchingVPChange}
          overarchingVP={overarchingVP}

          customerSegmentForVP={customerSegmentForVP}
          onCustomerSegmentForVPChange={handleCustomerSegmentForVPChange}

          majorComplements={majorComplements.map(v => v.description) || []}
          onMajorComplementChange={handleMajorComplementChange}

          timelineEVP={timelineEVP}
          onTimelineEVPChange={handleTimelineEVPChange}

        />
        : null}
    </>
  ) : null;
}

const GuidedModeModal = memo(({
  onClose,
  onSwitch,
  guidedModeKey,

  overarchingVP,
  onOverarchingVPChange,

  customerSegmentForVP,
  onCustomerSegmentForVPChange,

  majorComplements,
  onMajorComplementChange,

  timelineEVP,
  onTimelineEVPChange,

  onNextClick,
  onFinish,
}) => {
  const { t } = useLocalization();

  const [lastStep, setLastStep] = useState(false);

  const buildContent = useCallback(() => {
    switch (guidedModeKey) {
      case 'overarchingVP': return <GuidedOverarchingVP value={overarchingVP} onChange={onOverarchingVPChange} />;
      case 'customerSegmentForVP': return <GuidedCustomerSegmentForVP value={customerSegmentForVP} onChange={onCustomerSegmentForVPChange} />;
      case 'majorComplements': return <GuidedMajorComplements values={majorComplements} onChange={onMajorComplementChange} />;
      case 'timelineEVP': return <GuidedTimelineEVP value={timelineEVP} onChange={onTimelineEVPChange} />;
      default: return null;
    }
  }, [customerSegmentForVP, guidedModeKey, majorComplements, onCustomerSegmentForVPChange, onMajorComplementChange, onOverarchingVPChange, onTimelineEVPChange, overarchingVP, timelineEVP]);

  const handleNext = useCallback(() => {
    const order = ['overarchingVP', 'customerSegmentForVP', 'majorComplements', 'timelineEVP'];
    const index = order.findIndex(v => v === guidedModeKey);
    const nextIndex = index + 1;
    setLastStep(nextIndex === order.length - 1);
    if (nextIndex < order.length) {
      return onNextClick(order[nextIndex]);
    }
    onFinish();
  }, [guidedModeKey, onNextClick, onFinish]);

  return (
    <Modal size='big' open={true}>
      {buildContent()}
      <div className="flex jc--space-between">
        <ModalButton onClick={() => onSwitch(null)} text={t('switchUnguidedProcessButton')} />
        <div className="flex gap--normal">
          <ModalButton primary={lastStep} onClick={handleNext} text={lastStep ? t('confirm') : t('next')} />
        </div>
      </div>
    </Modal>
  );
});

const GuidedOverarchingVP = memo(({ value, onChange }) => {
  const { t } = useLocalization();

  return (
    <div className="guided-modal">
      <div className="guided-modal__title">{t('guideOverarchingVPTitle1')}<span className="scenario__color bold">{t('guideOverarchingVPTitle2')}</span>{t('guideOverarchingVPTitle3')}</div>
      <div className="guided-modal__inputs">
        <textarea className="guided-modal__textarea" value={value} onChange={(e) => onChange(e.target.value)} />
      </div>
      <div>
        <p className="bold guided-modal__sub-title">{t('explanation')}</p>
        <p>{t('guideOverarchingVPMessage1')}</p>
        <p className="guided-modal__example">{t('guideOverarchingVPMessage2')}</p>
        <p>{t('guideOverarchingVPMessage3')}</p>
      </div>
    </div>
  );
});

const GuidedCustomerSegmentForVP = memo(({ value, onChange }) => {
  const { t } = useLocalization();
  return (
    <div className="guided-modal">
      <div className="guided-modal__title">{t('guideCustomerSegmentForVPTitle1')}<span className="scenario__color bold">{t('guideCustomerSegmentForVPTitle2')}</span>{t('guideCustomerSegmentForVPTitle3')}</div>
      <div className="guided-modal__inputs">
        <textarea className="guided-modal__textarea" value={value} onChange={(e) => onChange(e.target.value)} />
      </div>
      <div>
        <p className="bold guided-modal__sub-title">{t('explanation')}</p>
        <p>{t('guideCustomerSegmentForVPMessage1')}</p>
        <p className="guided-modal__example">{t('guideCustomerSegmentForVPMessage2')}</p>
        <p>{t('guideCustomerSegmentForVPMessage3')}</p>
      </div>
    </div>
  );
});

const GuidedTimelineEVP = memo(({ value, onChange }) => {
  const { t } = useLocalization();

  return (
    <div className="guided-modal">
      <div className="guided-modal__title">{t('guideTimelineEVPTitle1')}<span className="scenario__color bold">{t('guideTimelineEVPTitle2')}</span>{t('guideTimelineEVPTitle3')}</div>
      <div className="guided-modal__inputs">
        <input type="number" className="guided-modal__input" value={value} onChange={(e) => onChange(e.target.value)} />
        <span className="sc-modal__input-hint">{t('months')}</span>
      </div>
      <div>
        <p className="bold guided-modal__sub-title">{t('explanation')}</p>
        <p>{t('guideTimelineEVPMessage1')}</p>
        <p className="guided-modal__example">{t('guideTimelineEVPMessage2')}</p>
      </div>
    </div>
  );
});

const GuidedMajorComplements = memo(({ values, onChange }) => {
  const { t } = useLocalization();
  return (
    <div className="guided-modal">
      <div className="guided-modal__title">{t('guideMajorComplementsTitle1')}<span className="scenario__color bold">{t('guideMajorComplementsTitle2')}</span>{t('guideMajorComplementsTitle3')}</div>
      <div className="guided-modal__inputs">
        <div className="grid gap--normal" style={{ gridTemplateColumns: '1fr 1fr' }}>
          {values.map((v, i) => (
            <input value={v} onChange={e => onChange(i, e.target.value)} className="guided-modal__input" />
          ))}
        </div>
      </div>
      <div>
        <p className="bold guided-modal__sub-title">{t('explanation')}</p>
        <p>{t('guideMajorComplementsMessage1')}</p>
        <p className="guided-modal__example">{t('guideMajorComplementsMessage2')}</p>
        <p>{t('guideMajorComplementsMessage3')}</p>
      </div>
    </div>
  );
});
