import { VALUE_ELEMENT } from "../../components/value-complement/ValueComplementNode";
import { complementsFilter, HIGHT_RISK, MY_ORGANIZATION_OPTION, valueComplementRisk } from "../../utils";

export const SPECIALIZING_ID = 'SPECIALIZING';

export function suggestSpecializing(complements) {
  const highRiskMyOrganization = complements
    .filter(el => complementsFilter(el) && el.data.providedBy === MY_ORGANIZATION_OPTION.value)
    .some(el => valueComplementRisk(el.data) <= 0.6);

  const hightRiskComplements = complements
    .filter(el => el.type === VALUE_ELEMENT)
    .filter(el => valueComplementRisk(el.data) <= HIGHT_RISK).lenght <= 3;

  return highRiskMyOrganization || hightRiskComplements;
}
