import { MAJOR_COMPLEMENT } from "./components/major-complements/MajorElementNode";
import { VALUE_ELEMENT } from "./components/value-complement/ValueComplementNode";

export const RISK_SLIDER_STEP = 0.1;
export const RISK_SLIDER_LEVEL = 0.2;

export const MIN_ZOOM = 0.1;
export const DEFAULT_ZOOM = 0.6;
export const MAX_ZOOM = 1.1;

export function mapInRange(value) {
  if (!value && value !== 0) return 0;
  if (value === 0) return 1;
  return Math.round(value / RISK_SLIDER_LEVEL + 0.005);
  /*
  null -> 0;
  0 -> 1;
  0.1, 0.2 -> 1;
  0.3, 0.4 -> 2;
  0.5, 0.6 -> 3;
  0.7, 0.8 -> 4;
  0.9, 1 -> 5;
  */
}

export const guidedModeQueryString = "?guided=true";

export const MY_ORGANIZATION_OPTION = {
  value: "myOrganization",
  key: "myOrganization",
};

export function toNumber(value, defaultNumber) {
  return Number(value) || defaultNumber;
}

export function scenarioRiskCalculation(majorComplements) {
  if (!majorComplements || majorComplements.length === 0) {
    return { ability: 0, willingness: 0 };
  }

  const obj = majorComplements.reduce(
    (prev, curr) => {
      return {
        ability: prev.ability * toNumber(curr.data.abilityToContribute, 0),
        willingness:
          prev.willingness * toNumber(curr.data.willingnessToContribute, 0),
      };
    },
    { ability: 1, willingness: 1 }
  );

  return {
    ...obj,
    risk: obj.ability < obj.willingness ? obj.ability : obj.willingness,
  };
}

export function valueComplementRisk(values) {
  const w = toNumber(values.willingnessToContribute, 0);
  const a = toNumber(values.abilityToContribute, 0);
  return a < w ? a : w;
}

export function calculateComplementsRisk(complements) {
  return complements
    .map(valueComplementRisk)
    .reduce((curr, prev) => curr * prev, 1);
}

export function getRiskIndex(riskValues) {
  const abilityMapped = mapInRange(toNumber(riskValues.ability, 0));
  const willingnessMapped = mapInRange(toNumber(riskValues.willingness, 0));
  return abilityMapped < willingnessMapped ? abilityMapped : willingnessMapped;
}

export const HIGHT_RISK = 0.6;

export function getRiskLabel(riskValues, lang = "en") {
  const value = getRiskIndex(riskValues);

  const labelMap = {
    en: {
      0: "N/A",
      1: "Very high Risk",
      2: "High Risk",
      3: "Moderate Risk",
      4: "Low Risk",
      5: "Very low Risk",
    },
    pt: {
      0: "N/A",
      1: "Risco muito elevado",
      2: "Risco elevado",
      3: "Risco moderado",
      4: "Risco baixo",
      5: "Risco muito baixo",
    },
  };
  return labelMap[lang] ? labelMap[lang][value] : labelMap["en"][value];
}

export function valuesReducer(state, action) {
  switch (action.type) {
    case "SET_VALUES":
      return { ...state, ...action.payload };
    default:
      alert("INVALID TYPE");
  }
}

export function getChildElements(elements, id) {
  const directChilds = elements.filter((el) => el.parentId === id);
  if (directChilds.length === 0) {
    return [];
  }
  const toRet = directChilds.flatMap((el) => {
    const r = getChildElements(elements, el.id);
    return r.concat([el]);
  });
  return toRet;
}

const Vector = (x, y) => ({ x, y });

const maxDistance = 300;
const maxRange = 600;

const FORCE_INDEX_POS = {
  TOP: 1,
  RIGHT: 2,
  BOTTOM: 3,
  LEFT: 4,
};

const majorComplementIndexAddPos = {
  1: { 1: FORCE_INDEX_POS.TOP },
  2: { 1: FORCE_INDEX_POS.TOP, 2: FORCE_INDEX_POS.BOTTOM },
  3: {
    1: FORCE_INDEX_POS.TOP,
    2: FORCE_INDEX_POS.BOTTOM,
    3: FORCE_INDEX_POS.BOTTOM,
  },
  4: {
    1: FORCE_INDEX_POS.TOP,
    2: FORCE_INDEX_POS.BOTTOM,
    3: FORCE_INDEX_POS.BOTTOM,
    4: FORCE_INDEX_POS.LEFT,
  },
  5: {
    1: FORCE_INDEX_POS.TOP,
    2: FORCE_INDEX_POS.RIGHT,
    3: FORCE_INDEX_POS.BOTTOM,
    4: FORCE_INDEX_POS.LEFT,
    5: FORCE_INDEX_POS.TOP,
  },
  6: {
    1: FORCE_INDEX_POS.TOP,
    2: FORCE_INDEX_POS.RIGHT,
    3: FORCE_INDEX_POS.BOTTOM,
    4: FORCE_INDEX_POS.BOTTOM,
    5: FORCE_INDEX_POS.LEFT,
    6: FORCE_INDEX_POS.TOP,
  },
  7: {
    1: FORCE_INDEX_POS.TOP,
    2: FORCE_INDEX_POS.RIGHT,
    3: FORCE_INDEX_POS.BOTTOM,
    4: FORCE_INDEX_POS.BOTTOM,
    5: FORCE_INDEX_POS.LEFT,
    6: FORCE_INDEX_POS.LEFT,
    7: FORCE_INDEX_POS.TOP,
  },
  8: {
    1: FORCE_INDEX_POS.TOP,
    2: FORCE_INDEX_POS.RIGHT,
    3: FORCE_INDEX_POS.RIGHT,
    4: FORCE_INDEX_POS.BOTTOM,
    5: FORCE_INDEX_POS.BOTTOM,
    6: FORCE_INDEX_POS.LEFT,
    7: FORCE_INDEX_POS.LEFT,
    8: FORCE_INDEX_POS.TOP,
  },
};

export function findAvailablePosition(
  elements,
  elementPosition,
  parentPosition,
  majorComplementsCount,
  majorComplementIndex = null
) {
  const radius = 120;

  const forceSide = majorComplementIndex
    ? majorComplementIndexAddPos[majorComplementsCount][majorComplementIndex]
    : null;

  if (
    forceSide === FORCE_INDEX_POS.BOTTOM ||
    (parentPosition &&
      parentPosition.y < elementPosition.y &&
      minDistanceInAxis(parentPosition.y, elementPosition.y))
  ) {
    const y = elementPosition.y + maxDistance;
    const x = elementPosition.x;
    return tryFindPosition(elements, Vector(x, y), radius, "x");
  }
  if (
    forceSide === FORCE_INDEX_POS.TOP ||
    (parentPosition &&
      parentPosition.y > elementPosition.y &&
      minDistanceInAxis(parentPosition.y, elementPosition.y))
  ) {
    const y = elementPosition.y - maxDistance;
    const x = elementPosition.x;
    return tryFindPosition(elements, Vector(x, y), radius, "x");
  }

  if (
    forceSide === FORCE_INDEX_POS.RIGHT ||
    (parentPosition &&
      parentPosition.x < elementPosition.x &&
      minDistanceInAxis(parentPosition.x, elementPosition.x))
  ) {
    const y = elementPosition.y;
    const x = elementPosition.x + maxDistance;
    return tryFindPosition(elements, Vector(x, y), radius, "y");
  }
  if (
    forceSide === FORCE_INDEX_POS.LEFT ||
    (parentPosition &&
      parentPosition.x > elementPosition.x &&
      minDistanceInAxis(parentPosition.x, elementPosition.x))
  ) {
    const y = elementPosition.y;
    const x = elementPosition.x - maxDistance;
    return tryFindPosition(elements, Vector(x, y), radius, "y");
  }

  return Vector(elementPosition.x, elementPosition.y - maxDistance);
}

function minDistanceInAxis(v1, v2) {
  return Math.abs(v1 - v2) > 200;
}

/*
function minDistance(p1, p2) {
  return getDistance(p1, p2) > 200;
}
*/
export function getDistance(p1, p2) {
  let y = p2.x - p1.x;
  let x = p2.y - p1.y;

  return Math.sqrt(x * x + y * y);
}

const offset = 170;
function tryFindPosition(elements, initialPoint, radius, axis) {
  const possiblePositions = generatePossiblePositions(
    initialPoint,
    offset,
    axis
  );
  for (const pos of possiblePositions) {
    const overlaps = overlapsElements(elements, pos, radius);
    if (!overlaps) return pos;
  }
  return possiblePositions[0];
}

function generatePossiblePositions(initialPoint, offset, axis) {
  const points = [initialPoint];
  const max = initialPoint[axis] + maxRange;
  const min = initialPoint[axis] - maxRange;
  const pointsNumber = Math.round((Math.abs(max) - Math.abs(min)) / offset);
  let times = 1;
  for (let i = 0; i < pointsNumber; ++i) {
    const isOdd = i % 2 === 1;
    points.push({
      ...initialPoint,
      [axis]: initialPoint[axis] + offset * times * (isOdd ? -1 : 1),
    });
    times += isOdd ? 1 : 0;
  }
  return points;
}

function overlapsElements(elements, point, radius) {
  for (const el of elements) {
    const overlaps = ptInCircle(el.position, point, radius) !== 1;
    if (overlaps) return true;
  }
  return false;
}

function ptInCircle(pt, center, r) {
  const lhs = Math.pow(center.x - pt.x, 2) + Math.pow(center.y - pt.y, 2);
  const rhs = Math.pow(r, 2);
  return lhs < rhs ? -1 : lhs === rhs ? 0 : 1;
}

export function complementsFilter(complement) {
  return (
    complement.type === VALUE_ELEMENT || complement.type === MAJOR_COMPLEMENT
  );
}
