import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import analyseIcon from "../assets/analyse.svg";
import boatIcon from "../assets/boat.svg";
import photoIcon from "../assets/photo.png";
import questionIcon from "../assets/question.svg";
import redoIcon from "../assets/redo.png";
import scenarioTreeIcon from "../assets/scenario_tree.png";
import gearIcon from "../assets/settings.png";
import shareIcon from "../assets/share.png";
import undoIcon from "../assets/undo.png";
import zoomIcon from "../assets/zoom.svg";

import { downloadLocalFile, isNotNullUndefined } from "../utils/utils";
import { getRiskLabel, MAX_ZOOM, MIN_ZOOM } from "./utils";

import { useLocalization } from "../localization/LocalizationProvider";
import { useTheme } from "../themes/ThemeManager";

import { Menu } from "../components/Menu";
import { safeFunction } from "../utils/safeFunction";
import { getScenarioTree } from "./api";
import { MAJOR_COMPLEMENT } from "./components/major-complements/MajorElementNode";
import { RiskIcon } from "./components/RiskIcon";
import { ScenarioContext } from "./ScenarioPage";
import { ScenarioToDo } from "./ScenarioToDo";

import manualENUrl from "./manuals/en/manual2.docx";
import manualPTUrl from "./manuals/pt/manual2.docx";

export function getManualUrlByLang(lang) {
  const manuals = new Map();
  manuals.set("en", manualENUrl);
  manuals.set("pt", manualPTUrl);
  return manuals.get(lang) || manualENUrl;
}

function ScenarioHud() {
  const ctx = useContext(ScenarioContext);
  return (
    <>
      <ScenarioProperties
        handleSave={ctx.onSaveScenario}
        timeline={ctx.timelineEVP}
        scenarioRisk={ctx.scenarioRisk}
        elements={ctx.elements}
        handleOpenTimeline={ctx.handleOpenTimeline}
        handleActorsReviewComplementClick={
          ctx.handleActorsReviewComplementClick
        }
        actorsOpen={ctx.actorsOpen}
        handleSetActorsOpen={ctx.handleSetActorsOpen}
      />
      <ScenarioConfigurations
        handleLog={ctx.logElements}
        zoom={ctx.zoom}
        handleZoomClick={ctx.handleZoomClick}
        actionsManager={ctx.actionsManager}
        handleDeleteScenarioClick={ctx.handleDeleteScenarioClick}
        handleDownloadScenario={ctx.handleDownloadScenario}
        handleOpenThemes={ctx.handleOpenThemes}
      />
      <ScenarioActions
        handleAnalyzeClick={ctx.handleAnalyzeClick}
        toDoListItems={ctx.toDoListItems}
      />
      <ScenarioUtils
        handlePrintClick={ctx.handlePrintClick}
        handleShare={ctx.handleShareScenarioClick}
        scenarioRootId={ctx.scenarioRootId}
        scenarioId={ctx.scenarioId}
      />
    </>
  );
}

export default ScenarioHud;

const ScenarioProperties = memo(
  ({
    handleSave,
    timeline,
    scenarioRisk,
    elements = [],
    handleOpenTimeline,
    handleActorsReviewComplementClick,
    actorsOpen,
    handleSetActorsOpen,
  }) => {
    const { t, lang } = useLocalization();

    const { hudIcons } = useTheme();

    const majorComplements = elements.filter(
      (el) => el.type === MAJOR_COMPLEMENT
    );
    const showRisk =
      majorComplements.length > 0 &&
      majorComplements.every(
        (el) =>
          isNotNullUndefined(el.data.abilityToContribute) &&
          isNotNullUndefined(el.data.willingnessToContribute)
      );

    const riskLabel = showRisk ? getRiskLabel(scenarioRisk, lang) : "N/A";

    return (
      <div className="hud hud__properties background">
        <div
          onClick={handleSave}
          className="hud__properties--status hud__row__cell"
        >
          {showRisk ? (
            <RiskIcon
              id={"0"}
              ability={scenarioRisk.ability}
              willingness={scenarioRisk.willingness}
              mode="hud"
            />
          ) : null}
          <span data-tip={"overallRiskHint"}>{riskLabel}</span>
        </div>
        <div className="hud__properties--users hud__row__cell">
          {hudIcons.type === "TEXT" ? (
            <div
              className="pointer"
              onClick={() => handleSetActorsOpen(!actorsOpen)}
              data-tip={"actors"}
            >
              {t(hudIcons.icons.actors)}
            </div>
          ) : (
            <img
              className="pointer"
              onClick={() => handleSetActorsOpen(!actorsOpen)}
              src={hudIcons.icons.actors}
              alt=""
              data-tip={"actors"}
            />
          )}
          <ActorsList
            isOpen={actorsOpen}
            elements={elements}
            handleClose={() => handleSetActorsOpen(false)}
            handleClickComplement={handleActorsReviewComplementClick}
          />
        </div>
        <div className="hud__properties--time hud__row__cell">
          {hudIcons.type === "TEXT" ? (
            <div data-tip={"timeline"} onClick={handleOpenTimeline}>
              {t(hudIcons.icons.timeline)}
            </div>
          ) : (
            <img
              className="pointer"
              src={hudIcons.icons.timeline}
              alt=""
              data-tip={"timeline"}
              onClick={handleOpenTimeline}
            />
          )}
          <span>
            {timeline} {t("months")}
          </span>
        </div>
      </div>
    );
  }
);

function groupByActors(elements) {
  const saved = {};
  const res = elements.reduce((prev, curr) => {
    const actorType = curr.data?.actorType;
    if (!actorType || saved[actorType]) return prev;
    saved[actorType] = true;
    const actorElements = elements.filter(
      (el) => el.data?.actorType === actorType
    );
    return [{ actorType, elements: actorElements }, ...prev];
  }, []);
  return res;
}

const ActorsList = ({
  isOpen,
  handleClose,
  elements,
  handleClickComplement,
}) => {
  const { t } = useLocalization();
  const [actorOpen, setActorOpen] = useState(null);

  useEffect(() => {
    const escFunction = (event) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    setActorOpen(null);
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [handleClose]);

  const actors = groupByActors(elements);

  return isOpen ? (
    <>
      <div className="sc_modal-dimmer" onClick={handleClose}></div>
      <div className="hud__actors-list-wrapper padding background border-radius">
        <div className="mb--normal color--gray-600">{t("actorsTitle")}</div>
        <div className="grid gap--small">
          {actors.map((item) => (
            <div key={item.actorType}>
              <div
                className="pointer fs--small"
                onClick={(e) => {
                  e.stopPropagation();
                  setActorOpen((v) =>
                    v === item.actorType ? null : item.actorType
                  );
                }}
              >
                {t(item.actorType)}
              </div>
              {actorOpen === item.actorType ? (
                <div className="grid gap--small pl--normal fs--small">
                  {item.elements.map((el) => (
                    <div
                      key={el.id}
                      onClick={() => handleClickComplement(el)}
                      className="pointer underline italic fs--tiny"
                    >
                      {el.data?.description}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </>
  ) : null;
};

const ScenarioConfigurations = memo(
  ({
    handleLog,
    zoom,
    handleZoomClick,
    handleDeleteScenarioClick,
    handleDownloadScenario,
    actionsManager,
    handleOpenThemes,
  }) => {
    const { lang } = useLocalization();

    const [menuOpen, setMenuOpen] = useState(false);

    const navigate = useNavigate();

    const displayZoom = useMemo(() => {
      const value = ((zoom - MIN_ZOOM) / (MAX_ZOOM - MIN_ZOOM)) * 100;
      return Math.floor(value);
    }, [zoom]);

    const handleGoHome = useCallback(() => {
      navigate("/home");
    }, [navigate]);

    return (
      <div data-html2canvas-ignore className="hud hud__configs background">
        <div className="hud__configs--zoom hud__row__cell">
          <span data-tip={"zoom"}>{displayZoom}%</span>
          <div className="relative" style={{ height: "100%" }}>
            <img src={zoomIcon} alt="" />
            <div
              data-tip={"zoomIn"}
              onClick={() => handleZoomClick({ type: "plus" })}
              className="hud__configs-zoom-btn hud__configs-zoom-btn--plus"
            />
            <div
              data-tip={"zoomOut"}
              onClick={() => handleZoomClick({ type: "minus" })}
              className="hud__configs-zoom-btn hud__configs-zoom-btn--minus"
            />
          </div>
        </div>
        <div
          className="flex ai--center gap--normal color--primary"
          style={{ fontSize: "25px", width: "135px" }}
        >
          <img
            src={undoIcon}
            alt=""
            className={`hud__configs-action-btn pointer ${actionsManager.canUndo ? "" : "no-click"
              }`}
            onClick={actionsManager.undo}
          />
          <img
            src={redoIcon}
            alt=""
            className={`hud__configs-action-btn pointer ${actionsManager.canRedo ? "" : "no-click"
              }`}
            onClick={actionsManager.redo}
          />
        </div>
        <div className="hud__configs--help hud__row__cell">
          <img
            data-tip={"tbd"}
            onClick={() =>
              downloadLocalFile(getManualUrlByLang(lang), "manual.docx")
            }
            src={questionIcon}
            alt=""
          />
        </div>
        <div className="hud__configs--gear hud__row__cell">
          <img
            className="pointer"
            onClick={() => setMenuOpen((v) => !v)}
            data-tip={"menuHint"}
            src={gearIcon}
            alt=""
          />
          <Menu
            isOpen={menuOpen}
            handleClose={(e) => setMenuOpen(false)}
            options={[
              { handleClick: handleGoHome, text: "menu_home" },
              { handleClick: handleOpenThemes, text: "menu_theme" },
              {
                handleClick: handleDownloadScenario,
                text: "menu_downloadFile",
              },
              {
                handleClick: handleDeleteScenarioClick,
                text: "menu_deleteScenario",
              },
            ]}
          />
        </div>
      </div>
    );
  }
);

const ScenarioActions = memo(({ handleAnalyzeClick, toDoListItems }) => {
  const { t } = useLocalization();
  return (
    <div data-html2canvas-ignore className="hud hud__actions">
      <ScenarioToDo toDoListItems={toDoListItems} />
      <div
        onClick={() => handleAnalyzeClick(true)}
        className="hud__actions--analyse"
      >
        <img className="hud__actions--analyse-bg" src={analyseIcon} alt="" />
        <span className="hud__actions--analyse-text">{t("analyseButton")}</span>
        <img src={boatIcon} alt="" />
      </div>
    </div>
  );
});

const ScenarioUtils = memo(
  ({ handlePrintClick, handleShare, scenarioRootId, scenarioId }) => {
    const onPrintClick = useCallback(() => {
      handlePrintClick();
    }, [handlePrintClick]);

    return (
      <div data-html2canvas-ignore className="hud hud__utils">
        <ScenarioTree scenarioRootId={scenarioRootId} scenarioId={scenarioId} />
        <img
          src={photoIcon}
          alt=""
          data-tip={"printHint"}
          className="hud__utils-icon"
          onClick={onPrintClick}
        />
        <img
          src={shareIcon}
          data-tip={"shareHint"}
          onClick={handleShare}
          className="hud__utils-icon"
          alt=""
        />
      </div>
    );
  }
);

const ScenarioTree = memo(({ scenarioRootId, scenarioId }) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [scenarioTree, setScenarioTree] = useState([]);

  useEffect(() => {
    safeFunction(async () => {
      if (scenarioRootId) {
        const treeScenarios = await getScenarioTree(scenarioRootId);
        treeScenarios.sort((v1, v2) => (v1.depth <= v2.depth ? 1 : -1));
        setScenarioTree(treeScenarios);
      }
    })();
  }, [scenarioRootId]);

  const handleScenarioClick = useCallback(
    (id) => {
      if (scenarioId === id) return null;
      setIsOpen(false);
      navigate(`/scenarios/${id}`);
    },
    [scenarioId, navigate]
  );

  return scenarioTree.length > 1 ? (
    <div className={`hud__utils-tree ${isOpen ? "open" : ""}`}>
      <img
        src={scenarioTreeIcon}
        className="pointer hud__utils-icon"
        data-tip={"scenarioTreeHint"}
        onClick={() => setIsOpen((v) => !v)}
        alt=""
      />
      {isOpen ? (
        <div className="background border-radius hud__utils-tree-wrapper">
          <div className="hud__utils-tree-content">
            {scenarioTree.map((sc) => (
              <div
                key={sc.id}
                title={sc.title}
                className={`pointer scenario-tree__menu-item ${scenarioId === sc.id ? "bold no-click" : ""
                  }`}
                onClick={() => handleScenarioClick(sc.id)}
              >
                {sc.title}
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  ) : null;
});
