import React, { memo, useCallback, useContext } from 'react';

import { ScenarioContext } from '../../ScenarioPage';

import { Node } from '../Node';

export const VALUE_ELEMENT = 'VALUE_COMPLEMENT';

const ValueComplementNode = memo(({ id, data }) => {
  const ctx = useContext(ScenarioContext);

  const handleOpen = useCallback((id) => {
    ctx.onValueElementOpen(id);
  }, [ctx]);

  const handleMove = useCallback((id) => {
    ctx.handleMoveComplement(id);
  }, [ctx]);

  return (
    <Node
      id={id}
      description={data.description}
      handleAddClick={ctx.onValueElementAddClick}

      handleOpen={handleOpen}
      handleMove={handleMove}

      willingness={data.willingnessToContribute}
      ability={data.abilityToContribute}
    />
  );
});

export default ValueComplementNode;
