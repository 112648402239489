import React, { useCallback, useEffect, useReducer, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { safeFunction } from '../utils/safeFunction';

import { firebaseVerifyPasswordResetCode, firebaseConfirmPasswordReset } from './api';

import { PATH as LOGIN_PATH } from './LoginPage';

import { Loader } from './components/Loader/Loader';
import { isPasswordValid } from './utils';
import { useLocalization } from '../localization/LocalizationProvider';
import { openAlertModal } from '../components/alert-modal/AlertModal';
import { AuthFooter, AuthFormInput, AuthFormSubmit, AuthHeader, AuthLanguage, Hero, HeroPage } from './components/AuthPages';

const initialRegisterState = {
  isLoading: false,

  firebaseActionCode: '',
  firebaseActionCodeVerified: null,

  password: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PASSWORD':
      return { ...state, password: action.payload };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_FIREBASE_CODE':
      return { ...state, firebaseActionCode: action.payload };
    case 'SET_VERIFIED':
      return { ...state, firebaseActionCodeVerified: action.payload };
    default:
      throw new Error('Invalid Type');
  }
}

function ConfirmPasswordRecoverPage() {
  const [state, dispatch] = useReducer(reducer, initialRegisterState);
  const { isLoading, password, firebaseActionCode, firebaseActionCodeVerified } = state;
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useLocalization();

  const isValidPassword = useMemo(() => {
    return isPasswordValid(password);
  }, [password]);

  useEffect(() => {
    const code = queryParams.get('oobCode');
    dispatch({ type: 'SET_FIREBASE_CODE', payload: code });
  }, [queryParams]);

  useEffect(() => {
    if (firebaseActionCode) {
      safeFunction(async () => {
        await firebaseVerifyPasswordResetCode(firebaseActionCode);
        dispatch({ type: 'SET_VERIFIED', payload: true });
      })();
    }
  }, [firebaseActionCode]);

  const handleSubmitClick = useCallback(() => {
    if (isLoading || !password || !firebaseActionCode || !firebaseActionCodeVerified) return null;

    if (!isValidPassword) {
      return openAlertModal({ title: t('passwordEnforceMessage') });
    }

    safeFunction(async () => {
      dispatch({ type: 'SET_LOADING', payload: true });
      await firebaseConfirmPasswordReset(firebaseActionCode, password);
      openAlertModal({
        title: t('passwordResetMessage'),
        closeHandler: () => {
          navigate(LOGIN_PATH);
        }
      });
    }, () => dispatch({ type: 'SET_LOADING', payload: false }))();
  }, [isLoading, password, firebaseActionCode, firebaseActionCodeVerified, isValidPassword, t, navigate]);

  return (
    <HeroPage>
      <Loader active={isLoading} />
      <Hero />
      <div className='auth__form'>
        <AuthLanguage />
        <AuthHeader />
        <div className='auth__form-wrapper' id='login-to'>
          <div className='title'>{t('passwordResetConfirmTitle')}</div>
          <div className='auth__form-content'>
            <AuthFormInput value={password} label={t('passwordPlaceholder')} type='password' onChange={e => dispatch({ type: 'SET_PASSWORD', payload: e.target.value })} placeholder={t('emailPlaceholder')} />
          </div>

          <AuthFormSubmit
            text={t('passwordResetConfirmButton')}
            onClick={handleSubmitClick}
            disabled={!password || !isValidPassword || state.firebaseActionCodeVerified !== true}
          />

          <AuthFooter />
        </div>
      </div>
    </HeroPage>
  );
}

export default ConfirmPasswordRecoverPage;
export const PATH = '/password';
