import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./HomePage.css";

import { useLocalization } from "../localization/LocalizationProvider";

import questionIcon from "../assets/question.svg";
import gearIcon from "../assets/settings.png";

import { createScenario, getScenarios, uploadScenario } from "../scenario/api";

import { logout } from "../auth/api";

import { downloadLocalFile, generateUUID } from "../utils/utils";
import { calculateMajorComplementsPosition } from "../scenario/components/utils";
import { safeFunction } from "../utils/safeFunction";
import { guidedModeQueryString } from "../scenario/utils";

import { CenterElementModal } from "../scenario/components/center/CenterElementModal";
import { TooltipWrapper } from "../components/TooltipWrapper";
import { Menu } from "../components/Menu";
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalTitle,
} from "../components/modal/Modal";
import { UploadFileButton } from "../components/UploadFileButton";
import { ThemeModal, useThemeModalManager } from "../themes/ThemeModal";
import { openAlertModal } from "../components/alert-modal/AlertModal";
import { getManualUrlByLang } from "../scenario/ScenarioHud";

function HomePage() {
  const navigate = useNavigate();

  const { t } = useLocalization();

  const [isLoading, setisLoading] = useState(false);

  const [newScenarioData, setNewScenarioData] = useState(null);

  const [scenariosTrees, setScenariosTrees] = useState([]);
  const [newScenarioModalOpen, setNewScenarioModalOpen] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);

  const themeModalManager = useThemeModalManager();

  useEffect(() => {
    safeFunction(async () => {
      // Refactor request to not get all info from request?
      const scenarios = await getScenarios();

      setScenariosTrees(
        scenarios
          .filter((sc) => sc.depth === 0)
          .map((sc) => {
            const treeTitle = sc.title;
            const treeScenarios = scenarios.filter((s) => s.rootId === sc.id);
            treeScenarios.sort((v1, v2) => (v1.depth <= v2.depth ? -1 : 1));
            return {
              title: treeTitle,
              scenarios: treeScenarios,
            };
          })
      );
    })();
  }, []);

  async function handleLogoutClick() {
    await logout();
    navigate("/login");
  }

  function handleScenarioClick(id) {
    navigate(`/scenarios/${id}`);
  }

  function handleNewClick() {
    setNewScenarioData({});
    setNewScenarioModalOpen(true);
  }

  const handleConfirmNewScenarioClick = useCallback(
    (values, isGuidedMode) => {
      setisLoading(true);
      const filteredMajorComplements = values.majorComplements.filter(
        (c) => c && c.description
      );
      createScenario({
        title: values.title,
        overarchingVP: values.overarchingVP,
        customerSegmentForVP: values.customerSegmentForVP,
        timelineEVP: values.timelineEVP,
        majorComplements: filteredMajorComplements.map((c, i) => {
          const index = i + 1; // starts at 1
          return {
            ...c,
            position: calculateMajorComplementsPosition(
              filteredMajorComplements.length,
              index
            ),
            id: generateUUID(),
            index,
          };
        }),
      })
        .then((newId) => {
          const guidedModeParams = isGuidedMode ? guidedModeQueryString : "";
          navigate(`/scenarios/${newId}${guidedModeParams}`);
        })
        .catch((err) => {
          setisLoading(false);
          openAlertModal({ title: t("error"), message: t("errorMessage") });
          alert(err);
        });
    },
    [navigate, t]
  );

  const handleUploadClick = useCallback(() => {
    setOpenUploadModal((v) => !v);
  }, []);

  const handleConfirmUploadClick = useCallback(
    (file) => {
      safeFunction(async () => {
        const data = new FormData();
        data.append("scenario-file", file);
        const res = await uploadScenario(data);
        openAlertModal({ title: t("uploadSuccessTitle") });

        const newScenario = {
          title: res.scenarioTitle,
          scenarios: [
            {
              id: res.scenarioId,
              title: res.scenarioTitle,
            },
          ],
        };
        setOpenUploadModal(false);
        setScenariosTrees((t) => [newScenario, ...t]);
      })();
    },
    [t]
  );

  const handleThemeClick = useCallback(() => {
    themeModalManager.handleOpen();
  }, [themeModalManager]);

  return (
    <>
      <div className="full-page scenario">
        <HomeHud
          handleConfigurationsClick={handleLogoutClick}
          handleUploadClick={handleUploadClick}
          handleThemeClick={handleThemeClick}
        />
        <div className="scenarios__content">
          <div className="flex gap--big mb--big">
            <ScenariosCard
              newScenario={true}
              dataFor="homePage"
              dataTip={"createNewScenario"}
              label={t("createNewScenario")}
              onClick={handleNewClick}
            />
            {/*<ScenariosCard dataFor="homePage" dataTip={'exampleModel'} label={t('exampleModel')} onClick={() => openAlertModal({ title: 'TO-DO' })} />*/}
          </div>
          <div className="scenarios">
            {scenariosTrees.map((tree, i) => (
              <ScenariosTree
                key={i}
                title={tree.title}
                scenarios={tree.scenarios}
                handleOpenClick={handleScenarioClick}
              />
            ))}
          </div>
        </div>
        <CenterElementModal
          isOpen={newScenarioModalOpen}
          isLoading={isLoading}
          handleConfirmClick={handleConfirmNewScenarioClick}
          handleClose={() => setNewScenarioModalOpen(false)}
          data={newScenarioData}
          guideMode
        />
        <UploadScenarioModal
          isOpen={openUploadModal}
          isLoading={isLoading}
          handleClose={() => setOpenUploadModal(false)}
          handleSubmit={handleConfirmUploadClick}
        />
        <ThemeModal themeModalManager={themeModalManager} />
      </div>
      <TooltipWrapper id="homePage" label={t} effect="float" />
    </>
  );
}

export default HomePage;
export const HOME_PATH = "/home";

const ScenariosCard = memo(
  ({ onClick, label, dataFor, dataTip, newScenario }) => {
    return (
      <div
        className={`scenarios__card ${
          newScenario ? "scenarios__card--new" : ""
        }`}
        onClick={onClick}
        data-for={dataFor}
        data-tip={dataTip}
        data-iscapture="true"
      >
        <div className="scenarios__card-content">
          <div className="scenarios__card-label padding fs">{label}</div>
        </div>
      </div>
    );
  }
);

const ScenariosTree = memo(({ title, handleOpenClick, scenarios }) => {
  return (
    <div className="scenarios__tree">
      <div className="scenarios__tree-title">{title}</div>
      <div className="scenarios__tree-items">
        {scenarios.map((sc) => (
          <ScenariosCard
            key={sc.id}
            label={sc.title}
            onClick={() => handleOpenClick(sc.id)}
          >
            <div>{sc.id}</div>
          </ScenariosCard>
        ))}
      </div>
    </div>
  );
});

const HomeHud = memo(({ handleUploadClick, handleThemeClick }) => {
  const { lang } = useLocalization();

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="hud hud__configs background">
      <div className="hud__configs--background" />
      <div className="hud__configs--help hud__row__cell">
        <img
          onClick={() =>
            downloadLocalFile(getManualUrlByLang(lang), "manual.docx")
          }
          src={questionIcon}
          alt=""
          className="pointer"
        />
      </div>
      <div className="hud__configs--gear hud__row__cell">
        <img
          className="pointer"
          onClick={() => setMenuOpen((v) => !v)}
          data-tip="custom show"
          data-event="click focus"
          src={gearIcon}
          alt=""
        />
        <Menu
          isOpen={menuOpen}
          handleClose={(e) => setMenuOpen(false)}
          options={[
            { handleClick: handleThemeClick, text: "menu_theme" },
            { handleClick: handleUploadClick, text: "menu_uploadFile" },
          ]}
        />
      </div>
    </div>
  );
});

const UploadScenarioModal = memo(
  ({ isOpen, isLoading, handleClose, handleSubmit }) => {
    const { t } = useLocalization();

    const handleFileUpload = useCallback(
      (e) => {
        const file = e.target.files[0];
        handleSubmit(file);
      },
      [handleSubmit]
    );

    return (
      <Modal size="big" open={isOpen}>
        <div className="ta--center">
          <ModalTitle>{t("uploadScenarioTitle")}</ModalTitle>
          <UploadFileButton
            text={t("uploadScenarioButton")}
            handleFileUpload={handleFileUpload}
          />
        </div>
        <ModalActions>
          <ModalButton
            disabled={isLoading}
            text={t("cancel")}
            onClick={handleClose}
          />
        </ModalActions>
      </Modal>
    );
  }
);
