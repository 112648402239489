import React, { memo, useCallback, useContext } from "react";

import { NodeModal } from "../NodeModal";
import { ScenarioContext } from "../../ScenarioPage";

export const ValueComplementModal = memo(({ isOpen, data, handleClose }) => {
  const ctx = useContext(ScenarioContext);

  const handleSubmitClick = useCallback((values) => {
    // validations? required fields?
    ctx.handleComplementDataChange(data.id, values);
    handleClose();
  }, [handleClose, ctx, data.id]);

  const handleDeleteClick = useCallback(() => {
    ctx.handleValueComplementRemoveClick(data.id);
    handleClose();
  }, [handleClose, ctx, data.id]);

  return (
    <NodeModal
      isOpen={isOpen}
      data={data}
      handleClose={handleClose}
      handleConfirmClick={handleSubmitClick}
      handleDeleteClick={handleDeleteClick}
      providedByOptions={ctx.providedByOptions}
    />
  );
});
