import { useCallback, useMemo, useState } from "react";

import { generateUUID } from "../utils/utils";
import { findAvailablePosition, getChildElements } from "./utils";

import { createComplement, createMajorComplement, removeMajorComplement, removeValueComplement, updateComplement, updateComplementPosition, updateValueProposition } from "./api";

import { CENTER_ELEMENT } from "./components/center/CenterElement";
import { EDGE_ADD_BUTTON } from "./components/edge/EdgeAddButton";
import { VALUE_ELEMENT as VALUE_COMPLEMENT } from './components/value-complement/ValueComplementNode';
import { MAJOR_COMPLEMENT } from "./components/major-complements/MajorElementNode";
import { majorComplementPositionMapper } from "./components/utils";
import { safeFunction } from "../utils/safeFunction";

export const ACTIONS = {
  addValueComplement: "addValueComplement",
  removeValueComplement: "removeValueComplement",
  moveValueComplement: "moveValueComplement",
  updateComplement: "updateComplement",
  updateVP: "updateVP",
  addMajorComplement: "addMajorComplement",
  removeMajorComplement: "removeMajorComplement",
  addValueComplementFromEdge: "addValueComplementFromEdge",
};

const ACTIONS_FUNC = {
  [ACTIONS.addValueComplement]: addValueComplementAction,
  [ACTIONS.removeValueComplement]: removeValueComplementAction,
  [ACTIONS.moveValueComplement]: moveValueComplementAction,
  [ACTIONS.updateComplement]: updateComplementAction,
  [ACTIONS.updateVP]: updateVPAction,
  [ACTIONS.addMajorComplement]: addMajorComplementAction,
  [ACTIONS.removeMajorComplement]: removeMajorComplementAction,
  [ACTIONS.removeMajorComplement]: removeMajorComplementAction,
  [ACTIONS.addValueComplementFromEdge]: addValueComplementFromEdgeAction,
};

export function useScenarioUndoRedoActionsManager(scenarioId, dispatch) {
  const [actionsIndex, setActionsIndex] = useState(-1);
  const [actionsList, setActionsList] = useState([]);

  const execute = useCallback((type, elements, data) => {
    const action = ACTIONS_FUNC[type](dispatch, scenarioId, elements, data);
    action.execute();
    setActionsList(l => [...l, action]);
    setActionsIndex(i => i + 1);
  }, [dispatch, scenarioId]);

  const undo = useCallback(() => {
    const action = actionsList.find((_, i) => i === actionsIndex);
    if (!action) { console.log('cant undo'); return null; }

    action.undo();
    setActionsIndex(i => i - 1);
  }, [actionsIndex, actionsList]);

  const redo = useCallback(() => {
    const action = actionsList.find((_, i) => i === actionsIndex + 1);
    if (!action) { console.log('cant redo'); return null; }

    setActionsIndex(i => i + 1);
    action.execute();
  }, [actionsIndex, actionsList]);

  const canUndo = useMemo(() => actionsIndex > -1, [actionsIndex]);
  const canRedo = useMemo(() => actionsIndex + 1 < actionsList.length, [actionsIndex, actionsList]);

  return { execute, undo, canUndo, redo, canRedo };
}
/*
function XPTO(dispatch, scenarioId, elements, data) {
  
  return {
    type: ACTIONS.XPTO,
    execute: () => {

    },
    undo: () => {
    
    }
  };
}
*/

function updateVPAction(dispatch, scenarioId, elements, data) {
  const savedData = elements.find(el => el.type === CENTER_ELEMENT);

  return {
    type: ACTIONS.updateVP,
    execute: () => {
      const element = elements.find(el => el.type === CENTER_ELEMENT);
      updateValueProposition(scenarioId, {
        title: data.title,
        overarchingVP: data.overarchingVP,
        customerSegmentForVP: data.customerSegmentForVP,
        timelineEVP: data.timelineEVP,
      }).then(() => {
        dispatch({ type: 'UPDATE_ELEMENT_DATA', payload: { id: element.id, data } });
        dispatch({ type: 'SET_CENTER_ELEMENT_OPEN', payload: null });
      });
    },
    undo: () => {
      updateValueProposition(scenarioId, {
        title: savedData.data.title,
        overarchingVP: savedData.data.overarchingVP,
        customerSegmentForVP: savedData.data.customerSegmentForVP,
        timelineEVP: savedData.data.timelineEVP,
      }).then(() => {
        dispatch({ type: 'UPDATE_ELEMENT_DATA', payload: { id: savedData.id, data: savedData.data } });
        dispatch({ type: 'SET_CENTER_ELEMENT_OPEN', payload: null });
      });
    }
  };
}

function addMajorComplementAction(dispatch, scenarioId, elements, data) {
  const id = generateUUID();

  const majorComplements = data.majorComplements;

  return {
    type: ACTIONS.addMajorComplement,
    execute: () => {
      if (majorComplements.length === 8) { return alert(`Not implemented...`); } // TODO change to modal

      const majorComplementsToUpdate = buildMajorComplementsToUpdate(majorComplements, majorComplements.length + 1);
      const newIndex = majorComplements.length + 1;
      const newComplement = {
        id,
        index: newIndex,
        type: MAJOR_COMPLEMENT,
        position: majorComplementPositionMapper(majorComplements.length + 1, newIndex),
        data: { description: '' },
      };

      const valuesForBd = { newComplements: [complementToDBFields(newComplement)], complementsToUpdate: majorComplementsToUpdate };
      createMajorComplement(scenarioId, valuesForBd)
        .then(() => {
          const values = { newComplements: [newComplement], complementsToUpdate: majorComplementsToUpdate };
          dispatch({ type: 'ADD_MAJOR_COMPLEMENT', payload: values });
        });
    },
    undo: () => {
      const idsToRemove = getChildElements(elements.filter(el => el.type === VALUE_COMPLEMENT), id).map(el => el.id);
      const majorComplementsToUpdate = buildMajorComplementsToUpdate(majorComplements, majorComplements.length);
      removeMajorComplement(scenarioId, id, idsToRemove, majorComplementsToUpdate)
        .then(() => {
          dispatch({ type: 'REMOVE_COMPLEMENTS', payload: [...idsToRemove, id] });
          dispatch({ type: 'UPDATE_MAJOR_COMPLEMENTS_POSITION', payload: majorComplementsToUpdate });
        });
    }
  };
}

function removeMajorComplementAction(dispatch, scenarioId, elements, data) {
  const majorComplement = elements.find(el => el.id === data.id);
  const childComplements = getChildElements(elements.filter(el => el.type === VALUE_COMPLEMENT), data.id);
  const majorComplements = elements.filter(el => el.id !== data.id).filter(el => el.type === MAJOR_COMPLEMENT);

  return {
    type: ACTIONS.removeMajorComplement,
    execute: () => {
      const idsToRemove = childComplements.map(el => el.id);
      const majorComplementsToUpdate = buildMajorComplementsToUpdate(majorComplements, majorComplements.length);
      removeMajorComplement(scenarioId, data.id, idsToRemove, majorComplementsToUpdate)
        .then(() => {
          dispatch({ type: 'REMOVE_COMPLEMENTS', payload: [...idsToRemove, data.id] });
          dispatch({ type: 'UPDATE_MAJOR_COMPLEMENTS_POSITION', payload: majorComplementsToUpdate });
        });
    },
    undo: () => {
      const majorComplementsToUpdate = majorComplements;
      createMajorComplement(scenarioId, { newComplements: [majorComplement, ...childComplements].map(complementToDBFields), complementsToUpdate: majorComplementsToUpdate })
        .then(() => {
          const edges = childComplements.map(complement => {
            return {
              id: generateUUID(),
              source: complement.id,
              target: complement.parentId,
              type: EDGE_ADD_BUTTON,
              arrowHeadType: 'arrowclosed',
              data: {
                source: complement.id,
                target: complement.parentId
              }
            };
          });
          const elementsToAdd = [
            majorComplement,
            ...childComplements,
            ...edges
          ];
          const values = { newComplements: elementsToAdd, complementsToUpdate: majorComplementsToUpdate };
          dispatch({ type: 'ADD_MAJOR_COMPLEMENT', payload: values });
        });
    }
  };
}

function updateComplementAction(dispatch, scenarioId, elements, data) {
  const savedComplement = elements.find(el => el.id === data.id);

  function buildBodyFromComplement(values) {
    const body = new FormData();

    function addIfPresent(key) {
      if (values[key] !== undefined && values[key] !== null)
        body.append(key, values[key]);
    }

    ['description', 'providedBy', 'actorType', 'contributionBasis', 'contributingAssumption',
      'availableIn', 'abilityToContribute', 'willingnessToContribute',
    ].forEach(addIfPresent);

    body.append('filesData', JSON.stringify(values.attachments.map(file => ({ id: file.id, key: file.key, name: file.name, isNew: file.isNew, remove: file.remove }))));

    values.attachments.filter(f => f.isNew).forEach(f => {
      body.append(`file_${f.id}`, f.data, f.id);
    });

    return body;
  }

  return {
    type: ACTIONS.updateComplement,
    execute: () => {
      const body = buildBodyFromComplement(data.data);

      updateComplement(scenarioId, data.id, body)
        .then((results) => {
          dispatch({ type: 'UPDATE_ELEMENT_DATA', payload: { id: data.id, data: results.elementUpdated } });
        });
    },
    undo: () => {
      const body = buildBodyFromComplement(savedComplement.data);

      updateComplement(scenarioId, data.id, body)
        .then((results) => {
          dispatch({ type: 'UPDATE_ELEMENT_DATA', payload: { id: data.id, data: results.elementUpdated } });
        });
    }
  };
}

function moveValueComplementAction(dispatch, scenarioId, elements, data) {
  const savedComplement = elements.find(el => el.id === data.id);
  return {
    newPos: data.position,
    oldPos: savedComplement.position,
    type: ACTIONS.moveValueComplement,
    execute: () => {
      updateComplementPosition(scenarioId, data.id, data.position)
        .then(() => {
          dispatch({ type: 'MOVE_VALUE_COMPLEMENT', payload: { id: data.id, position: data.position } });
        })
        .catch(e => {
          alert(e);
        });
    },
    undo: () => {
      updateComplementPosition(scenarioId, data.id, savedComplement.position)
        .then(() => {
          dispatch({ type: 'MOVE_VALUE_COMPLEMENT', payload: { id: data.id, position: savedComplement.position } });
        })
        .catch(e => {
          alert(e);
        });
    }
  };
}

function removeValueComplementAction(dispatch, scenarioId, elements, data) {
  const savedComplement = elements.find(el => el.id === data.id);
  const childComplement = elements.find(el => el.parentId === data.id);
  const childEdge = childComplement ? elements.find(el => el.type === EDGE_ADD_BUTTON && el.source === childComplement.id && el.target === data.id) : null;

  return {
    type: ACTIONS.removeValueComplement,
    execute: () => {
      safeFunction(async () => {
        removeValueComplement(scenarioId, data.id)
          .then(() => {
            dispatch({ type: 'REMOVE_VALUE_COMPLEMENT', payload: data.id });
          });
      })();
    },
    undo: () => {
      const mappedComplement = complementToDBFields(savedComplement);
      createComplement(scenarioId, { ...mappedComplement, childSourceId: childComplement?.id || undefined })
        .then(() => {
          const newEdge = {
            id: generateUUID(),
            source: savedComplement.id,
            target: savedComplement.parentId,
            type: EDGE_ADD_BUTTON,
            arrowHeadType: 'arrowclosed',
            data: {
              source: savedComplement.id,
              target: savedComplement.parentId
            }
          };

          if (childComplement && childEdge) {
            dispatch({ type: 'UPDATE_ELEMENT', payload: { id: childComplement.id, parentId: savedComplement.id } }); // update source node

            dispatch({ type: 'UPDATE_ELEMENT', payload: { id: childEdge.id, target: savedComplement.id, data: { source: childComplement.id, target: savedComplement.id } } }); // update current edge
          }

          dispatch({ type: 'ADD_ELEMENTS', payload: [savedComplement, newEdge] });
        });
    }
  };
}

function addValueComplementAction(dispatch, scenarioId, elements, data) {
  const newId = generateUUID();
  return {
    type: ACTIONS.addValueComplement,
    execute: () => {
      const majorComplementsCount = elements.filter(el => el.type === MAJOR_COMPLEMENT).length;
      const newPosition = findAvailablePosition(data.valueElementsInFlow, data.parentPosition, data.parentParentPosition, majorComplementsCount, data.majorComplementIndex);

      const newComplement = {
        id: newId,
        type: VALUE_COMPLEMENT,
        position: newPosition,
        parentId: data.parentId,
      };
      createComplement(scenarioId, newComplement)
        .then(() => {
          const newEdge = {
            id: generateUUID(),
            source: newComplement.id,
            target: newComplement.parentId,
            type: EDGE_ADD_BUTTON,
            arrowHeadType: 'arrowclosed',
            data: {
              source: newComplement.id,
              target: newComplement.parentId
            }
          };

          dispatch({ type: 'ADD_ELEMENTS', payload: [{ ...newComplement, data: { description: '' } }, newEdge] });
        });
    },
    undo: () => {
      removeValueComplement(scenarioId, newId)
        .then(() => {
          dispatch({ type: 'REMOVE_VALUE_COMPLEMENT', payload: newId });
        });
    }
  };
}

function addValueComplementFromEdgeAction(dispatch, scenarioId, elements, data) {
  const edge = data.edge;
  const newId = generateUUID();
  return {
    type: ACTIONS.addValueComplementFromEdge,
    execute: () => {
      const newComplement = {
        id: newId,
        type: VALUE_COMPLEMENT,
        position: { x: edge.position.x - 65, y: edge.position.y - 65 },
        parentId: edge.data.target,
      };
      createComplement(scenarioId, { ...newComplement, childSourceId: edge.data.source })
        .then(() => {
          dispatch({ type: 'UPDATE_ELEMENT', payload: { id: edge.data.source, parentId: newComplement.id } }); // update source node

          dispatch({ type: 'UPDATE_ELEMENT', payload: { id: edge.id, target: newComplement.id, data: { source: edge.data.source, target: newComplement.id } } }); // update current edge

          const newEdge = {
            id: generateUUID(),
            source: newComplement.id,
            target: newComplement.parentId,
            type: EDGE_ADD_BUTTON,
            arrowHeadType: 'arrowclosed',
            data: {
              source: newComplement.id,
              target: newComplement.parentId
            }
          };

          dispatch({ type: 'ADD_ELEMENTS', payload: [{ ...newComplement, data: { description: '' } }, newEdge] });
        });

    },
    undo: () => {
      removeValueComplement(scenarioId, newId, { childSourceId: edge.data.source, parentId: edge.data.target })
        .then(() => {
          dispatch({ type: 'REMOVE_VALUE_COMPLEMENT', payload: newId });

          dispatch({ type: 'UPDATE_ELEMENT', payload: { id: edge.data.source, parentId: edge.data.target } }); // update source node

          dispatch({ type: 'UPDATE_ELEMENT', payload: { id: edge.id, target: edge.data.target, data: { source: edge.data.source, target: edge.data.target } } }); // update current edge

        });
    }
  };
}

function complementToDBFields(complement) {
  return { ...complement.data, index: complement.index || undefined, id: complement.id, parentId: complement.parentId, position: complement.position, type: complement.type };
}

function buildMajorComplementsToUpdate(majorComplements, majorComplementsCount) {
  return majorComplements.slice().sort((c1, c2) => c1.index - c2.index).map((c, i) => ({
    ...c,
    position: majorComplementPositionMapper(majorComplementsCount, i + 1),
    index: i + 1,
  }));
}
