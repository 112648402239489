import React, { useContext, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';

import { useLocalization } from '../../../localization/LocalizationProvider';

import { TooltipWrapper } from '../../../components/TooltipWrapper';
import { ScenarioContext } from '../../ScenarioPage';

import './CenterElement.css';
import { CenterImage } from './CenterImage';

export const CENTER_ELEMENT = 'CENTER_ELEMENT';

function CenterElementNode({ data, id }) {
  const ctx = useContext(ScenarioContext);

  const { t } = useLocalization();

  useEffect(() => {
    const elem = document.querySelector(".react-flow__node-CENTER_ELEMENT");
    elem.classList.add('nodrag');
  }, []);

  return (
    <div className='center-element--wrapper nodrag' data-for="centerElement" data-tip='majorComplementsAreaHint'>
      <div className='center-element nodrag' data-for="centerElement" data-tip='editCenterElementHint'>
        <CenterImage className='center-element__content-bg-img' />
        <div className='center-element__content pointer' onClick={() => ctx.onCenterElementOpen()}>
          <div className='center-element__label'>
            <div className='center-element__label-primary'>{data?.overarchingVP}</div>
            <div className='divider divider--horizontal center-element__divider' />
            <div className='center-element__label-secondary'>{data?.customerSegmentForVP}</div>
          </div>
        </div>
        <Handle
          type="source"
          id={id}
          style={{ top: '50%', left: '50%', opacity: 0, pointerEvents: 'none' }}
        />
      </div>
      <TooltipWrapper
        id={'centerElement'}
        label={t}
        effect="float"
      />
    </div>
  );
}

export default CenterElementNode;
