import React from 'react';

function LoadingPage() {

  return (
    <div>
      LOADING......
    </div>
  );
}

export default LoadingPage;
