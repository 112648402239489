import { getLanguage, translate } from "../localization/LocalizationProvider";

import { openAlertModal } from '../components/alert-modal/AlertModal';

export const safeFunction = (unsafeFunction, finallyFunction, errorFunction) => async (...args) => {
  await unsafeFunction(...args)
    .catch((error) => {
      if (error.response?.status === 403) {
        return document.location.href = '/login';
      }

      const message = error.response?.data;
      const translatedMessage = translate(getLanguage(), message);
      if (errorFunction) {
        return errorFunction(message);
      }
      openAlertModal({ title: translatedMessage });
    });

  finallyFunction && finallyFunction();
};
