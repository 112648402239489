import { VALUE_ELEMENT } from "../../components/value-complement/ValueComplementNode";

export const DELAYING_ID = 'DELAYING';

export function suggestDelaying(elements) {
  const found = elements
    .filter(el => el.type === VALUE_ELEMENT)
    .filter(el => Number(el.data?.abilityToContribute) <= 0.6 && Number(el.data?.willingnessToContribute) >= 0.8);
  return found.length > 0 ? DELAYING_ID : null;
}
