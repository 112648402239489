import React, { memo, useCallback, useEffect, useState } from "react";

import {
  Modal,
  ModalActions,
  ModalButton,
  ModalTitle,
} from "../components/modal/Modal";
import { useLocalization } from "../localization/LocalizationProvider";
import { downloadLocalFile } from "../utils/utils";

import shareManual from "./manuals/multiplayer_manual.docx";

const initRemoveOptions = [
  { key: "general", checked: true, readOnly: true, label: "shareModalGeneral" },
  { key: "evp", checked: true, readOnly: true, label: "shareModalEVP" },
  {
    key: "referenceNames",
    checked: true,
    readOnly: true,
    label: "shareModalNames",
  },
  { key: "descriptions", checked: true, label: "shareModalDescription" },
  { key: "actorsNames", checked: true, label: "shareModalActors" },
  { key: "basisContribution", checked: true, label: "shareModalBasis" },
  {
    key: "assumptionContribution",
    checked: true,
    label: "shareModalAssumption",
  },
  { key: "availability", checked: true, label: "shareModalAvailability" },
  { key: "riskRatings", checked: true, label: "shareModalRiskRatings" },
];

const ShareScenarioModal = memo(
  ({ isOpen, handleClose, handleSubmitClick }) => {
    const { t } = useLocalization();

    const [emails, setEmails] = useState("");
    const [shareMode, setShareMode] = useState(0);
    const [dataRemovedOptions, setDataRemovedOptions] = useState([]);

    useEffect(() => {
      if (isOpen) {
        setShareMode(0);
        setEmails("");
        setDataRemovedOptions(initRemoveOptions);
      }
    }, [isOpen]);

    const handleChangeShareMode = useCallback((v) => {
      setShareMode(v);
      setDataRemovedOptions(
        v === 0
          ? initRemoveOptions
          : initRemoveOptions.map((i) =>
              i.readOnly ? i : { ...i, checked: false }
            )
      );
    }, []);

    const handlePropertyChange = useCallback((value) => {
      setDataRemovedOptions((options) => {
        return options.map((o) => {
          return o.key === value.key && !o.readOnly
            ? { ...o, checked: !value.checked }
            : o;
        });
      });
    }, []);

    const onSubmitClick = useCallback(() => {
      handleSubmitClick({
        emails,
        shareMode,
        dataOptions: dataRemovedOptions
          .filter((o) => (shareMode ? o.checked : true))
          .map((o) => o.key),
      });
    }, [dataRemovedOptions, emails, handleSubmitClick, shareMode]);

    const isSubmitDisabled = !emails; // validate emails string

    return isOpen ? (
      <Modal
        size="big"
        open={isOpen}
        closeOnDimmerClick={true}
        handleClose={handleClose}
      >
        <div>
          <ModalTitle>{t("shareModalTitle")}</ModalTitle>
          <div className="mt--normal">{t("shareModalMessage")}</div>
          <div>
            <input
              className="share-scenario__email input"
              onChange={(e) => setEmails(e.target.value)}
              placeholder="[email@email.com,gmail@gmail.com]"
              value={emails}
            />
          </div>
          <div className="mt--small">
            <div className="flex gap--small align--center color--gray">
              <input
                onChange={() => handleChangeShareMode(0)}
                type="radio"
                checked={shareMode === 0}
              />
              <div className="bold">{t("shareModalOption1")}</div>
              <div className="fs--small">({t("shareModalOptionHint")})</div>
            </div>
            <div className="flex gap--small align--center color--gray">
              <input
                onChange={() => handleChangeShareMode(1)}
                type="radio"
                checked={shareMode === 1}
              />
              <div className="bold">{t("shareModalOption2")}</div>
              <div className="fs--small">({t("shareModalOptionHint")})</div>
            </div>
            <div className="mt-4">
              <div>{t("shareModalCheckListTitle")}</div>
              <div>
                {dataRemovedOptions.map((v) => (
                  <div key={v.key} className="flex align--center">
                    <input
                      onChange={() => handlePropertyChange(v)}
                      type="checkbox"
                      checked={v.checked}
                      disabled={v.readOnly}
                    />
                    <div>{t(v.label)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <ModalActions>
            <ModalButton onClick={handleClose} text={t("cancel")} />
            <ModalButton
              primary
              disabled={isSubmitDisabled}
              onClick={onSubmitClick}
              text={t("confirm")}
            />
            <ModalButton
              onClick={() =>
                downloadLocalFile(shareManual, "share_manual.docx")
              }
              text={t("manual")}
            />
          </ModalActions>
        </div>
      </Modal>
    ) : null;
  }
);

export default ShareScenarioModal;
