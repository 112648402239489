import React from 'react';
import { Navigate } from 'react-router-dom';

import { AUTH_STATUS, useAuthStatus } from './PrivateRoute';

function RedirectLogged({ children }) {
  const { authStatus } = useAuthStatus();
  return authStatus === AUTH_STATUS.LOADING ? null
    : authStatus === AUTH_STATUS.LOGGED ? <Navigate to="/home" replace /> : children;
}

export default RedirectLogged;
