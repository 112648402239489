
export function CenterImage({ className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="305.743" height="305.74" viewBox="0 0 305.743 305.74">
      <g id="Grupo_316" data-name="Grupo 316" transform="translate(-1364.201 -883.736)">
        <g id="Grupo_305" data-name="Grupo 305">
          <rect id="Retângulo_229" data-name="Retângulo 229" width="277.92" height="277.92" transform="translate(1382.536 902.931)" opacity="0.4" fill="url(#pattern)" />
          <g id="Grupo_304" data-name="Grupo 304">
            <g id="Grupo_303" data-name="Grupo 303">
              <g id="Grupo_302" data-name="Grupo 302">
                <g id="Grupo_301" data-name="Grupo 301">
                  <path id="Caminho_488" data-name="Caminho 488" d="M1650.748,1036.607a133.675,133.675,0,1,1-133.676-133.675A133.671,133.671,0,0,1,1650.748,1036.607Z" fill="#fff" />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="Grupo_310" data-name="Grupo 310">
          <g id="Grupo_309" data-name="Grupo 309">
            <g id="Grupo_308" data-name="Grupo 308">
              <g id="Grupo_307" data-name="Grupo 307">
                <path id="Caminho_489" data-name="Caminho 489" d="M1631.93,1036.607a114.856,114.856,0,1,1-114.858-114.854A114.858,114.858,0,0,1,1631.93,1036.607Z" fill="none" className="theme-primary__stroke" stroke="#707070" stroke-miterlimit="10" stroke-width="6.803" />
              </g>
            </g>
          </g>
        </g>
        <g id="Grupo_315" data-name="Grupo 315">
          <g id="Grupo_314" data-name="Grupo 314">
            <g id="Grupo_313" data-name="Grupo 313">
              <g id="Grupo_312" data-name="Grupo 312">
                <g id="Grupo_311" data-name="Grupo 311">
                  <path id="Caminho_490" data-name="Caminho 490" d="M1668.647,1036.607a151.575,151.575,0,1,1-151.575-151.575A151.572,151.572,0,0,1,1668.647,1036.607Z" fill="none" stroke="#072430" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.593" strokeDasharray="19.842 19.842" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}