import React, { memo, useCallback, useContext, useEffect, useState } from "react";

import water_backgroundImageSrc from '../assets/bg-water.png';
import water_add from '../assets/plus-2.svg';
import water_add__major from '../assets/plus-3.svg';
import water_centerbackgroundImageSrc from '../assets/bg-center.svg';

import water_Status0 from '../assets/airtank-0.svg';
import water_Status1 from '../assets/airtank-1.svg';
import water_Status2 from '../assets/airtank-2.svg';
import water_Status3 from '../assets/airtank-3.svg';
import water_Status4 from '../assets/airtank-4.svg';
import water_Status5 from '../assets/airtank-5.svg';
import water_Face0 from '../assets/face-0.svg';
import water_Face1 from '../assets/face-1.svg';
import water_Face2 from '../assets/face-2.svg';
import water_Face3 from '../assets/face-3.svg';
import water_Face4 from '../assets/face-4.svg';
import water_Face5 from '../assets/face-5.svg';
import { mapInRange } from "../scenario/utils";
import { OCEAN_THEME } from "./oceanTheme";
import { FORMAL_RISK_ICONS, SCUBA_DIVING_RISK_ICONS } from "./riskIcons";
import { FORMAL_HUD_ICONS, SCUBA_HUD_ICONS } from "./hudIcons";

export const THEMES = {
  water: {
    key: 'water',
    bgImg: water_backgroundImageSrc,
    bgColor: '#40bac5',
    color: '#0f4f73',
    colorLight: 'rgb(10, 156, 220)',
    centerBgImg: water_centerbackgroundImageSrc,

    riskIconsNodeStyle: {
      ability: {
        left: '-30px',
        bottom: '15px',
        width: 'auto',
        height: '90px',
      },
      willingness: {
        left: '-17px',
        bottom: '0px',
        width: '41px',
        zIndex: '1',
      },
    },
    riskIconsHudStyle: {
      ability: {
        height: '120%',
        marginTop: '-10px'
      },
      willingness: {
        height: '60%',
        marginLeft: '-7px'
      },
    },
    riskIconsAnalyzerStyle: {
      ability: {
        height: '120%',
        marginTop: '-10px'
      },
      willingness: {
        height: '60%',
        marginLeft: '-7px'
      },
    },
    riskIconsSrc: {
      0: {
        ability: water_Status0,
        willingness: water_Face0,
      },
      1: {
        ability: water_Status1,
        willingness: water_Face1,
      },
      2: {
        ability: water_Status2,
        willingness: water_Face2,
      },
      3: {
        ability: water_Status3,
        willingness: water_Face3,
      },
      4: {
        ability: water_Status4,
        willingness: water_Face4,
      },
      5: {
        ability: water_Status5,
        willingness: water_Face5,
      },
    },
    addImg: {
      src: water_add,
      style: {
        right: '6px',
        top: '6px',
        width: '25px',
      }
    },
    addMajorComplamentImg: {
      src: water_add__major,
    }
  },
  chef: {
    key: 'chef',
  },
};

const DEFAULT_THEME = THEMES.water.key;

const ThemeContext = React.createContext({});

export const useThemeManager = () => {
  const [theme, setTheme] = useState({});

  useEffect(() => {
    const activeTheme = localStorage.getItem('mindEco__theme') || DEFAULT_THEME;
    setTheme(THEMES[activeTheme]);
  }, []);

  return theme || {};
};

export const ThemeHOC = memo(({ children }) => {
  const theme = useThemeManager();

  const bgImg = `url('${theme.bgImg}')`;
  const themeStyle = {
    '--sc__bg-img': bgImg,
    '--sc__bg-color': theme.bgColor,
    '--sc__color': theme.color,
    '--sc__hud-configs-bg-img': `url(${theme.hud?.configsBgImg})`,
    '--sc__color-light': theme.colorLight,
    '--sc__border': '3px solid var(--sc__color)',
  };

  return (
    <div className="theme" style={themeStyle}>
      {children}
    </div>
  );
});


const themeBackgrounds = [
  { id: 1, label: 'themeOcean', image: OCEAN_THEME.bgImg, bgColor: OCEAN_THEME.bgColor },
  { id: 2, label: 'themeWhite', image: '', bgColor: 'white' },
];

const themeColors = [
  { id: 1, label: 'themeBlue', primary: '#0f4f73', secondary: '#81b7cc', tertiary: 'rgba(129,183,204,.66)', descrtuctive: 'red' },
  { id: 2, label: 'themeBlack', primary: 'black', secondary: 'gray', tertiary: 'rgba(128,128,128, .66)', descrtuctive: 'red' },
];

const themeRiskIcons = [
  { id: 1, label: 'themeScubaDiving', build: SCUBA_DIVING_RISK_ICONS.build, hudIcons: SCUBA_HUD_ICONS },
  { id: 2, label: 'themeFormal', build: FORMAL_RISK_ICONS.build, hudIcons: FORMAL_HUD_ICONS },
];

export const ThemeProvider = ({ children }) => {
  const [background, setBackground] = useState({});
  const [riskIcon, setRiskIcon] = useState({});
  const [color, setColor] = useState({});
  const [themeHUDIcons, setThemeHUDIcons] = useState({});

  useEffect(() => {
    const saved = JSON.parse(localStorage.getItem('thrive_theme'));

    setBackground(themeBackgrounds.find(b => b.id === saved?.background) || themeBackgrounds[0]);
    setColor(themeColors.find(b => b.id === saved?.color) || themeColors[0]);
    setRiskIcon(themeRiskIcons.find(b => b.id === saved?.riskIcon) || themeRiskIcons[0]);
    setThemeHUDIcons(themeRiskIcons.find(b => b.id === saved?.riskIcon)?.hudIcons || themeRiskIcons[0].hudIcons);
  }, []);

  const handleThemeOptionsChange = useCallback((values) => {
    setBackground(values.background);
    setColor(values.color);
    setRiskIcon(values.riskIcon);
    setThemeHUDIcons(values.riskIcon.hudIcons);

    localStorage.setItem(
      'thrive_theme',
      JSON.stringify({ background: values.background.id, color: values.color.id, riskIcon: values.riskIcon.id }));
  }, []);

  const bgImg = `url('${background.image}')`;
  const themeStyle = {
    '--theme__bg-img': bgImg,
    '--theme__bg-color': background.bgColor,
    '--theme__color-primary': color.primary,
    '--theme__color-secondary': color.secondary,
    '--theme__color-tertiary': color.tertiary,
    '--theme__color-descructive': color.descrtuctive,
    '--theme__border': '3px solid var(--sc__color)',
  };

  return (
    <ThemeContext.Provider value={{
      handleThemeOptionsChange,
      backgrounds: themeBackgrounds,
      background,

      color,
      colors: themeColors,

      riskIcon,
      riskIcons: themeRiskIcons,

      hudIcons: themeHUDIcons,
    }}>
      <div style={themeStyle}>
        {children}
      </div>
    </ThemeContext.Provider>
  );
};

export function useTheme() {
  const context = useContext(ThemeContext);
  return context;
}

export function getAbilityIconSrc(icons, ability) {
  const abilityIndex = mapInRange(ability);
  return icons ? icons[abilityIndex]?.ability : '';
}

export function getWillingnessIconSrc(icons, willingness) {
  const willingnessIndex = mapInRange(willingness);
  return icons ? icons[willingnessIndex]?.willingness : '';
}
