import React, { memo, useContext, useMemo, useState } from "react";

import { useLocalization } from "../localization/LocalizationProvider";

import { ScenarioContext } from "./ScenarioPage";
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalTitle,
} from "../components/modal/Modal";
import { VALUE_ELEMENT } from "./components/value-complement/ValueComplementNode";
import { TooltipWrapper } from "../components/TooltipWrapper";
import { MAJOR_COMPLEMENT } from "./components/major-complements/MajorElementNode";

import arrowSrc from "../assets/right-arrow.svg";

function findComplementsMajor(elements) {
  function findMajor(element) {
    if (!element) return null;
    if (!element.parentId) return element.id;
    return findMajor(elements.find((el) => el.id === element.parentId));
  }

  return elements.map((el) => {
    if (el.type === MAJOR_COMPLEMENT) return el;
    const mcId = findMajor(el);
    return { ...el, mcId };
  });
}

export const ScenarioTimeline = memo(({ isOpen, handleClose }) => {
  const { t } = useLocalization();
  const ctx = useContext(ScenarioContext);

  const [openMajor, setOpenMajor] = useState(null);

  const totalTimeline = ctx.timelineEVP;

  const majorComplements = useMemo(() => {
    const complements = ctx.elements.filter(
      (el) => el.type === VALUE_ELEMENT || el.type === MAJOR_COMPLEMENT
    );
    const valueComplementsWithMajorLink = findComplementsMajor(complements);
    return complements
      .filter((el) => el.type === MAJOR_COMPLEMENT)
      .map((mj) => {
        return {
          ...mj,
          children: valueComplementsWithMajorLink.filter(
            (el) => el.mcId === mj.id
          ),
        };
      });
  }, [ctx.elements]);

  return (
    <Modal
      size="big"
      open={isOpen}
      closeOnDimmerClick={true}
      handleClose={handleClose}
    >
      <div>
        <ModalTitle>{t("timelineTitle")}</ModalTitle>
        <div
          style={{ overflowY: "auto", maxHeight: "400px" }}
          className="mt--normal grid gap--normal"
        >
          {majorComplements.map((mc) => (
            <>
              <TimelineItem
                isOpen={mc.id === openMajor}
                key={mc.id}
                complement={mc}
                totalTimeline={totalTimeline}
                handleClickExpand={() =>
                  setOpenMajor((v) => (v === mc.id ? null : mc.id))
                }
                handleClickComplement={ctx.handleTimelineReviewComplementClick}
              />
              {mc.id === openMajor
                ? mc.children.map((vc) => (
                    <TimelineItem
                      isChildren={true}
                      key={vc.id}
                      complement={vc}
                      totalTimeline={totalTimeline}
                      handleClickComplement={
                        ctx.handleTimelineReviewComplementClick
                      }
                    />
                  ))
                : null}
            </>
          ))}
        </div>
      </div>
      <div className="mt--normal flex jc--center gap--small color--gray-600 bold">
        <span>{t("totalTimeline")}:</span> {totalTimeline} {t("months")}
      </div>
      <ModalActions>
        <ModalButton onClick={handleClose} text={t("close")} />
      </ModalActions>
      <TooltipWrapper id="timeline" label={t} effect="float" />
    </Modal>
  );
});

const arrowSize = {
  width: "15px",
  height: "15px",
  cursor: "pointer",
};
const TimelineItem = ({
  complement,
  totalTimeline,
  handleClickComplement,
  handleClickExpand,
  isChildren,
  isOpen,
}) => {
  const time = complement?.data?.availableIn;

  return (
    <div className="grid ai--center gap--normal ai--center timeline__item color--gray">
      {isChildren ? (
        <div />
      ) : (
        <img
          style={{ ...arrowSize, transform: isOpen ? "rotate(90deg)" : "" }}
          onClick={handleClickExpand}
          src={arrowSrc}
          alt=""
        />
      )}
      <div
        data-for="timeline"
        data-tip="timelineOpenComplementHint"
        onClick={() => handleClickComplement(complement)}
        className={`pointer underline__hover ${
          complement.type === MAJOR_COMPLEMENT ? "fs bold" : "fs--small"
        } ${complement.data.description ? "" : "italic"}`}
      >
        {complement.data.description || "N/D"}
      </div>
      <div className={time ? "" : "italic"}>{time || "N/D"}</div>
      <div
        style={{
          width: "100%",
          backgroundColor: "var(--theme__color-secondary)",
          height: "15px",
        }}
      >
        {time ? (
          <div
            style={{
              width:
                time > totalTimeline
                  ? "100%"
                  : `${(time * 100) / totalTimeline}%`,
            }}
            className="timeline__item-line"
          />
        ) : (
          <div className="italic" style={{ color: "black", fontSize: "13px" }}>
            N/D
          </div>
        )}
      </div>
    </div>
  );
};
